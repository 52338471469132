import React, { useEffect, useMemo, useState } from "react";
import { ErrorMessage, Field } from "formik";
import { Select, DatePicker, TimePicker, Button } from "antd";
import dayjs from "dayjs";
import { Delete, Schedule } from "@/utils/Images";
import { useDeleteAssignAssets } from "@/supabase/jobs";
import { useAppSelector } from "@/redux/store/Store";
import { ROLE } from "@/utils/enums";
import {
  DeleteAssetsDataProps,
  ScheduleVisitAssigned,
  ScheduleVisitDateFieldProps,
  ScheduleVisitOptionType,
  ScheduleVisitProps,
  ScheduleVisitSelectFieldProps,
} from "@/models";
import { DeleteModal } from "@/components";
import { AddAssets } from "@/pages";
const { Option, OptGroup } = Select;

const SelectField: React.FC<ScheduleVisitSelectFieldProps> = ({
  field,
  form,
  loader,
  options = [],
  jobData,
  ...props
}) => {
  const extractDrivers = (description: any) => {
    const driverMatch = description?.match(/Drivers:\s*(.+?)(\n|$)/);
    return driverMatch ? driverMatch[1]?.trim() : null;
  };

  const drivers = extractDrivers(jobData?.description);
  const getMatchedDrivers = (drivers: any, list: any) => {
    return list?.filter((person: any) => drivers?.includes(person?.label));
  };
  const matchedDrivers = getMatchedDrivers(drivers, options);

  useEffect(() => {
    if (drivers) {
      let values = matchedDrivers?.map((option: any) => option.value);
      form.setFieldValue("assignedTo", values);
    }
  }, []);
  return (
    <Select
      {...field}
      {...props}
      mode={field.name == "assignedTo" ? "multiple" : undefined}
      maxTagCount={1}
      value={field.value || undefined}
      onChange={(value, obj: any) => {
        if (field.name == "assignedTo") {
          let values = obj?.map((option: any) => option.value);
          form.setFieldValue(field.name, values);
        } else {
          form.setFieldValue(field.name, value);
        }
      }}
      loading={loader}
      showSearch
      placeholder="Select an option"
      optionFilterProp="label"
      filterOption={(input, option) =>
        option?.props.children.toLowerCase().includes(input.toLowerCase())
      }
      className={
        field.name == "assignedTo"
          ? "w-full custom-antd-select mt-0.5 custom-select rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
          : "w-full mt-0.5 custom-select rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
      }
    >
      {options?.length > 0 &&
        options?.map((group) =>
          "options" in group ? (
            <OptGroup key={group?.label} label={group?.label}>
              {group?.options?.map((option) => (
                <Option key={option?.value} value={option?.value}>
                  {option?.label}
                </Option>
              ))}
            </OptGroup>
          ) : (
            <Option key={group?.value} value={group?.value}>
              {group?.label}
            </Option>
          )
        )}
    </Select>
  );
};

const AssetsSelectField: React.FC<ScheduleVisitSelectFieldProps> = ({
  field,
  form,
  loader,
  options = [],
  setAssetsData,
  reloadDashboard,
  jobData,
  ...props
}) => {
  const [visible, setVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<DeleteAssetsDataProps | null>(
    null
  );
  const [search, setSearch] = useState("");
  const { deleteAssignAssets } = useDeleteAssignAssets();
  const [assetId, setAssetId] = React.useState<string | null>(null);
  const [schemaId, setSchemaId] = useState<DeleteAssetsDataProps | null>(null);
  const handleVisibleModal = () => {
    setVisible(true);
  };
  const handleCancelModal = () => {
    setVisible(false);
  };
  const handleIsModalOpen = () => {
    setIsModalOpen(true);
  };
  const handleCancelIsModal = () => {
    setIsModalOpen(false);
  };
  const handleDeleteAsset = (values: any) => {
    setModalData(values);
    handleIsModalOpen();
  };

  const handleDeleteAssets = () => {
    deleteAssignAssets(
      schemaId?.schemaId,
      assetId,
      reloadDashboard,
      handleCancelIsModal,
      setAssetsData
    );
  };

  const filteredOptions = useMemo(() => {
    if (search.length > 0) {
      return (
        options?.map((val: any) => ({
          ...val,
          options: val?.options.filter((el: any) => {
            return el?.label.toLowerCase()?.includes(search?.toLowerCase());
          }),
        })) ?? []
      ).filter((v) => v?.options.length > 0);
    }
    return options;
  }, [options, search]);

  React.useEffect(() => {
    if (jobData) {
      setAssetsData(jobData?.assignAssets);
    }
  }, []);
  //
  return (
    <>
      {visible && (
        <AddAssets
          visible={visible}
          handleCancelModal={handleCancelModal}
          reloadDashboard={reloadDashboard}
        />
      )}
      {isModalOpen && (
        <DeleteModal
          openModal={isModalOpen}
          handleCancelModal={handleCancelIsModal}
          btnTextCncl="Cancel"
          text={`Are you sure you want to delete ${modalData?.label} from
          application?`}
          btnTextDlt="Delete"
          handleDelete={handleDeleteAssets}
        />
      )}
      <Select
        {...field}
        {...props}
        mode="multiple"
        maxTagCount={1}
        value={field.value ?? []}
        onChange={(value, options) => {
          const assets = options?.map((option: any) => option.label);
          setAssetsData(options);
          const selectedValues = options?.map((option: any) => option.value);
          form.setFieldValue(field.name, selectedValues);
        }}
        onSearch={(e) => setSearch(e)}
        loading={loader}
        filterOption={false}
        showSearch
        placeholder="Select an option"
        optionFilterProp="options.value"
        dropdownRender={(menu) => (
          <>
            {menu}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 8,
              }}
            >
              <Button
                type="dashed"
                onClick={() => handleVisibleModal()}
                style={{ marginBottom: 8 }}
              >
                Add More
              </Button>
            </div>
          </>
        )}
        className="w-full custom-antd-select mt-0.5 custom-select rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
      >
        {filteredOptions?.length > 0 &&
          filteredOptions?.map((group) =>
            "options" in group ? (
              <OptGroup key={String(group.label)} label={group.label}>
                {group.options?.map((option: any) => (
                  <Option key={option.value} value={option.label}>
                    <div className="flex justify-between">
                      {option.label}
                      <img
                        src={Delete}
                        alt=""
                        className="h-5 w-5"
                        onClick={() => {
                          handleDeleteAsset(option);
                          setAssetId(option?.value);
                          setSchemaId(group);
                        }}
                      />
                    </div>
                  </Option>
                ))}
              </OptGroup>
            ) : (
              <Option key={group.value} value={group.value}>
                {group.label}
              </Option>
            )
          )}
      </Select>
    </>
  );
};

const DateField: React.FC<ScheduleVisitDateFieldProps> = ({
  field,
  form,
  jobData,
  isUpdateMode,
}) => {
  const handleChange = (date: dayjs.Dayjs | null) => {
    const formattedDate = date ? date.format("YYYY-MM-DD") : "";
    form.setFieldValue(field.name, date ? formattedDate : "");
  };
  const disabledDate = (current: dayjs.Dayjs): boolean => {
    // If there's no dueDate, all dates are valid (return false to not disable any dates)
    if (!form.values.dueDate) {
      return false;
    }
    return current.isBefore(dayjs(form.values.dueDate), "day");
  };

  const prefilledEndDate = React.useMemo(() => {
    if (jobData?.dueDate && jobData?.duration) {
      return dayjs
        .unix(Number(jobData.dueDate))
        .add(jobData.duration, "second")
        .format("YYYY-MM-DD");
    }
    return "";
  }, [jobData]);

  React.useEffect(() => {
    if (field.name == "endDate" && isUpdateMode && !jobData?.endDate) {
      form.setFieldValue("endDate", prefilledEndDate);
    }
  }, [jobData]);
  return (
    <DatePicker
      name={field.name}
      value={field.value ? dayjs(field.value) : null}
      onChange={handleChange}
      className="w-full rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
      disabledDate={field.name === "endDate" ? disabledDate : undefined}
    />
  );
};

const TimeField: React.FC<ScheduleVisitDateFieldProps> = ({
  field,
  form,
  jobData,
}) => {
  // Handle time change
  const handleChange = (time: dayjs.Dayjs | null) => {
    form.setFieldValue(field.name, time ? time.format("HH:mm:ss") : "");
    if (field.name === "dueTime") {
      // Reset endTime when dueTime changes
      form.setFieldValue("endTime", null);
    }
  };

  // Prefill start time
  const prefilledStartTime = React.useMemo(() => {
    if (jobData?.dueDate) {
      return dayjs.unix(Number(jobData.dueDate)).format("HH:mm:ss");
    }
    return "";
  }, [jobData]);

  // Prefill end time
  const prefilledEndTime = React.useMemo(() => {
    if (jobData?.dueDate && jobData?.duration) {
      return dayjs
        .unix(Number(jobData.dueDate))
        .add(jobData.duration, "second")
        .format("HH:mm:ss");
    }
    return null;
  }, [jobData]);

  // Get formatted end time if available
  const getFormattedTime = () => {
    if (jobData?.endDate) {
      const date = new Date(parseInt(jobData.endDate) * 1000); // Convert to milliseconds
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    }
    return null;
  };

  // Disable past times for endTime when dueDate and endDate are the same
  const disabledTime = React.useCallback(() => {
    if (field.name === "endTime") {
      const dueDate = dayjs(form.values.dueDate);
      const endDate = dayjs(form.values.endDate);
      const dueTime = form.values.dueTime
        ? dayjs(form.values.dueTime, "HH:mm:ss")
        : null;

      if (dueTime && dueDate.isSame(endDate, "day")) {
        // Add one minute to the selected dueTime
        const minEndTime = dueTime.add(1, "minute");
        const dueHour = minEndTime.hour();
        const dueMinute = minEndTime.minute();
        const dueSecond = minEndTime.second();

        // Disable hours, minutes, and seconds before dueTime + 1 minute
        return {
          disabledHours: () => Array.from({ length: dueHour }, (_, i) => i),
          disabledMinutes: (selectedHour: number) =>
            selectedHour === dueHour
              ? Array.from({ length: dueMinute }, (_, i) => i)
              : [],
          disabledSeconds: (selectedHour: number, selectedMinute: number) =>
            selectedHour === dueHour && selectedMinute === dueMinute
              ? Array.from({ length: dueSecond }, (_, i) => i)
              : [],
        };
      }
    }
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
      disabledSeconds: () => [],
    };
  }, [form.values.dueDate, form.values.endDate, form.values.dueTime]);

  // Set prefilled values on mount
  React.useEffect(() => {
    if (field.name === "dueTime" && prefilledStartTime) {
      form.setFieldValue("dueTime", prefilledStartTime);
    } else if (
      field.name === "endTime" &&
      (prefilledEndTime || getFormattedTime())
    ) {
      form.setFieldValue("endTime", prefilledEndTime || getFormattedTime());
    }
  }, [jobData, prefilledStartTime, prefilledEndTime]);

  return (
    <TimePicker
      value={field.value ? dayjs(field.value, "HH:mm:ss") : null}
      onChange={handleChange}
      className="w-full rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
      format="HH:mm:ss"
      disabledTime={field.name === "endTime" ? disabledTime : undefined}
    />
  );
};

const ScheduleVisit: React.FC<ScheduleVisitProps> = ({
  allAssignedName,
  jobData,
  isUpdateMode,
  setAssetsData,
  reloadDashboard = () => {},
}) => {
  const assetsArr: ScheduleVisitAssigned[] = [];

  const storedAssets = useAppSelector((state) => state?.storedAssets?.assets);
  const userRole = useAppSelector((state) => state.Authlogin.data?.role);

  const options = useMemo(() => {
    if (!storedAssets || storedAssets?.length === 0) return [];

    const groupedAssets = storedAssets?.reduce((acc, asset) => {
      if (!acc[asset?.schemaTitle]) {
        acc[asset?.schemaTitle] = {
          assets: [],
          schemaId: asset?.schemaID, // Add schemaID when first encountering schemaTitle
        };
      }
      acc[asset?.schemaTitle].assets?.push({
        label: asset?.name,
        value: asset?.id,
      });

      return acc;
    }, {} as Record<string, { assets: ScheduleVisitOptionType[]; schemaId: string }>);

    return Object.entries(groupedAssets)?.map(
      ([schemaTitle, { assets, schemaId }]) => ({
        label: schemaTitle,
        options: assets,
        schemaId, // Include schemaId here
      })
    );
  }, [storedAssets]);

  return (
    <div className="min-h-screen-minus-150">
      <h1 className="text-base font-bold mt-3 flex items-center gap-2">
        <img src={Schedule} alt="" className="w-6 h-6" />
        Schedule Visit(s)
      </h1>
      {userRole != ROLE.customer && userRole != ROLE.staff && (
        <div className="mt-5">
          <label className="font-medium">Assign To</label>
          <Field
            name="assignedTo"
            options={allAssignedName}
            component={SelectField}
            jobData={jobData}
          />
          <ErrorMessage
            name="assignedTo"
            component="div"
            className="text-red-500 text-sm"
          />
        </div>
      )}

      <div className="mt-5 flex space-x-2 flex-col">
        <label className="font-medium">Start Date</label>
        <div className="flex space-x-2 mt-0.5">
          <div className="w-full flex flex-col">
            <Field name="dueDate" component={DateField} />
            <ErrorMessage
              name="dueDate"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          <div className="w-full flex flex-col">
            <Field name="dueTime" component={TimeField} jobData={jobData} />
            <ErrorMessage
              name="dueTime"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
        </div>
      </div>

      <div className="mt-5 flex space-x-2 flex-col">
        <label className="font-medium">End Date</label>
        <div className="flex space-x-2 mt-0.5">
          <div className="w-full flex flex-col">
            <Field
              name="endDate"
              component={DateField}
              jobData={jobData}
              isUpdateMode={isUpdateMode}
            />
            <ErrorMessage
              name="endDate"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          <div className="w-full flex flex-col">
            <Field name="endTime" component={TimeField} jobData={jobData} />
            <ErrorMessage
              name="endTime"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
        </div>
      </div>
      {userRole != ROLE.customer && userRole != ROLE.staff && (
        <div className="mt-5">
          <label className="font-medium">Assign Assets</label>
          <Field
            name="assignAssets"
            options={options}
            component={AssetsSelectField}
            loader={false}
            setAssetsData={setAssetsData}
            reloadDashboard={reloadDashboard}
            jobData={jobData}
          />
          <ErrorMessage
            name="assignAssets"
            component="div"
            className="text-red-500 text-sm"
          />
        </div>
      )}

      <div className="mt-5">
        <label className="font-medium">Shift</label>
        <Field
          name="shift"
          options={[
            { label: "Day", value: "Day" },
            { label: "Night", value: "Night" },
          ]}
          component={SelectField}
          loader={false}
        />
      </div>
    </div>
  );
};

export { ScheduleVisit };
