import { Button, Input } from "@/components";
import { DefaultProfile } from "@/utils/Images";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChangePasswordFields } from "@/components/Data";
import { useAuth } from "@/supabase/auth";
import { useDispatch, useSelector } from "react-redux";
import { RootState, useAppSelector } from "@/redux/store/Store";
import {
  EditOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import supabase from "@/supabase";
import { hideLoader, showLoader } from "@/redux/slices/LoaderSlice";
import { setProfileImage } from "@/redux/slices/UsersSlice";
import { MyProfileFormValues } from "@/models";

const MyProfile: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { fetchUserProfileByEmail } = useAuth();
  const profileEmail = useAppSelector(
    (state) => state.Authlogin?.data?.user?.email
  );
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const { UpdateCustomerProfile } = useAuth();
  const role = useAppSelector((state) => state.Authlogin?.data?.role);
  const [loader, setLoader] = useState(false);
  const profileImage = useAppSelector(
    (state) => state.usersSlice?.profileImage
  );
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [uploadedImageURL, setUploadedImageURL] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false); // New state for password visibility

  const profileData = useSelector(
    (state: RootState) =>
      state.Authlogin.data?.session?.user?.user_metadata ||
      state.Authlogin.data?.user?.user_metadata
  );

  const initialValues: MyProfileFormValues = {
    email: profileData?.email || "",
    newPassword: "",
    avatar_url: "",
  };

  const handleSubmit = async (
    values: MyProfileFormValues,
    { setSubmitting }: FormikHelpers<MyProfileFormValues>
  ) => {
    setSubmitting(false);

    // Update profile with uploaded image URL (if available) ̰
    const { newPassword, ...rest } = values;
    const updatedValues = newPassword
      ? { ...rest, newPassword } // Include newPassword only if it has a value
      : rest;

    // Add avatar URL if available
    const finalValues = uploadedImageURL
      ? { ...updatedValues, avatar_url: uploadedImageURL }
      : updatedValues;

    // Call your update function
    UpdateCustomerProfile(finalValues, setLoader, profileImage);
  };
  const onClick = () => {
    navigate(-1);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(showLoader());
    const file = event.target.files?.[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));

      // Check if there's an existing image in profileImage
      if (profileImage) {
        try {
          // Extract the file path from profileImage
          const oldFilePath = profileImage.split("/").slice(-2).join("/");

          // Remove the old image from the storage bucket
          const { error: deleteError } = await supabase.storage
            .from("profile_image")
            .remove([oldFilePath]);

          if (deleteError) {
            console.error("Error deleting old image:", deleteError.message);
            dispatch(hideLoader());
            return;
          } else {
            dispatch(hideLoader());
          }
        } catch (error) {
          console.error("Error handling old image removal:", error);
          dispatch(hideLoader());
          return;
        }
      }

      const date = new Date().toISOString();

      // Upload the new image to Supabase storage
      const filePath = `profile_image/${file.name}-${date}`;
      const { data, error } = await supabase.storage
        .from("profile_image") // Bucket name
        .upload(filePath, file);

      if (error) {
        console.error("Error uploading new image:", error.message);
        dispatch(hideLoader());
      } else {
        // Get the public URL of the uploaded image
        const { data: publicUrlData } = supabase.storage
          .from("profile_image")
          .getPublicUrl(filePath);

        if (publicUrlData?.publicUrl) {
          setUploadedImageURL(publicUrlData.publicUrl);
          dispatch(setProfileImage(publicUrlData.publicUrl));
          const { data, error } = await supabase
            .from("users")
            .update({ profile_image_path: publicUrlData.publicUrl })
            .eq("email", profileData?.email);
        }
      }

      dispatch(hideLoader());
    }
  };

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle password visibility
  };

  React.useEffect(() => {
    if (profileEmail) fetchUserProfileByEmail(profileEmail);
  }, []);

  return (
    <div className="flex items-center justify-center h-screen-minus-128">
      <div className="shadow-custom p-6 w-96 rounded-lg">
        <h1 className="text-lg font-medium">Edit Customer Profile</h1>
        <div className="flex items-center  gap-4 mt-6">
          <div className="relative">
            <img
              src={
                selectedImage ||
                profileData?.avatar_url ||
                profileImage ||
                DefaultProfile
              }
              alt="Profile"
              className="w-32 h-32 rounded-full"
            />
            <input
              type="file"
              ref={fileInputRef}
              className="hidden"
              onChange={handleFileChange}
            />
            <button
              type="button"
              onClick={handleIconClick}
              className="absolute bottom-0 right-0 p-2 bg-gray-200 rounded-full flex items-center justify-center"
            >
              <EditOutlined />
            </button>
          </div>
          <div className="">
            <p className="font-medium text-sm">
              Name:{" "}
              <span className="text-slate-400">
                {profileData?.full_name ||
                  profileData?.preferred_username ||
                  profileData?.name}
              </span>
            </p>
            <p className="font-medium text-sm">
              Role: <span className="text-slate-400">{role}</span>
            </p>
          </div>
        </div>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          <Form>
            <div>
              {ChangePasswordFields?.map((val) => (
                <div key={val.name} className="mt-5">
                  <div className="mb-1.5 flex justify-between">
                    <label className="font-medium text-sm">{val?.label}</label>
                  </div>
                  <div className="relative">
                    <Input
                      inputType={
                        val.name === "newPassword" && showPassword
                          ? "text"
                          : val.type
                      }
                      disabled={val.name === "email"}
                      inputPlaceholder={val.placeholder}
                      inputName={val.name}
                      inputClass="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                    />
                    {val.name === "newPassword" && (
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                      >
                        {showPassword ? (
                          <EyeInvisibleOutlined />
                        ) : (
                          <EyeOutlined />
                        )}
                      </button>
                    )}
                  </div>
                </div>
              ))}
              <div className="mt-8 flex gap-2">
                <Button
                  text="Save"
                  customClass="w-full font-bold shadow-sm rounded-lg py-3 bg-blue-500 text-white flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:bg-blue-700 focus:shadow-sm focus:shadow-outline"
                  btnLoader={loader}
                  btnType="submit"
                />
                <Button
                  text="Cancel"
                  customClass="w-full font-bold shadow-sm rounded-lg py-3 bg-red-600 text-white flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:bg-red-700 focus:shadow-sm focus:shadow-outline"
                  btnLoader={false}
                  btnType="button"
                  onClick={onClick}
                />
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export { MyProfile };
