import { AddJobTitleModalFormValues, AddJobTitleModalOption, AddJobTitleModalProps } from "@/models";
import { useAddJobTitle } from "@/supabase/jobs";
import { LoadingOutlined } from "@ant-design/icons";
import { Modal, Select, Spin } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";

const AddJobTitle: React.FC<AddJobTitleModalProps> = ({
  visible,
  handleCancelModal,
  setAddJobTitleData,
  allFormList,
}) => {
  const { addJobTitle } = useAddJobTitle();
  const [loader, setLoader] = React.useState(false);
  const [formValues, setFormValues] = React.useState<string[]>([]);

  const initialValues: AddJobTitleModalFormValues = {
    job_title: "",
    job_type: "",
  };

  const validationSchema = Yup.object().shape({
    job_title: Yup.string().required("Job Title is required"),
    job_type: Yup.string().required("Job Type is required"),
  });

  const handleSubmit = (values: any) => {
    addJobTitle(
      values,
      handleCancelModal,
      setLoader,
      setAddJobTitleData,
      formValues
    );
  };

  const handleGetOptions = () => {
    // Initialize an empty array to store the options
    const arr: AddJobTitleModalOption[] = [];

    // Map over allFormList and push each transformed object into arr
    allFormList?.forEach((ele: any) => {
      const obj = {
        label: ele?.title,
        value: ele?.formID,
      };
      arr.push(obj);
    });

    // Return the populated array
    return arr;
  };

  const handleValues = (values: { label: string; value: string }[]) => {
    // Extract the `value` properties from each object
    const valuesArray = values.map((item) => item.value);
    // Set the extracted values to your state
    setFormValues(valuesArray);
  };

  return (
    <div>
      <Modal
        title="Add New Option"
        visible={visible}
        onCancel={() => handleCancelModal()}
        footer={false}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <div>
              <label className="block font-medium">Job Title</label>
              <Field
                type="text"
                className="border-1 h-10 w-full rounded-md mt-1 px-2 focus:outline-none"
                name="job_title"
              />
              <ErrorMessage
                name="job_title"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>
            <div className="mt-3">
              <label className="block font-medium">Job Type</label>
              <Field
                type="text"
                className="border-1 h-10 w-full rounded-md mt-1 px-2 focus:outline-none"
                name="job_type"
              />
              <ErrorMessage
                name="job_type"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>
            <div className="mt-3">
              <label className="block font-medium">Form Progress</label>
              <Select
                mode="multiple"
                prefixCls="inner-select2"
                allowClear
                maxTagCount={1}
                style={{
                  width: "100%",
                  height: "48px",
                }}
                onChange={(_: string, data: any) => handleValues(data)}
                placeholder="Please select"
                options={handleGetOptions()}
              />
            </div>
            <div className="flex items-center gap-2 mt-3">
              <button
                className="bg-blue-500 p-2 px-4 w-full rounded-lg text-white font-semibold hover:bg-blue-700"
                type="submit"
              >
                {loader ? (
                  <Spin
                    className="text-white"
                    indicator={<LoadingOutlined spin />}
                  />
                ) : (
                  "Add"
                )}
              </button>
              <button
                className="bg-red-500 p-2 px-4 w-full rounded-lg text-white font-semibold hover:bg-red-700"
                onClick={() => handleCancelModal()}
              >
                Cancel
              </button>
            </div>
          </Form>
        </Formik>
      </Modal>
    </div>
  );
};

export { AddJobTitle };
