const registerUser = [
  {
    label: "Full Name",
    placeholder: "Full Name",
    type: "text",
    name: "fullName",
  },
  {
    label: "Australian Business Name",
    placeholder: "Australian Business Name",
    type: "text",
    name: "australianBusinessName",
  },
  {
    label: "Australian Number",
    placeholder: "Australian Number",
    type: "text",
    name: "australianNumber",
  },
  {
    label: "Email",
    placeholder: "Email",
    type: "email",
    name: "email",
  },
  {
    label: "Password",
    placeholder: "Password",
    type: "password",
    name: "password",
  },
  {
    label: "Confirm Password",
    placeholder: "Confirm Password",
    type: "password",
    name: "confirmPassword",
  },
];

const loginFields = [
  {
    label: "Email",
    placeholder: "Email",
    type: "email",
    name: "email",
  },
  {
    label: "Password",
    placeholder: "Password",
    type: "password",
    name: "password",
  },
];

const ChangePasswordFields = [
  {
    label: "Email",
    placeholder: "Email",
    type: "email",
    name: "email",
  },
  {
    label: "New Password",
    placeholder: "******",
    type: "password",
    name: "newPassword",
  },
];

const allJobsListing = [
  {
    jobName: "Marketing Coordinator",
    startDate: "1-6-2024",
    endDate: "2-7-2024",
  },
  {
    jobName: "President of Sales",
    startDate: "1-6-2024",
    endDate: "6-10-2024",
  },
];

const totalJobsHeadings = [
  "ID",
  "Invoice",
  "Due Date",
  "Confirmation",
  "Title",
  // "Job Type Template",
  "Billing Name",
  "Contact Name",
  "Location",
  "Job Number",
  // "Sales Person",
  // "Value",
  "Assigned",
  "Status",
];

const createJobsFields = [
  {
    label: "Title",
    placeholder: "Title",
    type: "text",
    name: "title",
  },
  {
    label: "Billing Name",
    placeholder: "John Deo",
    type: "text",
    name: "billingName",
  },
  {
    label: "Contact Name",
    placeholder: "John Deo",
    type: "text",
    name: "contactName",
  },
  {
    label: "Job Number",
    placeholder: "SSAPO - 1719",
    type: "text",
    name: "jobNumber",
  },
  {
    label: "Status",
    placeholder: "Completed",
    type: "text",
    name: "status",
  },
];

const customerDetailsHeadings = [
  "ID",
  "Full Name",
  "Phone Number",
  "Email",
  "Status",
  "",
];

const customerDetailsData = [
  {
    id: "10109566",
    fullName: "john Flo",
    phoneNumber: "123456789",
    email: "john Flo",
    status: "Active",
  },
  {
    id: "10109547",
    fullName: "Kwik Flo",
    phoneNumber: "123456789",
    email: "Kwik Flo",
    status: "Active",
  },
];

export {
  registerUser,
  loginFields,
  totalJobsHeadings,
  createJobsFields,
  customerDetailsHeadings,
  customerDetailsData,
  ChangePasswordFields,
  allJobsListing,
};
