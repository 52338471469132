import { JobsDataState } from "@/models";
import { createSlice } from "@reduxjs/toolkit";

const initialState: JobsDataState = {
    jobs: [],
};

const jobSlice = createSlice({
    name: "jobSlice",
    initialState,
    reducers: {
        storeJobs: (state, actions) => {
            state.jobs = actions.payload;
        },
    },
});

export const { storeJobs } = jobSlice.actions;
export default jobSlice.reducer;
