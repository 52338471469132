import { AssignRequestOrderNumProps } from "@/models";
import { ROLE } from "@/utils/enums";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import React from "react";

const AssignRequestOrderNumber: React.FC<AssignRequestOrderNumProps> = ({
  isOrderNumberEditable,
  record,
  orderNumberValue,
  setOrderNumberValue,
  setIsOrderNumberEditable,
  handleUpdateAssignTo,
  values,
  role,
}) => {
  React.useEffect(() => {
    setOrderNumberValue(values);
  }, []);
  return (
    <div>
      <span className="block overflow-hidden text-ellipsis whitespace-nowrap w-full">
        {isOrderNumberEditable !== record?.id ? (
          <span
            className={`flex ${values ? "justify-between" : "justify-end"} `}
          >
            {values}
            {role != ROLE.customer && role != ROLE.staff && (
              <EditOutlined
                className="cursor-pointer text-black"
                onClick={() => {
                  setIsOrderNumberEditable(record?.id);
                }}
              />
            )}
          </span>
        ) : (
          <span className="text-black flex items-center gap-2 relative">
            <input
              name="order"
              type="text"
              value={orderNumberValue || undefined}
              onChange={(e) => setOrderNumberValue(e.target.value)}
              placeholder="Enter order number"
              className="w-full border-1 rounded p-2 text-black"
            />
            <CloseOutlined
              onClick={() => {
                setIsOrderNumberEditable(null);
                setOrderNumberValue(null);
              }}
            />
            <CheckOutlined
              onClick={(e) => {
                handleUpdateAssignTo(record, "orderNumber");
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </span>
        )}
      </span>
    </div>
  );
};

export default AssignRequestOrderNumber;
