import React, { useState } from "react";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useAuth } from "@/supabase/auth";
import {
  AppLogo,
  GoogleLogo,
  MicrosoftLogo,
  OfficePhoto,
} from "@/utils/Images";
import TermsAndConditionsModal from "@/components/Modal/TermsAndConditionsModal";
import { Button } from "@/components";
import { useNavigate } from "react-router-dom";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { LoginFormValues } from "@/models";

const Login: React.FC = () => {
  const { loginUser, loginWithGoogle, loginWithMicrosoft } = useAuth();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const [isMicrosoftLoading, setIsMicrosoftLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [checkedValue, setCheckedValue] = useState(false);

  const initialValues: LoginFormValues = {
    email: "",
    password: "",
    checked: false,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
    checked: Yup.boolean().oneOf(
      [!checkedValue],
      "You must agree to the Terms and Conditions to proceed"
    ),
  });

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (
    values: LoginFormValues,
    { setSubmitting }: FormikHelpers<LoginFormValues>
  ) => {
    setIsLoading(true);
    try {
      await loginUser(values, setIsLoading);
    } catch (error) {
      console.error(error);
    }
    setSubmitting(false);
  };

  const handleGoogleLogin = async (validateForm: any, setTouched: any) => {
    const errors = await validateForm();
    if (!errors.checked) {
      setIsGoogleLoading(true);
      try {
        await loginWithGoogle();
      } catch (error) {
        setIsGoogleLoading(false);
      } finally {
        setIsGoogleLoading(false);
      }
    } else {
      setTouched({ checked: true });
    }
  };

  const handleMicrosoftLogin = async (validateForm: any, setTouched: any) => {
    const errors = await validateForm();
    if (!errors.checked) {
      setIsMicrosoftLoading(true);
      try {
        await loginWithMicrosoft();
      } catch (error) {
        setIsMicrosoftLoading(false);
      } finally {
        setIsMicrosoftLoading(false);
      }
    } else {
      setTouched({ checked: true });
    }
  };

  return (
    <div className="min-h-screen bg-gray-300 text-gray-900 flex justify-center">
      <div className="max-w-screen-xl m-0 sm:m-10 bg-white shadow sm:rounded-lg flex justify-center flex-1">
        <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-12">
          <div>
            <img src={AppLogo} className="w-32 mx-auto" alt="App Logo" />
          </div>
          <div className="mt-12 flex flex-col items-center">
            <h1 className="text-2xl font-semibold text-center">
              Smart Solutions Australia Gateway
            </h1>
            <div className="w-full flex-1 mt-8">
              <div className="mx-auto max-w-xs">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting, validateForm, setTouched }) => (
                    <Form>
                      <div className="flex flex-col items-center">
                        <Button
                          customClass="w-full max-w-xs font-bold shadow-sm rounded-lg py-3 bg-blue-500 text-white flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:bg-blue-700 focus:shadow-sm focus:shadow-outline"
                          onClick={() => {
                            handleGoogleLogin(validateForm, setTouched);
                          }}
                          btnLoader={isGoogleLoading}
                          text="Log in with Google"
                          btnType="button"
                        >
                          <div className="mr-3 bg-white p-2 rounded-full">
                            <img
                              className="w-4 h-4"
                              src={GoogleLogo}
                              alt="Google Logo"
                            />
                          </div>
                        </Button>
                      </div>
                      <div className="flex flex-col items-center mt-6">
                        <Button
                          customClass="w-full max-w-xs font-bold shadow-sm rounded-lg py-3 bg-blue-500 text-white flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:bg-blue-700 focus:shadow-sm focus:shadow-outline"
                          onClick={() => {
                            handleMicrosoftLogin(validateForm, setTouched);
                          }}
                          btnLoader={isMicrosoftLoading}
                          text="Log in with Microsoft"
                          btnType="button"
                        >
                          <div className="mr-3 bg-white p-2 rounded-full">
                            <img
                              className="w-4 h-4"
                              src={MicrosoftLogo}
                              alt="Microsoft Logo"
                            />
                          </div>
                        </Button>
                      </div>
                      <div className="my-12 border-b text-center">
                        <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2">
                          Or Log in with e-mail
                        </div>
                      </div>
                      <Field
                        name="email"
                        type="email"
                        className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                        placeholder="Email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-500 text-xs mt-1"
                      />
                      <div className="relative mt-5">
                        <Field
                          name="password"
                          type={passwordVisible ? "text" : "password"}
                          className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                          placeholder="Password"
                        />
                        <span
                          className="absolute right-3 top-4 cursor-pointer"
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        >
                          {passwordVisible ? (
                            <EyeInvisibleOutlined />
                          ) : (
                            <EyeOutlined />
                          )}
                        </span>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-red-500 text-xs mt-1"
                      />
                      <div className="flex items-center mt-4 gap-2">
                        <Field
                          type="checkbox"
                          name="checked"
                          className=""
                          checked={checkedValue}
                          onChange={(e: any) =>
                            setCheckedValue(e.target.checked)
                          }
                        />
                        <p className="text-xs text-gray-600">
                          By logging in I agree to abide by SSA's
                          <button
                            type="button"
                            className="border-b border-gray-500 border-dotted text-blue-500 mt-1"
                            onClick={openModal}
                          >
                            Terms and Conditions.
                          </button>
                        </p>
                      </div>
                      <ErrorMessage
                        name="checked"
                        component="div"
                        className="text-red-500 text-xs mt-1"
                      />
                      <Button
                        btnLoader={isSubmitting || isLoading}
                        btnType="submit"
                        customClass="mt-5 tracking-wide font-semibold bg-blue-500 text-white w-full py-4 rounded-lg hover:bg-blue-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                        text="Log in"
                      >
                        <svg
                          className="w-6 h-6 -ml-2"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                          <circle cx="8.5" cy="7" r="4" />
                        </svg>
                      </Button>
                      <div className="flex items-center justify-between mt-4">
                        <p className="text-base text-slate-400">
                          Don't have an account?
                        </p>
                        <p
                          className="cursor-pointer text-blue-500 font-medium text-base"
                          onClick={() => navigate("/")}
                        >
                          Sign Up
                        </p>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 bg-[rgb(1,0,120)] text-center hidden lg:flex rounded-r-md">
          <img className="rounded-r-md" src={OfficePhoto} alt="img" />
        </div>
      </div>
      <TermsAndConditionsModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default Login;
