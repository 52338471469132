import React, { useState, useEffect, useRef } from "react";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import { Button as CustomButton, DeleteModal } from "@/components";
import { Form, Input, Select, Button } from "antd";
import { useUsersListing } from "@/supabase/users";
import { useAppSelector } from "@/redux/store/Store";
import { ROLE } from "@/utils/enums";
import Papa from "papaparse";
import { Delete } from "@/utils/Images";
import { toast } from "react-toastify";
import {
  AdminDetailTableProps,
  DeleteAdminData,
  FormitizeUser,
  SupabaseFormitizeUser,
  UserDetail,
} from "@/models";
import { CustomTable } from "@/pages";
import { useDeleteUsers } from "@/supabase/jobs";

const { Option } = Select;

const EditableCell: React.FC<any> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  editing,
  formitizeUsers,
  ...restProps
}) => {
  let inputNode = <Input />;

  if (dataIndex === "role") {
    inputNode = (
      <Select style={{ maxHeight: "200px", overflowY: "auto" }}>
        <Option value={ROLE.admin}>Admin</Option>
        <Option value={ROLE.staff}>Staff</Option>
        <Option value={ROLE.customer}>Customer</Option>
      </Select>
    );
  } else if (dataIndex === "formitize_id") {
    inputNode = (
      <Select style={{ width: "200px", maxHeight: "200px", overflowY: "auto" }}>
        {formitizeUsers?.map((user: SupabaseFormitizeUser) => (
          <Option key={user.formitize_id} value={user.formitize_id}>
            {user.name} - {user.formitize_id}
          </Option>
        ))}
      </Select>
    );
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={
            dataIndex === "role" || dataIndex === "formitize_id"
              ? [{ required: true, message: `Please select ${title}!` }]
              : []
          }
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const AdminDetailsTable: React.FC<AdminDetailTableProps> = ({
  tableName,
  adminList,
}) => {
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [modalData, setModalData] = useState<DeleteAdminData | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { deleteUsers } = useDeleteUsers();
  const handleDeleteStaffUser = () => {
    deleteUsers(modalData?.id, handleCancelDeleteModal, synDataAfterDelete);
  };
  const [form] = Form.useForm();
  const {
    getUsersList,
    saveUserDetail,
    uploadFormitizeUsers,
    getFormitizeUsers,
  } = useUsersListing();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = useState(false);
  const formitizeUsers = useAppSelector(
    (state) => state.usersSlice.formitizeUsers
  );

  const isEditing = (record: UserDetail) => record.id === editingKey;

  const edit = (record: Partial<UserDetail>) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id ?? "");
  };

  const handleRefresh = () => {
    getFormitizeUsers();
  };

  const cancel = () => {
    setEditingKey(null);
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleCancelDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const onSave = async (key: string) => {
    try {
      const row = await form.validateFields();
      const newData = [...adminList];
      const index = newData.findIndex((item) => key === item.id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        saveUserDetail(newData[index]);
        setEditingKey(null);
      } else {
        newData.push(row);
        setEditingKey(null);
      }
    } catch (errInfo) {
      toast.error("Something went wrong");
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "full_name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      editable: true,
    },
    {
      title: "Business Name",
      dataIndex: "abn",
      key: "abn",
      editable: true,
    },
    {
      title: "Business Number",
      dataIndex: "aus_number",
      key: "aus_number",
      editable: true,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: UserDetail) => {
        const editable = isEditing(record);
        return editable ? (
          <span className="flex">
            <Button
              onClick={() => onSave(record.id)}
              icon={<SaveOutlined />}
              style={{ marginRight: 8 }}
            >
              Save
            </Button>
            <Button onClick={cancel}>Cancel</Button>
          </span>
        ) : (
          <div className="flex items-center gap-2">
            <Button
              disabled={editingKey !== null}
              onClick={() => edit(record)}
              icon={<EditOutlined />}
            >
              Edit
            </Button>
            <img
              src={Delete}
              alt=""
              className="w-6 h-6 max-w-6 cursor-pointer"
              onClick={() => {
                setModalData(record);
                handleOpenDeleteModal();
              }}
            />
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: UserDetail) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        formitizeUsers: formitizeUsers ?? [],
      }),
    };
  });

  const synDataAfterDelete = () => {
    getUsersList();
  };

  useEffect(() => {
    getUsersList();
  }, []);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the file input click
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploading(true);
      Papa.parse(file, {
        header: true,
        complete: (result: any) => {
          const uploadData = result?.data?.map((user: FormitizeUser) => {
            return {
              name: user.Name,
              email: user.Email,
              formitize_id: user.ID,
              super_admin: user["Super Administrator"],
              user_groups: user["User Groups"],
              user_type: user["User Type"],
              user_name: user.Username,
            };
          });
          uploadFormitizeUsers(uploadData, handleRefresh);
          setUploading(false);
        },
        error: (error: any) => {
          console.error("Error parsing CSV:", error);
          setUploading(false);
        },
      });
    }
  };

  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      {/* {openDeleteModal && (
        <DeleteAdminModal
          openDeleteModal={openDeleteModal}
          handleCancelDeleteModal={handleCancelDeleteModal}
          modalData={modalData}
          synDataAfterDelete={synDataAfterDelete}
        />
      )} */}
      {openDeleteModal && (
        <DeleteModal
          openModal={openDeleteModal}
          handleCancelModal={handleCancelDeleteModal}
          btnTextCncl="Cancel"
          text={`Are you sure you want to delete ${modalData?.full_name} from
          application?`}
          btnTextDlt="Delete"
          handleDelete={handleDeleteStaffUser}
        />
      )}
      <div className="flex items-center justify-between mb-6">
        <h4 className="text-xl font-semibold text-black">{tableName}</h4>
        <CustomButton
          text="Upload Users"
          customClass="bg-custom-bg text-white pt-2 pb-2 pr-4 pl-4 rounded-lg"
          btnType="button"
          btnLoader={uploading}
          onClick={() => {
            handleClick();
          }}
        />
        <input
          type="file"
          ref={fileInputRef}
          accept=".csv"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </div>
      <CustomTable
        columns={mergedColumns}
        dataSource={adminList}
        form={form}
        EditableCell={EditableCell}
      />
    </div>
  );
};

export { AdminDetailsTable };
