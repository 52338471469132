import { ClientState } from "@/models";
import { createSlice } from "@reduxjs/toolkit";

const initialState: ClientState = {
    clientList: undefined,
};

const clientsSlice = createSlice({
    name: "clientSlice",
    initialState,
    reducers: {
        storeClientData: (state, action) => {
            state.clientList = action.payload;
        },
    },
});

export const { storeClientData } = clientsSlice.actions;
export default clientsSlice.reducer;
