import React from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { RootState } from "@/redux/store/Store";

const GlobalLoader: React.FC = () => {
  const isLoading = useSelector((state: RootState) => state.loader.isLoading);
  const customIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

  if (!isLoading) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-9999">
      <Spin indicator={customIcon} />
    </div>
  );
};

export { GlobalLoader };
