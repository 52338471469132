import { AssignShiftProps } from "@/models";
import { useAppSelector } from "@/redux/store/Store";
import { useUpdateRemainingJobColumn } from "@/supabase/jobs";
import { ROLE } from "@/utils/enums";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { debounce } from "lodash";
import React from "react";

const AssignShift: React.FC<AssignShiftProps> = ({
  values,
  record,
  isShiftEditable,
  setIsShiftEditable,
  onSyncClick,
}) => {
  const role = useAppSelector((state) => state.Authlogin?.data?.role);
  const { updateRemainingJobColumn } = useUpdateRemainingJobColumn();
  const options = [
    {
      label: "Day",
      value: "Day",
    },
    {
      label: "Night",
      value: "Night",
    },
  ];

  const debouncedUpdateColumnQuickly = debounce(
    (obj: any, onSyncClick: any, setIsShiftEditable: any) => {
      updateRemainingJobColumn(obj, onSyncClick, setIsShiftEditable);
    },
    500 // Adjust debounce delay as needed
  );

  const handleUpdateAssignTo = (e: any) => {
    let obj = {
      crew: "",
      truck: record?.assignAssets,
      extraCharges: record?.extraCharges,
      shift: e,
      id: record?.id,
      contactName: record?.contactName,
    };
    debouncedUpdateColumnQuickly(obj, onSyncClick, setIsShiftEditable);
  };
  return (
    <div>
      {isShiftEditable == record?.id && isShiftEditable ? (
        <div className="flex items-center gap-2">
          <Select
            allowClear
            value={record?.shift}
            style={{
              width: "100%",
              height: "48px",
            }}
            onChange={(e: any, data: any) => {
              handleUpdateAssignTo(e);
            }}
            placeholder="Please select"
            options={options}
          />
          <div className="flex items-center gap-2">
            <CloseOutlined
              onClick={(e) => {
                setIsShiftEditable(null);
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </div>
        </div>
      ) : (
        <div
          className={`flex items-center whitespace-nowrap gap-2 ${
            values ? "justify-between" : "justify-end"
          }`}
        >
          <span>{values}</span>
          {role != ROLE.customer && role != ROLE.staff && (
            <EditOutlined
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsShiftEditable(record?.id);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AssignShift;
