import { TermsModalProps } from "@/models";
import React from "react";

const TermsModal: React.FC<TermsModalProps> = ({ isOpen, onClose }) => {
  return (
    <div
      className={`fixed z-10 inset-0 overflow-y-auto ${isOpen ? "" : "hidden"}`}
    >
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full mx-4">
          <h2 className="text-2xl font-bold mb-4">Terms and Conditions</h2>
          <div className="max-h-96 overflow-y-scroll leading-relaxed">
            <h3 className="font-semibold mb-1">Sectional Patch Repair</h3>
            <p>
              <strong>1. Introduction:</strong>
            </p>
            <p>
              These Terms and Conditions govern the provision of sectional patch
              repairing services provided by Smart Solutions Australia Pty Ltd
              to you, the Customer. By accepting a quote, you agree to these
              Terms and Conditions in full.
            </p>
            <p>
              <strong>2. Service Description:</strong>
            </p>
            <p>
              We specialize in the repair of sectional damages through patching,
              ensuring a seamless blend with the existing material. Our services
              include but are not limited to inspection, patching, and
              finishing.
            </p>
            <p>
              <strong>3. Quotes:</strong>
            </p>
            <p>
              All quotes are valid for 30 days from the date issued. The final
              cost may vary depending on the actual conditions assessed during
              the job commencement. Additional services not covered in the
              initial quote will be charged separately.
            </p>
            <p>
              <strong>4. Payment:</strong>
            </p>
            <p>
              A deposit of 20% is required to commence work, with the balance
              due upon completion. Payment terms are strictly net 30 days from
              the completion date. All Payment to be made out to Smart Solutions
              Australia Group.
            </p>
            <p>
              <strong>5. Scheduling and Timeliness:</strong>
            </p>
            <p>
              Repair schedules will be agreed upon acceptance of the quote.
              While we strive to adhere to the scheduled times, delays may occur
              due to unforeseen circumstances. We will communicate any changes
              in a timely manner.
            </p>
            <p>
              <strong>6. Guarantee and Warranty:</strong>
            </p>
            <p>Warranty/Guarantee provided separately.</p>
            <p>
              <strong>7. Liability</strong>
            </p>
            <p>
              Our liability for any claims related to the services provided
              shall not exceed the total cost of the service provided. We are
              not liable for any indirect, special, or consequential losses. If
              the drain is deemed unsuitable for patching due to issues with
              cleanliness, pit access, or any other circumstances that are
              beyond our control. SSA will levy a minimum charge for 6 hours at
              the rate for each plant resource, such as trucks or other
              machinery/services, utilized on-site in accordance with our
              schedule of rates. We shall not be liable for any delay or failure
              to perform our obligations due to any cause beyond our reasonable
              control, including but not limited to acts of nature, government
              actions, or labour disputes.
            </p>
            <p>
              <strong>8. Cancellation</strong>
            </p>
            <p>
              Cancellations must be made in writing at least 2 days (48 hours)
              before the scheduled service date. Cancellations made after this
              period may incur a cancellation fee of 10% of the total price. A
              cancellation made on the same day as the booking SSA will levy a
              minimum charge for 6 hours at the rate for each plant/resource,
              such as trucks or other machinery/services, utilized on-site in
              accordance with our schedule of rates.
            </p>
            <p>
              <strong>9. Amendment</strong>
            </p>
            <p>
              We reserve the right to amend these Terms and Conditions at any
              time. Your continued use of our services after any changes
              constitutes your acceptance of the latest Terms and Conditions.
            </p>
            <h4 className="font-semibold mt-1 mb-1">
              Vacuum Excavation CCTV Jetting
            </h4>
            <p>
              <strong>1. Definitions</strong>
            </p>
            <p>
              <strong>1.1 “Contractor”</strong> means Smart Solutions Australia
              Group Pty Ltd, its successors and assigns or any person acting on
              behalf of and with the authority of Smart Solutions Australia
              Group Pty Ltd.
            </p>
            <p>
              <strong>1.2 “Customer”</strong> means the person/s requesting the
              Contractor to provide the Services/Equipment as specified in any
              invoice, document, or order, and if there more than one person
              requesting the Services/Equipment is a reference to each person
              jointly and severally.
            </p>
            <p>
              <strong>1.3 “Incidental Items”</strong> means any goods,
              documents, designs, drawings, or materials supplied, consumed,
              created, or deposited incidentally by the Contractor in the course
              of it conducting, or supplying to the Customer, any Services.
            </p>
            <p>
              <strong>1.4 “Equipment”</strong> means all Equipment including any
              accessories supplied on hire by the Contractor to the Customer
              (and where the context so permits shall include any supply of
              Services). The Equipment shall be as described on the invoices,
              quotation, authority to hire, or any other work authorization form
              provided by the Contractor to the Customer.
            </p>
            <p>
              <strong>1.5 “Services”</strong> means all Services supplied by the
              Contractor to the Customer at the Customer’s request from time to
              time.
            </p>
            <p>
              <strong>1.6 “Price”</strong> means the price payable for the
              Services and/or Equipment hire as agreed between the Contractor
              and the Customer in accordance with clause 4 of this contract.
            </p>
            <p>
              <strong>2. Acceptance</strong>
            </p>
            <p>
              <strong>2.1</strong> The Customer is taken to have exclusively
              accepted and is immediately bound, jointly and severally, by these
              terms and conditions if the Customer places an order for or
              accepts quotations or proposals or Services/Equipment provided by
              the Contractor.
            </p>
            <p>
              <strong>2.2</strong> These terms and conditions may only be
              amended with the Contractor’s consent in writing and shall prevail
              to the extent of any inconsistency with any other document or
              agreement between the Customer and the Contractor.
            </p>
            <p>
              <strong>3. Change in Control</strong>
            </p>
            <p>
              <strong>3.1</strong> The Customer shall give the Contractor not
              less than fourteen (14) days prior written notice of any proposed
              change of ownership of the Customer and/or any other change in the
              Customer’s details (including but not limited to, changes in the
              Customer’s name, address, contact phone or fax number/s, or
              business practice). The Customer shall be liable for any loss
              incurred by the Contractor as a result of the Customer’s failure
              to comply with this clause.
            </p>
            <p>
              <strong>4. Price and Payment</strong>
            </p>
            <p>
              <strong>4.1</strong> At the Contractor’s sole discretion, the
              Price shall be either:
            </p>
            <p>
              (a) as indicated on any invoice provided by the Contractor to the
              Customer; or
            </p>
            <p>
              (b) the Price as at the date of delivery of the Services/Equipment
              according to the Contractor’s current price list; or
            </p>
            <p>
              (c) the Contractor’s quoted price (subject to clause 4.2) which
              will be valid for the period stated in the quotation or otherwise
              for a period of thirty (30) days.
            </p>
            <p>
              (d) Early Payment Discount: An early payment discount will apply
              in accordance with your credit account agreement. A 2.5% discount
              will apply for early payment.
            </p>
            <p>
              (e) Late Payment Interest: Should payment exceed the agreed terms,
              an interest charge of 2.5% per week will accrue from the invoice
              date until the payment is fully received. This is to compensate
              for the delay and to encourage adherence to the agreed payment
              schedule.
            </p>
            <p>
              <strong>4.2</strong> The Contractor reserves the right to change
              the Price if a variation to the Contractor’s quotation is
              requested. Any variation from the plan of scheduled works or
              specifications (including, but not limited to, any variation as a
              result of additional works required due to unforeseen
              circumstances or as a result of any increase to the Contractor in
              the cost of materials and labour) will be charged for on the basis
              of the Contractor’s quotation and will be shown as variations on
              the invoice. Payment for all variations must be made in full at
              their time of completion.
            </p>
            <p>
              <strong>4.3</strong> Time for payment for the Services/Equipment
              being of the essence, the Price will be payable by the Customer on
              the date/s determined by the Contractor, which may be:
            </p>
            <p>(a) on delivery of the Services/Equipment;</p>
            <p>
              (b) the date specified on any invoice or other form as being the
              date for payment; or
            </p>
            <p>
              (c) failing any notice to the contrary, the date which is the
              completion date of the Services/Work undertaken.
            </p>
            <p>
              <strong>4.4</strong> Payment may be made by cash, check, bank
              check, electronic/on-line banking, or by any other method as
              agreed to between the Customer and the Contractor.
            </p>
            <p>
              <strong>4.5</strong> Unless otherwise stated, the Price does not
              include GST. In addition to the Price, the Customer must pay to
              the Contractor an amount equal to any GST the Contractor must pay
              for any supply by the Contractor under this or any other agreement
              for providing the Contractor’s Services/Equipment. The Customer
              must pay GST, without deduction or set off of any other amounts,
              at the same time and on the same basis as the Customer pays the
              Price. In addition, the Customer must pay any other taxes and
              duties that may be applicable in addition to the Price except
              where they are expressly included in the Price.
            </p>
            <p>
              <strong>5. Delivery of Services/Equipment</strong>
            </p>
            <p>
              <strong>5.1</strong> At the Contractor’s sole discretion delivery
              of the Services/Equipment shall take place when the
              Services/Equipment are supplied to the Customer at the Customer’s
              nominated address.
            </p>
            <p>
              <strong>5.2</strong> Delivery of the Services to a third party
              nominated by the Customer is deemed to be delivery to the Customer
              for the purposes of this agreement.
            </p>
            <p>
              <strong>5.3</strong> The Contractor may deliver the
              Services/Equipment by separate instalments. Each separate
              instalment shall be invoiced and paid for in accordance with the
              provisions in these terms and conditions.
            </p>
            <p>
              <strong>5.4</strong> Any time specified by the Contractor for
              delivery of the Services/Equipment is an estimate only and the
              Contractor will not be liable for any loss or damage incurred by
              the Customer as a result of delivery being late. However, both
              parties agree that they shall make every endeavour to enable the
              Services/Equipment to be supplied at the time and place as was
              arranged between both parties. In the event that the Contractor is
              unable to supply the Services/Equipment as agreed solely due to
              any action or inaction of the Customer then the Contractor shall
              be entitled to charge a reasonable fee for re-supplying the
              Services/Equipment at a later time and date.
            </p>
            <p>
              <strong>6. Risk</strong>
            </p>
            <p>
              <strong>6.1</strong> Irrespective of whether the Contractor
              retains ownership of any Incidental Items, all risk for such items
              shall pass to the Customer as soon as such items are delivered to
              the Customer and shall remain with the Customer until such time as
              the Contractor may repossess the Incidental Items. The Customer
              must insure all Incidental Items on or before delivery.
            </p>
            <p>
              <strong>6.2</strong> The Contractor reserves its right to seek
              compensation or damages for any damage, destruction, or loss
              suffered in relation to the Incidental Items as a result of the
              Customer’s failure to insure in accordance with clause 6.1.
            </p>
            <p>
              <strong>7. Access</strong>
            </p>
            <p>
              <strong>7.1</strong> The Customer shall ensure that the Contractor
              has clear and free access to the work site at all times to enable
              them to undertake the works. The Contractor shall not be liable
              for any loss or damage to the site (including, without limitation,
              damage to pathways, driveways, and concreted or paved or grassed
              areas) unless due to the negligence of the Contractor.
            </p>
            <p>
              <strong>7.2</strong> The Customer shall be responsible (at the
              Customer’s cost) for the removal of any trees, plants, or any
              other obstacles in the vicinity of the work site that are in the
              way of the Contractor undertaking the works.
            </p>
            <p>
              <strong>8. Underground Locations</strong>
            </p>
            <p>
              <strong>8.1</strong> Prior to the commencement of any excavation
              works, the Customer must advise the Contractor, in writing, of the
              precise location of all underground services on the site and
              clearly mark the same. The underground mains services the Customer
              must identify include, but are not limited to, electrical
              services, gas services, sewer services, pumping services, sewer
              connections, sewer sludge mains, water mains, irrigation pipes,
              telephone cables, and any other services that may be on site. In
              the absence of notification, the Contractor will take all care to
              avoid damage to services and will not be liable to repair or
              replace any damaged services unless such damage was due to the
              Contractor’s negligence.
            </p>
            <p>
              <strong>8.2</strong> Should the Customer fail to identify any
              services as required, the Customer will be solely liable for any
              loss or damage suffered by the Contractor in connection with the
              Contractor’s failure to identify such services.
            </p>
            <p>
              <strong>9. Title to Incidental Items</strong>
            </p>
            <p>
              <strong>9.1</strong> Notwithstanding the fact that Incidental
              Items may be supplied or installed by the Contractor, all
              Incidental Items shall remain the property of the Contractor until
              such time as full payment has been made by the Customer and
              received by the Contractor.
            </p>
            <p>
              <strong>9.2</strong> In the event of the Customer being in default
              of payment of any invoice issued by the Contractor, the Contractor
              may recover possession of any Incidental Items at any site owned,
              possessed, or controlled by the Customer and the Customer grants
              the Contractor an irrevocable right to enter upon the premises
              owned or occupied by the Customer or any site where the Incidental
              Items are situated.
            </p>
            <p>
              <strong>10. Default and Consequences of Default</strong>
            </p>
            <p>
              <strong>10.1</strong> Interest on overdue invoices shall accrue
              daily from the date when payment becomes due until the date of
              payment at a rate of 2.5% per calendar month (and at the
              Contractor’s sole discretion such interest shall compound monthly
              at such a rate) after as well as before any judgment.
            </p>
            <p>
              <strong>10.2</strong> If the Customer owes the Contractor any
              money the Customer shall indemnify the Contractor from and against
              all costs and disbursements incurred by the Contractor in
              recovering the debt (including but not limited to internal
              administration fees, legal costs on a solicitor and own client
              basis, the Contractor’s contract default fee, and bank dishonour
              fees).
            </p>
            <p>
              <strong>10.3</strong> Further to any other rights or remedies the
              Contractor may have under this contract, if a Customer has made
              payment to the Contractor, and the transaction is subsequently
              reversed, the Customer shall be liable for the amount of the
              reversed transaction, in addition to any further costs incurred by
              the Contractor under this clause 10 where it can be proven that
              such reversal is found to be illegal, fraudulent, or in
              contravention to the Customer’s obligations under this agreement.
            </p>
            <p>
              <strong>11. Cancellation</strong>
            </p>
            <p>
              <strong>11.1</strong> Without prejudice to any other remedies the
              Contractor may have, if at any time the Customer is in breach of
              any obligation (including those relating to payment), the
              Contractor may suspend or terminate the supply of
              Services/Equipment to the Customer and any of its other
              obligations under the terms and conditions. The Contractor will
              not be liable to the Customer for any loss or damage the Customer
              suffers because the Contractor exercised its rights under this
              clause.
            </p>
            <p>
              <strong>Cancellation Fee:</strong> All cancellations made within 4
              hours from the scheduled start time of the shift will incur a
              cancellation fee equivalent to a 4-hour minimum charge based on
              the price of the asset.
            </p>
            <p>
              <strong>Cancellation After Shift Commencement:</strong> If a
              cancellation occurs after the shift has commenced from the depot,
              a minimum charge will apply based on the booking minimum specified
              for that shift as outlined in our schedule of rates.
            </p>
            <p>
              <strong>12. General</strong>
            </p>
            <p>
              <strong>12.1</strong> If any provision of these terms and
              conditions shall be invalid, void, illegal, or unenforceable the
              validity, existence, legality, and enforceability of the remaining
              provisions shall not be affected, prejudiced, or impaired.
            </p>
            <p>
              <strong>12.2</strong> These terms and conditions and any contract
              to which they apply shall be governed by the laws of the state in
              which the Contractor has its principal place of business and are
              subject to the jurisdiction of the courts in that state.
            </p>
            <p>
              <strong>12.3</strong> The Contractor shall be under no liability
              whatsoever to the Customer for any indirect and/or consequential
              loss and/or expense (including loss of profit) suffered by the
              Customer arising out of a breach by the Contractor of these terms
              and conditions (alternatively, the Contractor’s liability shall be
              limited to damages which under no circumstances shall exceed the
              Price of the Services/Equipment).
            </p>
            <p>
              <strong>12.4</strong> The Customer shall not be entitled to set
              off against or deduct from the Price any sums owed or claimed to
              be owed to the Customer by the Contractor.
            </p>
            <p>
              <strong>12.5</strong> The Contractor may license or sub-contract
              all or any part of its rights and obligations without the
              Customer’s consent.
            </p>
            <p>
              <strong>12.6</strong> The Contractor reserves the right to review
              these terms and conditions at any time. If, following any such
              review, there is to be any change to these terms and conditions,
              then that change will take effect from the date on which the
              Contractor notifies the Customer of such change.
            </p>
            <p>
              <strong>12.7</strong> Neither party shall be liable for any
              default due to any act of God, war, terrorism, strike, lock-out,
              industrial action, fire, flood, storm, or other event beyond the
              reasonable control of either party.
            </p>
            <p>
              <strong>12.8</strong> The failure by the Contractor to enforce any
              provision of these terms and conditions shall not be treated as a
              waiver of that provision, nor shall it affect the Contractor’s
              right to subsequently enforce that provision.
            </p>
          </div>
          <button
            onClick={onClose}
            className="mt-4 px-4 py-2 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsModal;
