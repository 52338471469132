import { AssignToProps } from "@/models";
import { useAppSelector } from "@/redux/store/Store";
import {
  useUpdateAssignedJobDetails,
  useUpdateAssignToCreatedJobs,
} from "@/supabase/jobs";
import { ROLE } from "@/utils/enums";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { debounce } from "lodash";
import React from "react";
import { toast } from "react-toastify";

const AssignTo: React.FC<AssignToProps> = ({
  record,
  assignedData,
  setIsEditable,
  isEditable,
  role,
  onSyncClick,
}) => {
  const [updatedAssignTo, setUpdatedAssignTo] = React.useState<string[] | null>(
    null
  );
  const allStoredAssets = useAppSelector(
    (state) => state?.usersSlice?.formitizeUsers
  );
  const { updateAsignedJobDetails } = useUpdateAssignedJobDetails();
  const { updateAssignToCreatedJobs } = useUpdateAssignToCreatedJobs();
  const [prefilledData, setPrefilledData] = React.useState(null);
  const showAssignTo =
    updatedAssignTo &&
    updatedAssignTo.map((option: any) => option?.label).join(", ");
  const jobsObj = useAppSelector((state) => state?.usersSlice?.formitizeUsers);

  const assignedToArray = Array.isArray(record?.assignedTo)
    ? record.assignedTo
    : [];

  const selectedLabels = assignedToArray?.map((assignedId: any) => {
    const option = assignedData?.find((val: any) => val?.value === assignedId);
    return option ? option.value : ""; // Get the label or an empty string
  });

  React.useEffect(() => {
    setUpdatedAssignTo(selectedLabels);
  }, [selectedLabels?.length > 0]);

  const handleValues = (values: any, data: any) => {
    setPrefilledData(data);
    setUpdatedAssignTo(values);
  };

  const debouncedUpdateColumnQuickly = debounce(
    (obj: any, onSyncClick: any, id: any, setIsEditable: any) => {
      updateAsignedJobDetails(obj, onSyncClick, id, setIsEditable);
    },
    500 // Adjust debounce delay as needed
  );

  const debouncedUpdateCreatedJobs = debounce(
    (
      supabaseObj: any,
      onSyncClick: any,
      id: any,
      newKeysObj: any,
      setIsEditable: any
    ) => {
      updateAssignToCreatedJobs(
        supabaseObj,
        onSyncClick,
        id,
        newKeysObj,
        setIsEditable
      );
    },
    500 // Adjust debounce delay as needed
  );

  const handleUpdateAssignTo = (prefilledData: any, updatedAssignTo: any) => {
    if (prefilledData) {
      const dueDate = new Date(prefilledData?.dueDate * 1000);
      // Calculate the end date by adding the duration (in seconds)
      const endDate = new Date(
        dueDate.getTime() + parseInt(prefilledData?.duration) * 1000
      );

      const supabaseAssignto =
        updatedAssignTo?.length > 0 &&
        updatedAssignTo?.map((option: any) => option?.value);

      // Get the timestamp of the end date in seconds
      const endDateTimestamp = Math.floor(endDate.getTime() / 1000);
      const formIds = Object.values(prefilledData?.forms || {})?.map(
        (form: any) => form.id
      );
      const compareAssignTo =
        updatedAssignTo && Array.isArray(updatedAssignTo)
          ? updatedAssignTo?.map((option: any) => option.value)
          : updatedAssignTo?.value;
      const matchedAssigTo = allStoredAssets?.filter((user) =>
        compareAssignTo?.includes(user?.formitize_id)
      );
      const matchedAssignToNames =
        matchedAssigTo && Array.isArray(matchedAssigTo)
          ? matchedAssigTo?.map((val: any) => val?.name)
          : matchedAssigTo;
      const extractNotes = (description: string) => {
        const notesMatch = description.match(/Notes:\s*(.*)/);
        return notesMatch ? notesMatch[1].trim() : "";
      };

      // Extract the Notes value from prefilledData.description
      const extractedNotes = extractNotes(prefilledData.description);
      const supabaseNotes = `Client Name: ${
        prefilledData?.contactName ? prefilledData?.contactName : ""
      }\n${
        (prefilledData?.assignAssets &&
          `Vehichle Registration: ${prefilledData?.assignAssets}`) ||
        ""
      }\nDrivers: ${matchedAssignToNames || ""}${
        extractedNotes ? `\nNotes: ${extractedNotes}` : ""
      }`;
      const formattedDueDate = Math.floor(new Date(dueDate).getTime() / 1000);
      const newObj = {
        billingName: prefilledData?.billingName || "",
        contactName: prefilledData?.contactName || "",
        title: prefilledData?.title || "",
        location: prefilledData?.location || "",
        dueDate: formattedDueDate || "",
        agent: updatedAssignTo?.[0]?.value || updatedAssignTo?.value || [],
        status: prefilledData?.status || "",
        confirmed: prefilledData?.confirmed || "",
        siteName: prefilledData?.siteName || "",
        zone: prefilledData?.zone || "",
        jobType: prefilledData?.title || "",
        jobTitle: prefilledData?.title || "",
        notes: supabaseNotes || "",
        form: formIds || "",
        endDate: endDateTimestamp,
        assignAssets: prefilledData?.assignAssets || "",
        priority: prefilledData?.priority || "",
        invoice: prefilledData?.invoice || "",
        jobValue: prefilledData?.jobValue || "",
        salesPerson: prefilledData?.salesPerson || "",
        salesSource: prefilledData?.salesSource || "",
        isDisplay: prefilledData?.isDisplay || "",
        invoiceNumber: prefilledData?.invoice || "",
      };
      let obj = {
        billingName: prefilledData?.billingName || "",
        title: prefilledData?.jobTitle || "",
        location: prefilledData?.location || "",
        dueDate: prefilledData?.dueDate,
        assignedTo: supabaseAssignto || [],
        status: prefilledData?.status || "",
        confirmed: prefilledData?.confirmed || "",
        siteName: prefilledData?.siteName || "",
        zone: prefilledData?.zone || "",
        jobType: prefilledData?.jobType || "",
        jobTitle: prefilledData?.jobTitle || "",
        description: supabaseNotes || "",
        orderNumber: prefilledData?.orderNumber || "",
        form: prefilledData?.form,
        endDate: prefilledData?.endDate,
        assignAssets: [],
        priority: prefilledData?.priority || "",
        invoice: prefilledData?.invoice || "",
        jobValue: prefilledData?.jobValue || "",
        salesPerson: prefilledData?.salesPerson || "",
        salesSource: prefilledData?.salesSource || "",
        isDisplay: prefilledData?.isDisplay || false,
      };
      let newKeysObj = {
        extraCharges: prefilledData?.extraCharges || "",
        shift: prefilledData?.shift || "",
        contactName: prefilledData?.contactName || "",
        assignAssets: prefilledData?.assignAssets || [],
      };
      {
        !record?.source
          ? debouncedUpdateColumnQuickly(
              newObj,
              onSyncClick,
              prefilledData?.id,
              setIsEditable
            )
          : debouncedUpdateCreatedJobs(
              obj,
              onSyncClick,
              prefilledData?.id,
              newKeysObj,
              setIsEditable
            );
      }
    } else {
      toast.warning("Nothing is change to update");
    }
  };

  React.useEffect(() => {
    // Extract the "Drivers:" line from the description dynamically
    const driversIndex = record?.description?.indexOf("Drivers:") || -1;
    const drivers =
      driversIndex !== -1
        ? record?.description
            ?.slice(driversIndex + 8)
            .split("\n")[0]
            .trim() // Extract and clean the Drivers line
        : "";

    // Split the drivers string into an array of driver names (handles variable length)
    const driverNames = drivers
      ? drivers.split(",").map((name: any) => name.trim())
      : [];

    // Match driver names with `assignedData` based on the `label` field
    const matchedDrivers = assignedData?.filter((val: any) =>
      driverNames.includes(val.label)
    );

    // If matched drivers are found, set the updated assigned value
    if (matchedDrivers.length > 0) {
      setUpdatedAssignTo(matchedDrivers); // Set matched driver data in the state
    } else {
      setUpdatedAssignTo(null); // Clear the state if no matches found
    }
  }, [isEditable, record?.description, assignedData]);

  return (
    <span className="whitespace-nowrap">
      {isEditable === record?.id ? (
        <div className="flex items-center gap-2">
          <Select
            allowClear
            showSearch
            mode="multiple"
            maxTagCount={1}
            value={updatedAssignTo} // Pass the array of selected labels
            style={{
              width: "100%",
              height: "48px",
            }}
            onChange={(_: string[], data: any) => handleValues(data, record)}
            placeholder="Please select"
            options={assignedData}
            filterOption={(input, option) =>
              option?.label.toLowerCase().includes(input.toLowerCase())
            }
          />
          <div className="flex items-center gap-2">
            <CloseOutlined
              onClick={(e) => {
                setIsEditable(null);
                e.preventDefault();
                e.stopPropagation();
              }}
            />
            <CheckOutlined
              onClick={(e) => {
                handleUpdateAssignTo(prefilledData, updatedAssignTo);
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </div>
        </div>
      ) : (
        <span
          className={`flex items-center gap-2 ${
            showAssignTo ? "justify-between" : "justify-end"
          }`}
        >
          {/* Join all job names with a comma */}
          {showAssignTo}
          {role !== ROLE.customer && role !== ROLE.staff && (
            <EditOutlined
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsEditable(record?.id);
              }}
            />
          )}
        </span>
      )}
    </span>
  );
};

export default AssignTo;
