/* eslint-disable eqeqeq, @typescript-eslint/no-floating-promises */
import React, { useState } from "react";
import { Table, Tooltip } from "antd";
import type { TableColumnsType, TableProps } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  useDeleteRequestJob,
  useSubmitJobRequest,
  useUpdateRequestJob,
} from "@/supabase/jobs";
import { useAppSelector } from "@/redux/store/Store";
import { toast } from "react-toastify";
import { ROLE } from "@/utils/enums";
import AssignRequestAssets from "./AssignRequestAssets";
import AssignRequestJobNumber from "./AssignRequestJobNumber";
import AssignRequestOrderNumber from "./AssignRequestOrderNumber";
import AssignRequestExtraCharges from "./AssignRequestExtraCharges";
import AssignRequestDriver from "./AssignRequestDriver";
import { debounce } from "lodash";
import { JobRequestTableDataType, JobRequestTableProps } from "@/models";

const formatDate = (timeStamp: string): string => {
  return moment.unix(Number(timeStamp)).format("ll, hh:mm a");
};

const onChange: TableProps<JobRequestTableDataType>["onChange"] = (
  pagination,
  filters,
  sorter,
  extra
) => {};

const JobRequestTable: React.FC<JobRequestTableProps> = ({
  tableName,
  tableData,
  handleFetchData,
}) => {
  const jobsObj = useAppSelector((state) => state?.usersSlice?.formitizeUsers);
  const assignedData = jobsObj?.map((item) => ({
    label: item.name,
    value: item.formitize_id,
  }));
  const { submitJobRequest } = useSubmitJobRequest();
  const [extraChargesValue, setExtraChargesValue] = useState<string | null>(
    null
  );
  const storedAssets = useAppSelector((state) => state?.storedAssets?.assets);
  const transformedArray = storedAssets?.map((item) => ({
    label: item?.name,
    value: item?.name,
  }));
  const { updateRequestJob } = useUpdateRequestJob();
  const [jobNumberValue, setJobNumberValue] = useState<string | null>(null);
  const [orderNumberValue, setOrderNumberValue] = useState<string | null>(null);
  const [isMobileView, setIsMobileView] = useState(false);
  const userRole = useAppSelector((state) => state.Authlogin.data?.role);
  const userData = useAppSelector((state) => state.Authlogin);
  const { deleteRequestJob } = useDeleteRequestJob();
  const [isEditable, setIsEditable] = React.useState<string | null>(null);
  const [isExtraChargeEditable, setIsExtraChargeEditable] = useState<
    string | null
  >(null);
  const [isOrderNumberEditable, setIsOrderNumberEditable] = useState<
    string | null
  >(null);
  const [updatedAssets, setUpdatedAssets] = useState([]);
  const role = useAppSelector((state) => state.Authlogin?.data?.role);
  const allStoredAssets = useAppSelector(
    (state) => state?.usersSlice?.formitizeUsers
  );
  const [setDeleteCheck] = React.useState(false);
  const [crewName, setCrewName] = useState([]);
  const [isTruckEditable, setIsTruckEditable] = React.useState<string | null>(
    null
  );
  const [isJobNumberEditable, setIsJobNumberEditable] = React.useState<
    string | null
  >(null);
  const customerJobs = tableData?.filter(
    (item) => item.billingName == userData?.data?.customer_formitize_id
  );

  const debouncedSubmitJobRequest = debounce(
    (reqObj: any, id: any, handleFetchData: any, newKeysObj: any) => {
      submitJobRequest(reqObj, id, handleFetchData, newKeysObj);
    },
    500 // Debounce delay in milliseconds (adjust as needed)
  );

  const handleSubmitRequest = (values: any) => {
    const allCrewNames = crewName?.map((val: any) => val?.value);
    const matchedAssets = allStoredAssets?.filter((user) =>
      values?.assignedTo?.includes(user?.formitize_id)
    );
    const matchedNames = matchedAssets?.map((user) => user?.name);
    const combinedNotes = `Client Name: ${
      values.contactName && values.contactName
    }\n${
      (values?.assignAssets &&
        `Vehichle Registration: ${values?.assignAssets}`) ||
      ""
    }\nDrivers: ${matchedNames || ""}${
      values?.latestNote ? `\nNotes: ${values?.latestNote}` : ""
    }`;

    let reqObj = {
      billingName: values?.billingName || "",
      title: values?.jobTitle || "",
      location: values?.location || "",
      dueDate: values?.dueDate || "",
      assignedTo: values?.assignedTo || allCrewNames || "",
      confirmed: values?.confirmed || "",
      siteName: values?.siteName || "",
      zone: values?.zone || "",
      jobType: values?.jobTitle || "",
      jobTitle: values?.jobTitle || "",
      description: combinedNotes || "",
      form: values?.form,
      endDate: values?.endDate || "",
      priority: values?.priority || "",
      invoice: values?.invoice || "",
      jobValue: values?.jobValue || "",
      salesPerson: values?.salesPerson || "",
      salesSource: values?.salesSource || "",
      isDisplay: values?.isDisplay || false,
      orderNumber: orderNumberValue || "",
      jobNumber: jobNumberValue || "",
      status: "Created",
    };
    let newKeysObj = {
      extraCharges: extraChargesValue || "",
      shift: values?.shift || "",
      assignAssets: values?.assignAssets || "",
      contactName: values?.contactName,
    };
    if (crewName?.length > 0 && updatedAssets?.length > 0) {
      debouncedSubmitJobRequest(
        reqObj,
        values?.id,
        handleFetchData,
        newKeysObj
      );
    } else {
      toast.error("Please fill the required fields");
      setIsEditable(values?.id);
      setIsTruckEditable(values?.id);
    }
  };

  const handleReject = (values: any) => {
    deleteRequestJob(values?.id, handleFetchData, setDeleteCheck);
  };

  // Debounce the updateRequestJob API call
  const debouncedUpdateRequestJob = debounce(
    (
      id: any,
      handleFetchData: any,
      setEditable: any,
      updatedColumn: string,
      updatedValue: any
    ) => {
      updateRequestJob(
        id,
        handleFetchData,
        setEditable,
        updatedColumn,
        updatedValue
      );
    },
    500 // Debounce delay in milliseconds (adjust as needed)
  );

  const handleUpdateAssignTo = (record: any, updatedColumn: string) => {
    const allCrewNames =
      crewName &&
      crewName?.length > 0 &&
      crewName?.map((val: any) => (val?.value ? val?.value : val));
    const finalAssets =
      updatedAssets &&
      updatedAssets?.length > 0 &&
      updatedAssets?.map((val: any) => (val?.value ? val?.value : val));

    // Call the debounced function
    debouncedUpdateRequestJob(
      record?.id,
      handleFetchData,
      updatedColumn == "extraCharges"
        ? setIsExtraChargeEditable
        : updatedColumn == "jobNumber"
        ? setIsJobNumberEditable
        : updatedColumn == "orderNumber"
        ? setIsOrderNumberEditable
        : updatedColumn == "assignedTo"
        ? setIsEditable
        : updatedColumn == "assignAssets"
        ? setIsTruckEditable
        : "",
      updatedColumn,
      updatedColumn == "extraCharges"
        ? extraChargesValue
        : updatedColumn == "jobNumber"
        ? jobNumberValue
        : updatedColumn == "orderNumber"
        ? orderNumberValue
        : updatedColumn == "assignedTo"
        ? allCrewNames || []
        : updatedColumn == "assignAssets"
        ? finalAssets || []
        : ""
    );
  };

  const columns: TableColumnsType<JobRequestTableDataType> = [
    {
      title: "ID",
      dataIndex: "id",
      fixed: isMobileView ? undefined : "left",
      width: 120,
      sorter: (a: JobRequestTableDataType, b: JobRequestTableDataType) =>
        a?.id?.localeCompare(b.id),
      render: (id: string) => (
        <div className="flex justify-center">
          <p></p>
        </div>
      ),
    },
    {
      title: <span className="whitespace-nowrap">Due Date</span>,
      dataIndex: "dueDate",
      width: 200,
      sorter: (a: JobRequestTableDataType, b: JobRequestTableDataType) =>
        a?.dueDate?.localeCompare(b.endDate),
      render: (dueDate: string) => {
        return (
          <div className="flex justify-center">
            <p className="whitespace-nowrap">{formatDate(dueDate)}</p>
          </div>
        ); // Adjust the format as needed
      },
      defaultSortOrder: "descend",
    },
    {
      title: <span className="whitespace-nowrap">SSA - P.O</span>,
      dataIndex: "jobNumber",
      width: isJobNumberEditable ? 190 : 130,
      render: (values: any, record: any) => {
        return (
          <AssignRequestJobNumber
            isJobNumberEditable={isJobNumberEditable}
            record={record}
            jobNumberValue={jobNumberValue}
            setJobNumberValue={setJobNumberValue}
            setIsJobNumberEditable={setIsJobNumberEditable}
            handleUpdateAssignTo={handleUpdateAssignTo}
            values={values}
            role={role}
          />
        );
      },
    },
    {
      title: "Invoice #",
      dataIndex: "invoice",
      width: 100,
      render: (invoiceID) => <p>{invoiceID}</p>,
    },
    {
      title: "Address",
      dataIndex: "location",
      width: 110,
      sorter: (a: JobRequestTableDataType, b: JobRequestTableDataType) =>
        a?.location?.localeCompare(b.location),
      render: (values) => (
        <Tooltip title={values}>
          <span className="block overflow-hidden text-ellipsis whitespace-nowrap max-w-32">
            {values}
          </span>
        </Tooltip>
      ),
    },
    {
      title: <span className="whitespace-nowrap">Client - P.O</span>,
      dataIndex: "orderNumber",
      width: isOrderNumberEditable ? 220 : 120,
      render: (values: any, record: any) => {
        return (
          <AssignRequestOrderNumber
            isOrderNumberEditable={isOrderNumberEditable}
            record={record}
            orderNumberValue={orderNumberValue}
            setOrderNumberValue={setOrderNumberValue}
            setIsOrderNumberEditable={setIsOrderNumberEditable}
            handleUpdateAssignTo={handleUpdateAssignTo}
            values={values}
            role={role}
          />
        );
      },
    },
    ...(role == ROLE.admin
      ? [
          {
            title: <span className="whitespace-nowrap">Extra Charges</span>,
            dataIndex: "extraCharges",
            width: 220,
            render: (values: any, record: any) => {
              return (
                <AssignRequestExtraCharges
                  isExtraChargeEditable={isExtraChargeEditable}
                  record={record}
                  extraChargesValue={extraChargesValue}
                  setExtraChargesValue={setExtraChargesValue}
                  setIsExtraChargeEditable={setIsExtraChargeEditable}
                  handleUpdateAssignTo={handleUpdateAssignTo}
                  values={values}
                  role={role}
                />
              );
            },
          },
        ]
      : []),
    {
      title: <span className="whitespace-nowrap">Tipping Instructions</span>,
      dataIndex: "latestNote",
      width: 170,
      render: (values) => (
        <Tooltip title={values}>
          <span className="block overflow-hidden text-ellipsis whitespace-nowrap max-w-32">
            {values}
          </span>
        </Tooltip>
      ),
    },
    {
      title: <span className="whitespace-nowrap">Client</span>,
      dataIndex: "billingName",
      width: 130,
      sorter: (a: JobRequestTableDataType, b: JobRequestTableDataType) =>
        a?.billingName?.localeCompare(b.billingName),
      render: (values) => (
        <Tooltip title={values}>
          <span className="block overflow-hidden text-ellipsis whitespace-nowrap max-w-32">
            {values}
          </span>
        </Tooltip>
      ),
    },
    {
      title: <span className="whitespace-nowrap">Site Contact</span>,
      dataIndex: "contactName",
      width: 140,
      sorter: (a: JobRequestTableDataType, b: JobRequestTableDataType) =>
        a?.contactName?.localeCompare(b.contactName),
      render: (values) => (
        <Tooltip title={values}>
          <span className="block overflow-hidden text-ellipsis whitespace-nowrap max-w-32">
            {values}
          </span>
        </Tooltip>
      ),
    },
    {
      title: <span className="whitespace-nowrap">Crew Names</span>,
      dataIndex: "assignedTo",
      width: 310,
      render: (values, record) => {
        return (
          <AssignRequestDriver
            values={values}
            record={record}
            isEditable={isEditable}
            setIsEditable={setIsEditable}
            assignedData={assignedData}
            crewName={crewName}
            setCrewName={setCrewName}
            handleUpdateAssignTo={handleUpdateAssignTo}
            role={role}
            jobsObj={jobsObj}
          />
        );
      },
    },
    {
      title: <span className="whitespace-nowrap">Truck</span>,
      dataIndex: "assignAssets",
      width: 320,
      render: (values, record) => {
        return (
          <AssignRequestAssets
            isTruckEditable={isTruckEditable}
            record={record}
            updatedAssets={updatedAssets}
            setUpdatedAssets={setUpdatedAssets}
            setIsTruckEditable={setIsTruckEditable}
            handleUpdateAssignTo={handleUpdateAssignTo}
            values={values}
            role={role}
            transformedArray={transformedArray}
          />
        );
      },
    },
    {
      title: <span className="whitespace-nowrap">Shift</span>,
      dataIndex: "shift",
      width: 120,
      render: (values) => {
        return (
          <span className="block overflow-hidden text-ellipsis whitespace-nowrap max-w-32">
            {values}
          </span>
        );
      },
    },
    {
      title: <span className="whitespace-nowrap">Job Title</span>,
      dataIndex: "title",
      width: 150,
      sorter: (a: JobRequestTableDataType, b: JobRequestTableDataType) =>
        a?.title?.localeCompare(b.title),
      render: (values) => (
        <Tooltip title={values}>
          <span className="block overflow-hidden text-ellipsis whitespace-nowrap max-w-32">
            {values}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      fixed: isMobileView ? undefined : "right",
      width: 100,
      render: (_, record) => (
        <div>
          <div className="flex justify-center gap-2">
            {userRole != ROLE.customer && userRole != ROLE.staff && (
              <CheckCircleOutlined
                className="text-green-600 text-xl cursor-pointer"
                onClick={() =>
                  !isJobNumberEditable &&
                  !isOrderNumberEditable &&
                  !isExtraChargeEditable &&
                  !isTruckEditable &&
                  !isEditable
                    ? handleSubmitRequest(record)
                    : toast.warn("Please complete your changes first")
                }
              />
            )}

            <CloseCircleOutlined
              className="text-red-600 text-xl cursor-pointer"
              onClick={() => handleReject(record)}
            />
          </div>
        </div>
      ),
    },
  ];

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    handleResize(); // Set the initial state
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="rounded-sm border-1 border-stroke p-6">
      <div className="mb-5 flex flex-col space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between gap-5">
            <h4 className="text-xl font-semibold text-black">{tableName}</h4>
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={role != ROLE.admin ? customerJobs : tableData}
        onChange={onChange}
        showSorterTooltip={{ target: "sorter-icon" }}
        rowClassName="cursor-pointer"
        scroll={{ x: 1500, y: 540 }}
        // sticky
        className="custom-table"
      />
    </div>
  );
};
export { JobRequestTable };
