import React, { useRef, useState } from "react";
import {
  Checkbox,
  DatePicker,
  Dropdown,
  MenuProps,
  Table,
  Tooltip,
} from "antd";
import type { TableColumnsType, TableProps } from "antd";
import {
  CaretDownOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  FileSyncOutlined,
} from "@ant-design/icons";
import { Button } from "../Button";
import { CreateJobs } from "@/pages/admin/dashboard/createJobs";
import moment from "moment";
import { ROLE, STATUS, STATUS_COLOR_CODE } from "@/utils/enums";
import {
  useDeleteCreatedJobsForDashboard,
  useFetchRemainingJobColoumns,
  useFilterApply,
  useSendClientDetails,
} from "@/supabase/jobs";
import dayjs from "dayjs";
import { useAppSelector } from "@/redux/store/Store";
import { RequestJob } from "@/pages/admin/dashboard/RequestJob";
import AssignAssets from "./AssignAssets";
import { Assigned, DataType, Detail, Job, ListinTableProps } from "./interface";
import AssignTo from "./AssignTo";
import AssignShift from "./AssignShift";
import AssignExtraCharges from "./AssignExtraCharges";
import AssignContactName from "./AssignContactName";
import AssignOrderNum from "./AssignOrderNum";
import AssignAddress from "./AssignAddress";
import AssignClient from "./AssignClient";
import AssignJobTitle from "./AssignJobTitle";
import { debounce } from "lodash";
import AssignJobNumber from "./AssignJobNumber";

const formatDate = (timeStamp: string): string => {
  return moment.unix(Number(timeStamp)).format("hh:mm a,Do MMMM YY");
};

const onChange: TableProps<DataType>["onChange"] = (
  pagination,
  filters,
  sorter,
  extra
) => {};

const ListingTable: React.FC<ListinTableProps> = ({
  tableName,
  tableData,
  isButton,
  buttonText,
  onSyncClick,
}) => {
  const arr: Assigned[] = [];
  const { sendClientData } = useSendClientDetails();
  const { fetchRemainingJobColoumns } = useFetchRemainingJobColoumns();
  const [remainingJobColoums, setRemainingJobColoums] = useState<Detail[]>([]);

  const role = useAppSelector((state) => state.Authlogin?.data?.role);
  const isRowRendering = useAppSelector(
    (state) => state?.showRowAnimation?.isRowAnimation
  );
  const allStoredAssets = useAppSelector(
    (state) => state?.usersSlice?.formitizeUsers
  );
  const { deleteCreatedJobsForDashboard } = useDeleteCreatedJobsForDashboard();
  const [open, setOpen] = React.useState(false);
  const [requestOpen, setRequestOpen] = React.useState(false);
  const [isUpdateMode, setIsUpdateMode] = React.useState(false);
  const [selectedJob, setSelectedJob] = React.useState<Job | null>(null);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [startDate, setStartDate] = React.useState<string | null>(null);
  const [endDate, setEndDate] = React.useState<string | null>(null);
  const { RangePicker } = DatePicker;
  const [isOrderNumEditable, setIsOrderNumEditable] = React.useState<
    string | null
  >(null);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isEditable, setIsEditable] = React.useState<string | null>(null);
  const [isTruckEditable, setIsTruckEditable] = React.useState<string | null>(
    null
  );
  const [isJobNumberEditable, setIsJobNumberEditable] = React.useState<
    string | null
  >(null);
  const [isShiftEditable, setIsShiftEditable] = React.useState<string | null>(
    null
  );
  const [isAddressEditable, setIsAddressEditable] = React.useState<
    string | null
  >(null);
  const [isExtraChargesEditable, setSsExtraChargesEditable] = React.useState<
    string | null
  >(null);
  const [isContactNameEditable, setIsContactNameEditable] = React.useState<
    string | null
  >(null);
  const [isClientEditable, setIsClientEditable] = React.useState<string | null>(
    null
  );
  const [isJobTitleEditable, setIsJobTitleEditable] = React.useState<
    string | null
  >(null);

  const [checkedKeys, setCheckedKeys] = useState<Set<string>>(new Set());
  const rangePickerRef = useRef<any>(null);
  const [visible, setVisible] = useState(false);
  const { filterApply } = useFilterApply();

  const debouncedUpdateColumnQuickly = debounce(
    (
      obj: any,
      setOpen: any,
      onSyncClick: any,
      newKeysObj: any,
      id: any,
      columnId: any
    ) => {
      sendClientData(obj, setOpen, onSyncClick, newKeysObj, id, columnId);
    },
    500 // Adjust debounce delay as needed
  );

  const [assignedData, setAssignedData] = useState<Assigned[]>([]);

  const jobsObj = useAppSelector((state) => state?.usersSlice?.formitizeUsers);
  const handleFocusCalender = () => {
    setVisible(!visible);
  };
  const formatDateWithMoment = (inputDate: string): string => {
    return moment(inputDate).format("Do MMM YY");
  };

  const getUnixTimestampInSeconds = (dateString: string | null): number => {
    const dateTimeString = dateString;

    const date = dayjs(dateTimeString, "YYYY-MM-DD");
    return Math.floor(date.valueOf() / 1000);
  };

  const getUniqueSortedDates = (data: DataType[]) => {
    const uniqueDates: { [key: string]: number } = {};

    data.forEach((record) => {
      const recordDate = moment
        .unix(Number(record.dueDate))
        .format("YYYY-MM-DD");
      uniqueDates[recordDate] = (uniqueDates[recordDate] || 0) + 1;
    });

    // Return sorted unique dates
    return Object.keys(uniqueDates).sort((a, b) => moment(a).diff(moment(b)));
  };

  const sortedUniqueDates = getUniqueSortedDates(tableData);

  // Row class name logic
  const rowClassName = (record: DataType) => {
    const recordDate = moment.unix(Number(record.dueDate)).format("YYYY-MM-DD");
    const index = sortedUniqueDates.indexOf(recordDate);

    // Alternate row colors based on the index of the date
    return record?.id == isRowRendering
      ? index % 2 === 0
        ? "row-black fade-in"
        : "row-white fade-in"
      : index % 2 === 0
      ? "row-black"
      : "row-white";
  };

  const handleSubmit = (values: any) => {
    let obj = {
      client: {
        billingName: values?.billingName || "",
      },
      title: values?.title || "",
      location: values?.location || "",
      dueDate: values?.dueDate,
      agent: values?.assignedTo?.[0] || "",
      orderNumber: values?.orderNumber || "",
      jobNumber: values?.jobNumber || "",
      status: "",
      confirmed: values?.confirmed || "",
      siteName: values?.siteName || "",
      zone: values?.zone || "",
      jobType: values?.jobType || "",
      jobTitle: values?.jobTitle || "",
      notes: values?.description || "",
      form: values?.form,
      endDate: values?.endDate,
      priority: "",
      invoice: values?.invoice || "",
      jobValue: values?.jobValue || "",
      salesPerson: values?.salesPerson || "",
      salesSource: values?.salesSource || "",
      sendNotification: values?.isDisplay || "",
      invoiceNumber: values?.invoice || "",
    };
    let newKeysObj = {
      extraCharges: values?.extraCharges || "",
      shift: values?.shift || "",
      contactName: values?.contactName || "",
      assignAssets: values?.assignAssets || [],
    };
    debouncedUpdateColumnQuickly(
      obj,
      setOpen,
      onSyncClick,
      newKeysObj,
      values?.id,
      values?.jobColumnId
    );
  };

  const handleCheckboxChange = (key: string) => {
    setCheckedKeys((prev) => {
      const updated = new Set(prev);
      if (updated.has(key)) {
        updated.delete(key);
      } else {
        updated.add(key);
      }
      return updated;
    });
  };

  const handleSetDate = (data: any) => {
    setStartDate(data?.[0]);
    setEndDate(data?.[1]);
    setVisible(false);
  };

  const handleReject = (record: any) => {
    deleteCreatedJobsForDashboard(record?.id, onSyncClick);
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={() => handleCheckboxChange("0")}
        >
          <Checkbox checked={checkedKeys.has("0")} />
          <p className="text-black h-4 flex items-center">Creating</p>
        </div>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={() => handleCheckboxChange("1")}
        >
          <Checkbox checked={checkedKeys.has("1")} />
          <p className="text-black h-4 flex items-center">Created</p>
        </div>
      ),
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={() => handleCheckboxChange("2")}
        >
          <Checkbox checked={checkedKeys.has("2")} />
          <p className="text-black h-4 flex items-center">Assigned</p>
        </div>
      ),
      key: "2",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={() => handleCheckboxChange("3")}
        >
          <Checkbox checked={checkedKeys.has("3")} />
          <p className="text-black h-4 flex items-center">Accepted</p>
        </div>
      ),
      key: "3",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={() => handleCheckboxChange("4")}
        >
          <Checkbox checked={checkedKeys.has("4")} />
          <p className="text-black h-4 flex items-center">Finished</p>
        </div>
      ),
      key: "4",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={() => handleCheckboxChange("6")}
        >
          <Checkbox checked={checkedKeys.has("6")} />
          <p className="text-black h-4 flex items-center">Rejected</p>
        </div>
      ),
      key: "5",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={() => handleCheckboxChange("8")}
        >
          <Checkbox checked={checkedKeys.has("8")} />
          <p className="text-black h-4 flex items-center">Rescheduled</p>
        </div>
      ),
      key: "6",
    },
  ];
  const columns: TableColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      fixed: isMobileView ? undefined : "left",
      width: 120,
      sorter: (a: DataType, b: DataType) => a.id.localeCompare(b.id),
      render: (id: string, record: any) => {
        return (
          <div className="flex justify-center">
            <p>{record?.source == "supabase" ? "" : id}</p>
          </div>
        );
      },
    },
    {
      title: <span className="whitespace-nowrap">Due Date</span>,
      dataIndex: "dueDate",
      width: 200,
      sorter: (a: DataType, b: DataType) => a.dueDate.localeCompare(b.dueDate),
      render: (dueDate: string) => {
        return (
          <div className="flex justify-center">
            <p className="whitespace-nowrap">{formatDate(dueDate)}</p>
          </div>
        ); // Adjust format as needed
      },
      defaultSortOrder: "descend",
    },
    {
      title: <span className="whitespace-nowrap">SSA - P.O</span>,
      dataIndex: "jobNumber",
      width: isJobNumberEditable ? 220 : 140,
      render: (values, record) => {
        return (
          <AssignJobNumber
            values={values}
            record={record}
            isJobNumberEditable={isJobNumberEditable}
            setIsJobNumberEditable={setIsJobNumberEditable}
            onSyncClick={onSyncClick}
          />
        );
      },
    },
    {
      title: "Invoice #",
      dataIndex: "invoiceNumber",
      width: 100,
      render: (invoiceNumber) => <p>{invoiceNumber}</p>,
    },
    {
      title: "Address",
      dataIndex: "location",
      width: isAddressEditable ? 280 : 190,
      sorter: (a: DataType, b: DataType) =>
        a.location.localeCompare(b.location),
      render: (values, record) => {
        return (
          <AssignAddress
            values={values}
            record={record}
            isAddressEditable={isAddressEditable}
            setIsAddressEditable={setIsAddressEditable}
            onSyncClick={onSyncClick}
          />
        );
      },
    },
    {
      title: "Client P.O.",
      dataIndex: "orderNumber",
      width: isOrderNumEditable ? 200 : 150,
      render: (values, record) => {
        return (
          <AssignOrderNum
            values={values}
            record={record}
            isOrderNumEditable={isOrderNumEditable}
            setIsOrderNumEditable={setIsOrderNumEditable}
            onSyncClick={onSyncClick}
          />
        );
      },
    },
    ...(role === ROLE.admin
      ? [
          {
            title: <span className="whitespace-nowrap">Extra Charges</span>,
            dataIndex: "extraCharges",
            width: isExtraChargesEditable ? 200 : 130,
            render: (values: string, record: any) => {
              return (
                <AssignExtraCharges
                  values={values}
                  record={record}
                  isExtraChargesEditable={isExtraChargesEditable}
                  setIsExtraChargesEditable={setSsExtraChargesEditable}
                  onSyncClick={onSyncClick}
                />
              );
            },
          },
        ]
      : []),
    {
      title: <span className="whitespace-nowrap">Tipping Instructions</span>,
      dataIndex: "description",
      width: 170,
      render: (values) => (
        <Tooltip title={values}>
          <span className="block overflow-hidden text-ellipsis whitespace-nowrap max-w-32">
            {values}
          </span>
        </Tooltip>
      ),
    },
    {
      title: <span className="whitespace-nowrap">Client</span>,
      dataIndex: "billingName",
      width: isClientEditable ? 280 : 150,
      sorter: (a: DataType, b: DataType) =>
        a.billingName.localeCompare(b.billingName),
      render: (values, record) => {
        return (
          <AssignClient
            values={values}
            record={record}
            isClientEditable={isClientEditable}
            setIsClientEditable={setIsClientEditable}
            onSyncClick={onSyncClick}
          />
        );
      },
    },
    {
      title: <span className="whitespace-nowrap">Site Contact</span>,
      dataIndex: "contactName",
      width: isContactNameEditable ? 220 : 200,
      sorter: (a: DataType, b: DataType) =>
        a.contactName.localeCompare(b.contactName),
      render: (values, record) => {
        return (
          // <span>{values}</span>
          <AssignContactName
            values={values}
            record={record}
            isContactNameEditable={isContactNameEditable}
            setIsContactNameEditable={setIsContactNameEditable}
            onSyncClick={onSyncClick}
          />
        );
      },
    },
    {
      title: <span className="whitespace-nowrap">Crew Names</span>,
      dataIndex: "assignedTo",
      width: 300,
      render: (values, record) => {
        // Ensure assignedTo is an array, log the record for debugging
        return (
          <AssignTo
            record={record}
            assignedData={assignedData}
            setIsEditable={setIsEditable}
            isEditable={isEditable}
            role={role}
            onSyncClick={onSyncClick}
          />
        );
      },
    },
    {
      title: <span className="whitespace-nowrap">Truck</span>,
      dataIndex: "assignAssets",
      width: 320,
      render: (values, record) => {
        return (
          <AssignAssets
            isTruckEditable={isTruckEditable}
            setIsTruckEditable={setIsTruckEditable}
            record={record}
            values={values}
            onSyncClick={onSyncClick}
          />
        );
      },
    },
    {
      title: <span className="whitespace-nowrap">Shift</span>,
      dataIndex: "shift",
      width: 200,
      render: (values, record) => {
        return (
          <AssignShift
            values={values}
            record={record}
            isShiftEditable={isShiftEditable}
            setIsShiftEditable={setIsShiftEditable}
            onSyncClick={onSyncClick}
          />
        );
      },
    },
    {
      title: "Job Title",
      dataIndex: "title",
      width: isJobTitleEditable ? 220 : 180,
      sorter: (a: DataType, b: DataType) => a.title.localeCompare(b.title),
      render: (values, record) => {
        return (
          <AssignJobTitle
            values={values}
            record={record}
            isJobTitleEditable={isJobTitleEditable}
            setIsJobTitleEditable={setIsJobTitleEditable}
            onSyncClick={onSyncClick}
          />
        );
      },
    },
    ...(role != ROLE.customer && role != ROLE.staff
      ? [
          {
            title: "Action",
            dataIndex: "",
            width: 150,
            render: (value: any, record: any) => (
              <div>
                {record?.source !== "supabase" ? (
                  ""
                ) : (
                  <div className="flex justify-center gap-2">
                    <CheckCircleOutlined
                      className="text-green-600 text-xl cursor-pointer"
                      onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleSubmit(record);
                      }}
                    />

                    <CloseCircleOutlined
                      className="text-red-600 text-xl cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleReject(record);
                      }}
                    />
                  </div>
                )}
              </div>
            ),
          },
        ]
      : []),
    {
      title: "Status",
      dataIndex: "status",
      fixed: isMobileView ? undefined : "right",
      width: 120,
      render: (status: string, record: any) => {
        return (
          <div
            className={`inline-block px-2 py-1 text-xs font-semibold text-white ${STATUS_COLOR_CODE[status]} rounded-full`}
          >
            {STATUS[status]}
          </div>
        );
      },
    },
  ];

  const showDrawer = (job?: Job) => {
    if (job) {
      setSelectedJob(job);
      setIsUpdateMode(true);
    } else {
      setSelectedJob(null);
      setIsUpdateMode(false);
    }
    setOpen(true);
  };

  const openDrawer = () => {
    setRequestOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setRequestOpen(false);
  };

  const handleSync = () => {
    onSyncClick();
    if (checkedKeys || startDate) {
      handleClearAll();
    }
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch(searchQuery);
    }
  };

  const handleRemoveValue = (key: string) => {
    setCheckedKeys((prev) => {
      const updated = new Set(prev);
      updated.delete(key);
      return updated;
    });
  };
  const handleRemoveSelectedDate = () => {
    setStartDate(null);
    setEndDate(null);
  };
  const handleClearAll = () => {
    setCheckedKeys(new Set());
    setStartDate(null);
    setEndDate(null);
  };
  const handleRowClick = (record: Job) => {
    if (role != ROLE.customer && role != ROLE.staff) showDrawer(record);
  };

  const handleFilterAllData = () => {
    const statusParam = Array.from(checkedKeys).join(",");
    let params = new URLSearchParams();
    statusParam !== "4" && statusParam && params.append("status", statusParam);
    startDate &&
      params.append(
        "start_date",
        getUnixTimestampInSeconds(startDate).toString()
      );
    statusParam == "4" && params.append("finished", "true");
    endDate &&
      params.append("end_date", getUnixTimestampInSeconds(endDate).toString());
    const queryString = params.toString();
    filterApply(queryString);
  };

  React.useEffect(() => {
    if (window) {
      // Debounce the resize event to reduce the frequency of state updates
      const handleResize = debounce(() => {
        setIsMobileView(window.innerWidth < 768);
      }, 100); // Adjust the debounce delay as needed

      window.addEventListener("resize", handleResize);

      // Cleanup event listener on component unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  React.useEffect(() => {
    handleFilterAllData();
  }, [checkedKeys, startDate, endDate]);

  React.useEffect(() => {
    fetchRemainingJobColoumns(setRemainingJobColoums);
  }, []);

  React.useEffect(() => {
    jobsObj?.length > 0 &&
      jobsObj?.map((ele) => {
        let obj = {
          label: ele?.name,
          value: ele?.formitize_id,
        };
        arr.push(obj);
      });
    setAssignedData(arr);
  }, []);

  return (
    <div className="rounded-sm border-1 border-stroke p-6">
      {open && (
        <CreateJobs
          onClose={onClose}
          open={open}
          setOpen={setOpen}
          reloadDashboard={onSyncClick}
          isUpdateMode={isUpdateMode}
          jobData={selectedJob || undefined}
        />
      )}
      {requestOpen && (
        <RequestJob
          onClose={onClose}
          requestOpen={requestOpen}
          setRequestOpen={setRequestOpen}
          reloadDashboard={onSyncClick}
        />
      )}
      <div className="mb-5 flex flex-col space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between gap-5">
            <h4 className="text-xl font-semibold text-black">{tableName}</h4>
          </div>
          {isButton && (
            <div className="flex space-x-2">
              {role !== ROLE.staff && (
                <div>
                  {role == ROLE.customer ? (
                    <Button
                      text={"Job Request"}
                      customClass="bg-custom-bg text-white pt-2 pb-2 pr-4 pl-4 rounded-lg"
                      btnType="button"
                      btnLoader={false}
                      onClick={() => openDrawer()}
                    />
                  ) : (
                    <Button
                      text={buttonText}
                      customClass="bg-custom-bg text-white pt-2 pb-2 pr-4 pl-4 rounded-lg"
                      btnType="button"
                      btnLoader={false}
                      onClick={() => showDrawer()}
                    />
                  )}
                </div>
              )}

              <Button
                text="Sync"
                customClass="bg-custom-bg text-white pt-2 pb-2 pr-4 pl-4 rounded-lg"
                btnType="button"
                btnLoader={false}
                onClick={handleSync}
              />
            </div>
          )}
        </div>
        <div className="flex space-x-4">
          <div className="flex space-x-4">
            <Dropdown menu={{ items }} trigger={["click"]} placement="bottom">
              <div className="flex items-center bg-white gap-4 cursor-pointer">
                <div>
                  <span
                    className={` relative text-sm font-semibold py-1 p-2 rounded-3xl border-1 border-custom-white-transparent flex items-center justify-between gap-2`}
                  >
                    <FileSyncOutlined className=" cursor-pointer" />
                    Status
                    <CaretDownOutlined />
                  </span>
                </div>
              </div>
            </Dropdown>

            <div className="relative">
              <div className="absolute z-98 w-max top-0">
                <span
                  onClick={handleFocusCalender}
                  className={`py-1 p-2 cursor-pointer bg-white px- relative text-sm font-semibold rounded-3xl border-1 border-custom-white-transparent flex items-center justify-between gap-2`}
                >
                  <ClockCircleOutlined />
                  Created At
                  <CaretDownOutlined className=" cursor-pointer" />
                </span>
              </div>
              <RangePicker
                prefixCls="range-picker"
                // style={{ display: "none" }}
                ref={rangePickerRef as any}
                open={visible}
                onChange={(_, data) => {
                  handleSetDate(data);
                }}
                className="custom-rangepicker w-0 h-0 relative"
              />
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2 mt-4">
          {Array.from(checkedKeys).map((val) => (
            <span
              key={val} // Make sure to provide a unique key for each element
              className={`text-xs font-semibold py-2 px-4 rounded-2xl border-1 border-custom-white-transparent flex items-center justify-between gap-2`}
            >
              {val == "0"
                ? "Creating"
                : val == "1"
                ? "Created"
                : val == "2"
                ? "Assigned"
                : val == "3"
                ? "Accepted"
                : val == "6"
                ? "Rejected"
                : val == "8"
                ? "Rescheduled"
                : "Finished"}
              {/* Display the value here */}
              <CloseOutlined
                className="cursor-pointer"
                onClick={() => handleRemoveValue(val)}
              />
            </span>
          ))}
          {startDate && endDate && (
            <span
              className={`text-xs font-semibold py-2 px-4 rounded-2xl border-1 border-custom-white-transparent flex items-center justify-between gap-2`}
            >
              {`${formatDateWithMoment(startDate)} - ${formatDateWithMoment(
                endDate
              )}`}
              <CloseOutlined
                className="cursor-pointer"
                onClick={() => handleRemoveSelectedDate()}
              />
            </span>
          )}
          {(checkedKeys.size > 0 || (startDate && endDate)) && (
            <span
              className={`text-xs font-semibold py-2 px-4 rounded-2xl border-1 border-custom-white-transparent flex items-center justify-between gap-2 cursor-pointer`}
              onClick={handleClearAll}
            >
              Clear All
              <CloseOutlined />
            </span>
          )}
        </div>

        <Table
          columns={columns}
          dataSource={tableData}
          onChange={onChange}
          showSorterTooltip={{ target: "sorter-icon" }}
          rowClassName={rowClassName}
          scroll={{ x: 1500, y: 540 }}
          className="custom-table"
          onRow={(record) => ({
            onClick: () =>
              !isEditable &&
              !isJobTitleEditable &&
              !isClientEditable &&
              !isTruckEditable &&
              !isShiftEditable &&
              handleRowClick(record),
            className: rowClassName(record),
          })}
        />
      </div>
    </div>
  );
};
export { ListingTable };
