import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import SignupSlice from "../slices/SignupSlice";
import LoaderSlice from "../slices/LoaderSlice";
import UsersSlice from "../slices/UsersSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import ClientsSlice from "../slices/ClientsSlice";
import JobsSlice from "../slices/JobsSlice";
import RequestJobsSlice from "../slices/JobFormListSlice";
import StoreAssetsSlice from "../slices/StoreAssetsSlice";
import ShowRowAnimation from "../slices/ShowRowAnimation";

const reducers = combineReducers({
  Authlogin: SignupSlice,
  loader: LoaderSlice,
  usersSlice: UsersSlice,
  clientSlice: ClientsSlice,
  jobSlice: JobsSlice,
  requestJobsSlice: RequestJobsSlice,
  storedAssets: StoreAssetsSlice,
  showRowAnimation: ShowRowAnimation,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "persist/PERSIST",
          "persist/REHYDRATE",
          "persist/PAUSE",
          "persist/PURGE",
          "persist/FLUSH",
          "persist/REGISTER",
        ],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
export const persistor = persistStore(store);
