const ROLE = {
  staff: "Staff",
  customer: "Customer",
  admin: "Admin",
  // driver: "Driver",
};

const STATUS: { [key: string]: string } = {
  "0": "Creating",
  "1": "Created",
  "2": "Assigned",
  "3": "Accepted",
  "4": "Finished",
  "6": "Rejected",
  "8": "Rescheduled",
  "Created": "Created"
};

const STATUS_COLOR_CODE: { [key: string]: string } = {
  "0": "bg-blue-400",
  "1": "bg-teal-400",
  "2": "bg-yellow-400",
  "3": "bg-orange-400",
  "4": "bg-green-400",
  "6": "bg-red-500",
  "8": "bg-indigo-500",
  "Created": "bg-sky-500"
};


export { ROLE, STATUS, STATUS_COLOR_CODE };
