import { StaffTableProps } from "@/models";
import { Form, Table } from "antd";
import React from "react";

const CustomTable: React.FC<StaffTableProps> = ({
  columns,
  dataSource,
  form,
  EditableCell,
}) => {
  return (
    <div>
      <Form form={form} component={false}>
        <Table
          columns={columns}
          dataSource={dataSource}
          rowClassName="cursor-pointer"
          scroll={{ x: "auto" }}
          className="custom-table"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
        />
      </Form>
    </div>
  );
};

export { CustomTable };
