import { useUpdateColumnQuickly, useUpdateCreatedJobs } from "@/supabase/jobs";
import { debounce } from "lodash";

const useDebounceFunctions = () => {
  const { updateColumnQuickly } = useUpdateColumnQuickly();
  const { updateCreatedJobs } = useUpdateCreatedJobs();

  const debouncedUpdateColumnQuickly = debounce(
    (
      obj: any,
      onSyncClick: any,
      id: any,
      newKeysObj: any,
      setIsEditable: any
    ) => {
      updateColumnQuickly(obj, onSyncClick, id, newKeysObj, setIsEditable);
    },
    500 // Adjust debounce delay as needed
  );

  const debouncedUpdateCreatedJobs = debounce(
    (
      supabaseObj: any,
      onSyncClick: any,
      id: any,
      newKeysObj: any,
      setIsEditable: any
    ) => {
      updateCreatedJobs(
        supabaseObj,
        onSyncClick,
        id,
        newKeysObj,
        setIsEditable
      );
    },
    500 // Adjust debounce delay as needed
  );

  return {
    debouncedUpdateColumnQuickly,
    debouncedUpdateCreatedJobs,
  };
};

export { useDebounceFunctions };
