import { Button } from "@/components";
import {
  useClientListing,
  useCreateJobs,
  useDeleteCreatedJobs,
  useDeleteJobDetail,
  useUpdateCreatedJobs,
  useUpdateJobDetails,
} from "@/supabase/jobs";
import { CloseOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import { Formik, Form, FormikHelpers } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { JobDetails } from "@/pages";
import { Delete } from "@/utils/Images";
import { useAppSelector } from "@/redux/store/Store";
import {
  ApiClienData,
  CreateJobAssignedArr,
  CreateJobClient,
  CreateJobFormValues,
  CreateJobsProps,
} from "@/models";
import { ScheduleVisit, ClientDetails } from "@/pages";

const CreateJobs: React.FC<CreateJobsProps> = ({
  onClose,
  open,
  isUpdateMode,
  jobData,
  setOpen,
  reloadDashboard = () => {},
}) => {
  const arr: CreateJobAssignedArr[] = [];
  const { deleteCreatedJobs } = useDeleteCreatedJobs();
  const { createJobs } = useCreateJobs();
  const allStoredAssets = useAppSelector(
    (state) => state?.usersSlice?.formitizeUsers
  );
  const [hitOnSubmit, setHitOnSubmit] = useState(null);
  const { updateCreatedJobs } = useUpdateCreatedJobs();
  const [contactListing, setContactListing] = useState([]);
  const jobsObj = useAppSelector((state) => state?.usersSlice?.formitizeUsers);
  const { deleteJobDetails } = useDeleteJobDetail();
  const { updateJobDetails } = useUpdateJobDetails();
  const [step, setStep] = useState(0);
  const [clientsData, setClientsData] = useState<CreateJobClient[]>([]);
  const [loader, setLoader] = React.useState(false);
  const [assetsData, setAssetsData] = useState([]);
  const { fetchClientsData } = useClientListing();
  const [buttonLoader, setButtonLoader] = React.useState(false);
  const [allAssignedName, setAllAssignedName] = useState<
    CreateJobAssignedArr[]
  >([]);
  const [contactListingLoader, setContactListingLoader] = useState(false);
  const [formProgressData, setFormProgressData] = useState([]);
  const clients = useAppSelector((state) => state.clientSlice.clientList);

  const formatDate = (d: string): string => {
    const timestamp = Number(d) * 1000;
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${month}/${day}/${year}`;
  };

  const updateListing = (val: any) => {
    setContactListing(val);
  };

  const initialValues: CreateJobFormValues = {
    id: isUpdateMode ? jobData?.id || "" : "",
    billingName: isUpdateMode ? jobData?.billingName || "" : "",
    contactName: isUpdateMode ? jobData?.contactName || "" : "",
    title: isUpdateMode ? jobData?.title || "" : "",
    location: isUpdateMode ? jobData?.location || "" : "",
    dueDate: isUpdateMode
      ? jobData?.dueDate
        ? formatDate(jobData.dueDate)
        : ""
      : "",
    assignedTo: isUpdateMode ? jobData?.assignedTo || [] : [],
    status: isUpdateMode ? jobData?.status || "" : "",
    orderNumber: isUpdateMode ? jobData?.orderNumber || "" : "",
    jobNumber: isUpdateMode ? jobData?.jobNumber || "" : "",
    confirmed: isUpdateMode ? jobData?.confirmed || "" : "",
    siteName: isUpdateMode ? jobData?.siteName || "" : "",
    zone: isUpdateMode ? jobData?.zone || "" : "",
    jobType: isUpdateMode ? jobData?.jobType || "" : "",
    jobTitle: isUpdateMode ? jobData?.title || "" : "",
    description: isUpdateMode
      ? (() => {
          const notesIndex = jobData?.description?.indexOf("Notes:") || -1;
          return notesIndex !== -1
            ? jobData?.description?.slice(notesIndex + 6).trim() // Extract text after "Notes:"
            : ""; // Fallback if "Notes:" is not found
        })()
      : "",
    extraCharges: isUpdateMode ? jobData?.extraCharges || "" : "",
    crew: isUpdateMode ? jobData?.crew || "" : "",
    shift: isUpdateMode ? jobData?.shift || "" : "",
    form: isUpdateMode ? jobData?.form || "" : "",
    endDate: isUpdateMode
      ? jobData?.endDate
        ? formatDate(jobData.endDate)
        : ""
      : "",
    assignAssets: isUpdateMode ? jobData?.assignAssets || [] : [],
    priority: isUpdateMode
      ? jobData?.priority === "0"
        ? "Low"
        : jobData?.priority === "1"
        ? "Normal"
        : jobData?.priority === "2"
        ? "Medium"
        : jobData?.priority === "3"
        ? "High"
        : "Urgent" || ""
      : "",

    dueTime: isUpdateMode ? jobData?.dueTime || "" : "",
    endTime: isUpdateMode ? jobData?.endTime || "" : "",
    invoice: isUpdateMode ? jobData?.invoiceNumber || "" : "",
    jobValue: isUpdateMode ? jobData?.jobValue || "" : "",
    salesPerson: isUpdateMode ? jobData?.salesPerson || "" : "",
    salesSource: isUpdateMode ? jobData?.salesSource || "" : "",
    isDisplay: isUpdateMode ? jobData?.isDisplay || false : false,
    invoiceNumber: isUpdateMode ? jobData?.invoiceNumber || "" : "",
  };

  const convertToTimestamp = (date: string, time: string) => {
    const dateTimeString = `${date} ${time}`;
    const dateTime = new Date(dateTimeString);
    return Math.floor(dateTime.getTime() / 1000); // Convert to Unix timestamp in seconds
  };

  const handleDeleteJob = () => {
    if (!jobData?.source) {
      deleteJobDetails(jobData?.id, setButtonLoader, setOpen, reloadDashboard);
    } else {
      deleteCreatedJobs(jobData?.id, setOpen, reloadDashboard);
    }
  };

  const validationSchemas = [
    Yup.object().shape({
      billingName: Yup.string().required("Billing name is required"),
      contactName: Yup.string().required("Contact name is required"),
      location: Yup.string().required("Location is required"),
    }),
    Yup.object().shape({
      // assignedTo: Yup.array().required("Assign To is required"),
      dueDate: Yup.string().required("Due Date is required"),
      dueTime: Yup.string().required("Due Time is required"),
      endDate: Yup.date()
        .required("End Date is required")
        .test(
          "is-after-dueDate",
          "End date must be after or equal to due date",
          function (value) {
            const { dueDate } = this.parent;
            if (!value || !dueDate) return true; // Skip validation if dueDate or endDate is not provided

            // Normalize dates to midnight
            const normalizeDate = (dateStr: any) => {
              const date = new Date(dateStr);
              date.setHours(0, 0, 0, 0);
              return date;
            };

            const normalizedDueDate = normalizeDate(dueDate);
            const normalizedEndDate = normalizeDate(value);

            return normalizedEndDate >= normalizedDueDate;
          }
        ),
      endTime: Yup.string().required("End Time is required"),
    }),
    Yup.object().shape({
      jobType: Yup.mixed()
        .required("Job type is required")
        .test(
          "is-correct-type",
          "Job type must be either an object or a string",
          function (value) {
            return typeof value === "object" || typeof value === "string";
          }
        ),
    }),
    Yup.object().shape({}),
  ];

  const clientArray = () => {
    if (clients) {
      const clientObjectOptions: CreateJobClient[] = [];
      Object.values(clients).map((ele: ApiClienData) => {
        let label = "";
        if (ele?.primaryAddress) {
          label += `\nAddress : ${ele?.primaryAddress}`;
        }
        if (ele?.cachedata?.[0]?.type == "Mobile") {
          label += `\nContact : ${ele?.cachedata?.[0]?.value}`;
        }
        if (ele?.cachedata?.[0]?.type == "Email") {
          label += `\nEmail : ${ele?.cachedata?.[0]?.value}`;
        }

        let obj = {
          label,
          name: ele?.billingName,
          value: ele?.clientID,
        };
        clientObjectOptions.push(obj);
      });
      setClientsData(clientObjectOptions);
    }
  };

  const handleSubmit = async (
    values: CreateJobFormValues,
    { setSubmitting }: FormikHelpers<CreateJobFormValues>
  ) => {
    const extractIds = (data: any[]): string[] => {
      let ids: string[] = [];

      const traverse = (obj: any) => {
        if (obj.formID) ids.push(obj.formID);

        if (obj.children) {
          Object.values(obj.children).forEach((child) => traverse(child));
        }
      };

      data.forEach((item) => traverse(item));
      return ids;
    };

    const matchedAssets = allStoredAssets?.filter((user) =>
      values?.assignedTo.includes(user?.formitize_id)
    );
    const matchedNames = matchedAssets?.map((user) => user?.name);

    // Extract IDs from formProgressData
    const allIds = extractIds(formProgressData);

    const allAssets = assetsData?.map((option: any) => option.value);
    const prefillAssets = jobData?.assignAssets;
    const combinedNotes = `Client Name: ${
      values.contactName ? values.contactName : jobData.contactName
    }\n${
      (allAssets?.[0] != undefined && `Vehichle Registration: ${allAssets}`) ||
      `Vehichle Registration: ${prefillAssets}` ||
      ""
    }\nDrivers: ${matchedNames || ""}${
      values?.description ? `\nNotes: ${values.description}` : ""
    }`;
    let obj = {
      client: {
        billingName: values?.billingName || jobData?.billingName || "",
      },
      title: values?.jobTitle || jobData?.title || "",
      location: values?.location || jobData?.location || "",
      dueDate: convertToTimestamp(
        values?.dueDate || jobData?.dueDate || "",
        values?.dueTime || jobData?.dueTime || ""
      ),
      agent:
        (Array.isArray(values?.assignedTo) && values?.assignedTo?.length > 0
          ? values?.assignedTo?.[0]
          : values?.assignedTo) ||
        jobData?.assignedTo ||
        [],
      orderNumber: values?.orderNumber || jobData?.orderNumber || "",
      jobNumber: values?.jobNumber || jobData?.jobNumber || "",
      status: values?.status || jobData?.status || "",
      confirmed: values?.confirmed || jobData?.confirmed || "",
      siteName: values?.siteName || jobData?.siteName || "",
      zone: values?.zone || jobData?.zone || "",
      jobType: values?.jobType?.value || jobData?.title || "",
      jobTitle: values?.jobTitle || jobData?.jobTitle || "",
      notes: combinedNotes || "",
      form: allIds,
      endDate: convertToTimestamp(
        values?.endDate || jobData?.endDate || "",
        values?.endTime || jobData?.endTime || ""
      ),
      priority: values?.priority || jobData?.priority || "",
      invoice: values?.invoice || jobData?.invoice || "",
      jobValue: values?.jobValue || jobData?.jobValue || "",
      salesPerson: values?.salesPerson || jobData?.salesPerson || "",
      salesSource: values?.salesSource || jobData?.salesSource || "",
      sendNotification: values?.isDisplay || jobData?.isDisplay || "",
      invoiceNumber: values?.invoice || jobData?.invoice || "",
    };
    let newKeysObj = {
      extraCharges: values?.extraCharges || jobData?.extraCharges || "",
      shift: values?.shift || jobData?.shift || "",
      contactName: values?.contactName || jobData?.contactName,
      assignAssets:
        allAssets?.[0] != undefined ? allAssets : prefillAssets || [],
    };
    {
      isUpdateMode &&
        !jobData?.source &&
        updateJobDetails(
          obj,
          reloadDashboard,
          jobData?.id,
          setOpen,
          newKeysObj
        );
    }
    setSubmitting(false);
  };

  const handleCreateJob = (
    values: CreateJobFormValues,
    { setSubmitting }: FormikHelpers<CreateJobFormValues>
  ) => {
    if (step === 3) {
      const extractIds = (data: any[]): string[] => {
        let ids: string[] = [];
        const traverse = (obj: any) => {
          if (obj.formID) ids.push(obj.formID);
          if (obj.children) {
            Object.values(obj.children).forEach((child) => traverse(child));
          }
        };
        data.forEach((item) => traverse(item));
        return ids;
      };
      const allIds = extractIds(formProgressData);
      const allAssets = assetsData?.map((option: any) => option.value);
      const prefillAssets = jobData?.assignAssets;
      const matchedAssets = allStoredAssets?.filter((user) =>
        values?.assignedTo.includes(user?.formitize_id)
      );
      const matchedNames = matchedAssets?.map((user) => user?.name);
      const combinedNotes = `Client Name: ${
        values?.contactName ? values?.contactName : ""
      }\n${
        (allAssets?.[0] != undefined &&
          `Vehichle Registration: ${allAssets}`) ||
        `Vehichle Registration: ${jobData?.assignAssets}` ||
        ""
      }\nDrivers: ${matchedNames || ""}${
        values?.description ? `\nNotes: ${values.description}` : ""
      }`;

      let obj = {
        billingName: values?.billingName || jobData?.billingName || "",
        title: values?.jobTitle || jobData?.jobTitle || "",
        location: values?.location || jobData?.location || "",
        dueDate: String(
          convertToTimestamp(
            values?.dueDate
              ? String(values?.dueDate)
              : jobData?.dueDate
              ? String(jobData?.dueDate)
              : "" || "", // Ensure string is passed
            values?.dueTime || jobData?.dueTime || ""
          )
        ),
        assignedTo: values?.assignedTo || jobData?.assignedTo || [],
        status: "Created" || "",
        confirmed: values?.confirmed || jobData?.confirmed || "",
        siteName: values?.siteName || jobData?.siteName || "",
        zone: values?.zone || jobData?.zone || "",
        jobType: jobData?.jobType
          ? jobData?.jobType
          : values?.jobType?.value || "",
        jobTitle: values?.jobTitle || jobData?.jobTitle || "",
        description: combinedNotes || "",
        orderNumber: values?.orderNumber || jobData?.orderNumber || "",
        jobNumber: values?.jobNumber || jobData?.jobNumber || "",
        form: allIds,
        endDate: String(
          convertToTimestamp(
            values?.endDate
              ? String(values?.endDate)
              : jobData?.endDate
              ? String(jobData?.endDate)
              : "" || "", // Ensure string is passed
            values?.endTime || jobData?.endTime || ""
          )
        ),
        assignAssets: allAssets || "",
        priority: values?.priority || jobData?.priority || "",
        invoice: values?.invoice || jobData?.invoice || "",
        jobValue: values?.jobValue || jobData?.jobValue || "",
        salesPerson: values?.salesPerson || jobData?.salesPerson || "",
        salesSource: values?.salesSource || jobData?.salesSource || "",
        isDisplay: values?.isDisplay || jobData?.isDisplay || false,
      };
      let newKeysObj = {
        extraCharges: values?.extraCharges || jobData?.extraCharges || "",
        shift: values?.shift || jobData?.shift || "",
        contactName: values?.contactName || jobData?.contactName,
        assignAssets:
          allAssets?.[0] != undefined ? allAssets : prefillAssets || [],
      };
      {
        isUpdateMode
          ? updateCreatedJobs(
              obj,
              reloadDashboard,
              jobData?.id,
              newKeysObj,
              setOpen
            )
          : createJobs(obj, setOpen, reloadDashboard, newKeysObj);
      }
    }
    setSubmitting(false);
  };

  const handleNext = (validateForm: any, setTouched: any) => {
    validateForm().then((errors: any) => {
      if (Object.keys(errors).length === 0) {
        setStep((prev) => prev + 1);
      } else {
        setTouched(errors);
      }
    });
  };

  const handleBack = () => {
    setStep((prev) => prev - 1);
  };

  React.useEffect(() => {
    fetchClientsData(setLoader);
    clientArray();
  }, []);

  React.useEffect(() => {
    jobsObj?.length > 0 &&
      jobsObj?.map((ele) => {
        let obj = {
          label: ele?.name,
          value: ele?.formitize_id,
        };
        arr.push(obj);
      });
    setAllAssignedName(arr);
  }, []);

  React.useEffect(() => {
    if (jobData) {
      setHitOnSubmit(jobData?.source ? jobData?.source : "formitize");
    }
  }, []);

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={onClose}
      open={open}
      key="bottom"
      className="overflow-hidden"
    >
      <div>
        <div className="flex items-center justify-between">
          <h1 className="font-medium text-lg flex gap-2">
            <CloseOutlined
              className="cursor-pointer text-sm"
              onClick={onClose}
            />
            {isUpdateMode ? "Update Job" : "Create Job"}
          </h1>
          {isUpdateMode && (
            <img
              src={Delete}
              alt=""
              onClick={handleDeleteJob}
              className="w-5 h-5 cursor-pointer"
            />
          )}
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemas[step]}
          onSubmit={
            hitOnSubmit != "supabase" && hitOnSubmit != null
              ? handleSubmit
              : handleCreateJob
          }
        >
          {({
            isSubmitting,
            validateForm,
            errors,
            touched,
            values,
            setTouched,
          }) => (
            <Form>
              <div className="min-h-screen-minus-150">
                {step === 0 && (
                  <ClientDetails
                    errors={errors}
                    touched={touched}
                    clientsData={clientsData}
                    loader={loader}
                    contactListing={contactListing}
                    updateListing={updateListing}
                    initialValues={values}
                    setContactListingLoader={setContactListingLoader}
                    contactListingLoader={contactListingLoader}
                    jobData={jobData}
                    reloadDashboard={reloadDashboard}
                  />
                )}
                {step === 1 && (
                  <ScheduleVisit
                    errors={errors}
                    touched={touched}
                    allAssignedName={allAssignedName}
                    jobData={jobData}
                    isUpdateMode={isUpdateMode}
                    setAssetsData={setAssetsData}
                    reloadDashboard={reloadDashboard}
                  />
                )}
                {(step === 2 || step === 3) && (
                  <JobDetails
                    errors={errors}
                    touched={touched}
                    setFormProgressData={setFormProgressData}
                    jobData={jobData}
                    prefillValues={values}
                    assetsData={assetsData}
                    latestNotesDriverValue={values}
                  />
                )}
              </div>
              <div className="flex items-center justify-between gap-5 mt-4">
                {step > 0 && (
                  <Button
                    text="Back"
                    btnType="button"
                    btnLoader={false}
                    customClass="w-full py-4 font-bold shadow-sm rounded-lg bg-gray-500 text-white"
                    onClick={handleBack}
                  />
                )}
                <Button
                  text={
                    step === 2 ? (isUpdateMode ? "Update" : "Create") : "Next"
                  }
                  btnType={step === 3 ? "submit" : "button"}
                  onClick={() => handleNext(validateForm, setTouched)}
                  btnLoader={isSubmitting}
                  customClass="w-full py-4 font-bold shadow-sm rounded-lg bg-blue-500 text-white"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Drawer>
  );
};

export { CreateJobs };
