import { Customers } from "@/pages/admin/customers";
import Dashboard from "@/pages/admin/dashboard";
import { MyProfile } from "@/pages/admin/myProfile";
import { StaffPageDetails } from "@/pages/admin/staff";
import { JobRequest, SharedDocuments } from "@/pages/common";

const PrivateAdminPath = [
  {
    path: "jobs",
    component: <Dashboard />,
  },
  {
    path: "profile",
    component: <MyProfile />,
  },
  {
    path: "customers",
    component: <Customers />,
  },
  {
    path: "staff",
    component: <StaffPageDetails />,
  },
  {
    path: "job-request",
    component: <JobRequest />,
  },
  {
    path: "shared-documents",
    component: <SharedDocuments />,
  },
];

const PrivateCustomerPath = [
  {
    path: "jobs",
    component: <Dashboard />,
  },
  {
    path: "profile",
    component: <MyProfile />,
  },
  {
    path: "job-request",
    component: <JobRequest />,
  },
  {
    path: "shared-documents",
    component: <SharedDocuments />,
  },
];

const PrivateStaffPath = [
  {
    path: "jobs",
    component: <Dashboard />,
  },
  {
    path: "profile",
    component: <MyProfile />,
  },
  {
    path: "shared-documents",
    component: <SharedDocuments />,
  },
];

export { PrivateAdminPath, PrivateCustomerPath, PrivateStaffPath };
