import { LoaderState } from "@/models";
import { createSlice } from "@reduxjs/toolkit";



const initialState: LoaderState = {
    isLoading: false,
};

const loaderSlice = createSlice({
    name: "loader",
    initialState,
    reducers: {
        showLoader: (state) => {
            state.isLoading = true;
        },
        hideLoader: (state) => {
            state.isLoading = false;
        },
    },
});

export const { showLoader, hideLoader } = loaderSlice.actions;
export default loaderSlice.reducer;
