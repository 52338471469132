import { InputProps } from "@/models";
import { Field } from "formik";
import React from "react";

const Input: React.FC<InputProps> = ({
  inputType,
  inputPlaceholder,
  inputName,
  inputClass,
  disabled,
}) => {
  return (
    <div>
      <Field
        className={inputClass}
        type={inputType}
        placeholder={inputPlaceholder}
        name={inputName}
        disabled={disabled}
      />
    </div>
  );
};

export { Input };
