import React from "react";
import {LoadingOutlined} from "@ant-design/icons";
import {Spin} from "antd";

const Loader: React.FC = () => {
  const customIcon = (
      <LoadingOutlined className="text-white" style={{ fontSize: 24 }} spin />
  );

  return (
      <div className="flex justify-center items-center">
        <Spin indicator={customIcon} />
      </div>
  );
};

export { Loader };
