import React, { memo, useMemo, useState } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { Select, Button } from "antd";
import { JobDetail } from "@/utils/Images";
import { AddJobTitle } from "@/pages";
import { usefetchJobTitleListing, useFetchListForms } from "@/supabase/jobs";
import { useAppSelector } from "@/redux/store/Store";
import { ROLE } from "@/utils/enums";
import {
  JobDetailJobTitle,
  JobDetailProps,
  JObDetailSelectFieldProps,
} from "@/models";

const { Option } = Select;

const SelectField: React.FC<JObDetailSelectFieldProps> = ({
  field,
  form,
  value,
  setAddJobTitleData,
  setFieldsValue,
  options = [],
  setSelectedJobType,
  ...props
}) => {
  const [visible, setVisible] = useState(false);
  const allFormList = useAppSelector(
    (state) => state?.requestJobsSlice?.jobFormList
  );

  const handleVisibleModal = () => {
    setVisible(true);
  };
  const handleCancelModal = () => {
    setVisible(false);
  };
  React.useEffect(() => {
    if (value) {
      setFieldsValue("jobType", value);
      setFieldsValue("jobTitle", value?.value);
    }
  }, [value]);
  return (
    <div>
      {visible && (
        <AddJobTitle
          visible={visible}
          handleCancelModal={handleCancelModal}
          setAddJobTitleData={setAddJobTitleData}
          allFormList={allFormList}
        />
      )}
      <Select
        {...field}
        {...props}
        value={field.value || value || undefined}
        onChange={(value, obj) => {
          form.setFieldValue("jobType", obj);
          setSelectedJobType(null);
        }}
        onBlur={() => form.setFieldTouched(field.name, true)}
        showSearch
        placeholder="Select an option"
        optionFilterProp="label"
        filterOption={(input, option) =>
          option?.props.children.toLowerCase().includes(input.toLowerCase())
        }
        className="w-full mt-0.5 custom-select rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
        dropdownRender={(menu) => (
          <>
            {menu}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 8,
              }}
            >
              <Button
                type="dashed"
                onClick={() => handleVisibleModal()}
                style={{ marginBottom: 8 }}
              >
                Add More
              </Button>
            </div>
          </>
        )}
      >
        {options?.length > 0 &&
          options?.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
      </Select>
    </div>
  );
};

const JobDetails: React.FC<JobDetailProps> = ({
  setFormProgressData,
  jobData,
  prefillValues,
}) => {
  const allStoredAssets = useAppSelector(
    (state) => state?.usersSlice?.formitizeUsers
  );
  const assignedNames = Array.isArray(prefillValues?.assignedTo)
    ? prefillValues?.assignedTo.map((val: any) => val?.value)
    : [];

  const matchedAssets = allStoredAssets?.filter((user) =>
    assignedNames?.[0] != undefined
      ? assignedNames?.includes(user?.formitize_id)
      : prefillValues?.assignedTo?.includes(user?.formitize_id)
  );
  const matchedNames = matchedAssets?.map((user: any) => user?.name);
  const prefilledAssets =
    prefillValues &&
    prefillValues?.assignAssets
      ?.map((val: any) => val)
      .filter(Boolean)
      .join(", ");
  const { values, setFieldValue } = useFormikContext<any>();
  const { fetchJobTitleListing } = usefetchJobTitleListing();
  const [jobTitleListing, setJobTitleListing] = useState<JobDetailJobTitle[]>(
    []
  );
  const [addJobTitleData, setAddJobTitleData] = useState([]);
  const [selectedJobType, setSelectedJobType] =
    useState<JobDetailJobTitle | null>(null);
  const userRole = useAppSelector((state) => state.Authlogin.data?.role);
  const { fetchListForms } = useFetchListForms();
  const allFormList = useAppSelector(
    (state) => state?.requestJobsSlice?.jobFormList
  );
  const getClientOption = () => {
    jobTitleListing?.length > 0 &&
      jobTitleListing?.map((ele) => {
        if (ele?.value == jobData?.title) {
          setSelectedJobType(ele);
        }
      });
  };

  React.useEffect(() => {
    if (values?.jobType) {
      if (!selectedJobType) setFieldValue("jobTitle", values.jobType?.value);
    }
  }, [values.jobType, setFieldValue]);

  const matchedForms = useMemo(() => {
    // Find the selected job type based on the value
    if (jobTitleListing?.length > 0) {
      const selectedJobType = jobTitleListing?.find(
        (val: any) => val.value === values.jobType?.value
      );
      // Initialize an array for matched forms
      const arr: any[] = [];
      if (selectedJobType) {
        // Check if formId is an array and proceed
        if (Array.isArray(selectedJobType?.formId)) {
          if (Array.isArray(allFormList)) {
            allFormList?.forEach((form: any) => {
              // Check if the formID exists in the selectedJobType's formId array
              if (selectedJobType.formId.includes(form.formID)) {
                arr.push(form);
              }
            });
          }
        }
      }

      setFormProgressData(arr);
      return arr;
    } else {
      return [];
    }
  }, [values?.jobType, jobTitleListing]);

  React.useEffect(() => {
    fetchJobTitleListing(setJobTitleListing);
  }, [addJobTitleData]);
  React.useEffect(() => {
    fetchListForms();
  }, []);

  React.useEffect(() => {
    getClientOption();
  }, [jobTitleListing]);

  const jobDataForms = useMemo(() => {
    return jobData?.forms ? Object.values(jobData.forms) : [];
  }, [jobData]);

  return (
    <div className="min-h-screen-minus-150">
      <h1 className="text-base font-bold mt-3 flex items-center gap-2">
        <img src={JobDetail} alt="" className="w-6 h-6" />
        Job Details
      </h1>

      <div className="mt-5">
        <label className="font-medium">Job Type</label>
        <Field
          name="jobType"
          type="text"
          value={selectedJobType}
          placeholder="Select a Job Type"
          component={SelectField}
          setFieldsValue={setFieldValue}
          setAddJobTitleData={setAddJobTitleData}
          options={jobTitleListing}
          setSelectedJobType={setSelectedJobType}
          className="w-full mt-0.5 px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
        />
        <ErrorMessage
          name="jobType"
          component="div"
          className="text-red-500 text-sm"
        />
      </div>

      <div className="mt-5">
        <label className="font-medium">Job Title</label>
        <div className="w-full mt-0.5 px-6 py-4 rounded-lg bg-gray-100 border border-gray-200 flex items-center justify-between">
          <Field
            name="jobTitle"
            type="text"
            placeholder="Job Title"
            disabled
            className="placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400"
          />
          <img src={JobDetail} alt="" className="w-6 h-6" />
        </div>
      </div>
      {userRole != ROLE.customer && (
        <div className="mt-5">
          <label className="font-medium">Order Number</label>
          <Field
            name="orderNumber"
            type="text"
            placeholder="Enter an order number"
            className="w-full mt-0.5 px-8 py-4 rounded-lg bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
          />
        </div>
      )}

      {(matchedForms?.length > 0 || jobDataForms?.length > 0) && (
        <div className="mt-5">
          <label className="font-medium">Form Progress</label>
          {matchedForms?.length > 0
            ? matchedForms?.map((val) => {
                return (
                  <Field
                    name="form"
                    value={val?.title}
                    type="text"
                    disabled
                    className="w-full mt-2 px-8 py-4 rounded-lg bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                  />
                );
              })
            : jobDataForms?.map((val) => {
                return (
                  <Field
                    name="form"
                    value={val?.title}
                    type="text"
                    className="w-full mt-2 px-8 py-4 rounded-lg bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                  />
                );
              })}
        </div>
      )}
      <div className="mt-5">
        <label className="font-medium">Latest Notes</label>
        <div className="w-full mt-0.5 px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 text-sm">
          {userRole != ROLE.customer && userRole != ROLE.staff && (
            <p className="">Vehicle Registration: {prefilledAssets}</p>
          )}
          {userRole != ROLE.customer && userRole != ROLE.staff && (
            <p>Client Contact: {prefillValues?.contactName}</p>
          )}

          {userRole != ROLE.customer && userRole != ROLE.staff && (
            <p>Drivers: {matchedNames.join(", ")}</p>
          )}
          <Field
            name="description"
            as="textarea"
            placeholder="Enter notes"
            className="w-full placeholder-gray-500 focus:outline-none bg-gray-100 focus:border-gray-400"
          />
        </div>

        <ErrorMessage
          name="description"
          component="div"
          className="text-red-500 text-sm"
        />
      </div>
      {userRole != ROLE.customer && userRole != ROLE.staff && (
        <div className="mt-5">
          <label className="font-medium">Extra Charges</label>
          <div className="relative">
            <Field
              name="extraCharges"
              type="text"
              placeholder="Enter charges"
              className="w-full mt-0.5 pl-8 pr-4 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export { JobDetails };
