import { useDispatch } from "react-redux";
import supabase from "..";
import { hideLoader, showLoader } from "@/redux/slices/LoaderSlice";
import { useAppDispatch, useAppSelector } from "@/redux/store/Store";
import { storeClientData } from "@/redux/slices/ClientsSlice";
import { toast } from "react-toastify";
import { storeJobs } from "@/redux/slices/JobsSlice";
import { useNavigate } from "react-router-dom";
import { storeRequestJobs } from "@/redux/slices/JobFormListSlice";
import { showRowAnimation } from "@/redux/slices/ShowRowAnimation";
import { storeUserData } from "@/redux/slices/SignupSlice";
import { jobRequest, PayloadItem } from "@/models";



const useJobListing = () => {
    const dispatch = useDispatch();

    const fetchJobData = async () => {
        dispatch(showLoader());
        const { data, error } = await supabase.functions.invoke("api-jobs", {
            method: "GET",
        });
        if (data?.payload) {
            dispatch(hideLoader());
            const arrayOfObjects = Object.values(data.payload);
            dispatch(storeJobs(arrayOfObjects));
            setTimeout(() => {
                dispatch(showRowAnimation(false))
            }, 2000)
        } else {
            dispatch(hideLoader());
        }
    };

    return {
        fetchJobData,
    };
};

const useClientListing = () => {
    const dispatch = useAppDispatch();

    const fetchClientsData = async (
        setLoader?: (loading: boolean) => void
    ) => {
        const mapClientIdToClient: any = {};
        setLoader && setLoader(true);
        const { data, error } = await supabase.functions.invoke("api-clients", {
            method: "GET",
        });
        if (data) {
            setLoader && setLoader(false);
            data?.payload?.map((ele: any) => {
                mapClientIdToClient[ele.id] = ele;
            });

            dispatch(storeClientData(mapClientIdToClient));

        } else {
            setLoader && setLoader(false);
        }
    };
    return {
        fetchClientsData,
    };
};

//  create
const useSendClientDetails = () => {
    const dispatch = useDispatch();
    const sendClientData = async (
        obj: any,
        setOpen: (oen: boolean) => void,
        reloadDashboard: () => void,
        newKeysObj: any,
        id: string,
        columnId: string
    ) => {
        dispatch(showLoader());
        const { data: createData, error } = await supabase.functions.invoke("api-jobs", {
            method: "POST",
            body: JSON.stringify(obj),
        });
        dispatch(showRowAnimation(createData?.payload?.jobID))

        if (createData) {
            toast.success(`Job has been created successfully! Job Id: ${createData?.payload?.jobID}`);;
            const { data, error } = await supabase
                .from('jobs')
                .insert([
                    { crew: "", truck: newKeysObj?.assignAssets, extraCharges: newKeysObj?.extraCharges, shift: newKeysObj?.shift, compared_id: createData?.payload?.jobID, contactName: newKeysObj?.contactName },
                ])
                .select()

            if (data) {
                const { error } = await supabase
                    .from('queued_jobs')
                    .delete()
                    .eq('id', id)
                if (!error) {
                    setOpen(false);
                    dispatch(hideLoader());
                    reloadDashboard();

                    const { error } = await supabase
                        .from('jobs')
                        .delete()
                        .eq('id', columnId)

                }
            } else {
                setOpen(false);
                dispatch(hideLoader());
                reloadDashboard();
                toast.error(error.message)
            }

        } else {
            dispatch(hideLoader());
            setOpen(false);
            reloadDashboard();
            toast.error(error.message)
        }
    };
    return {
        sendClientData,
    };
};

const usePostClientDetails = () => {
    const dispatch = useDispatch();
    const postClientData = async (
        obj: any,
        setOpen: (oen: boolean) => void,
        reloadDashboard: () => void,
        newKeysObj: any
    ) => {
        dispatch(showLoader());
        const { data: createData, error } = await supabase.functions.invoke("api-jobs", {
            method: "POST",
            body: JSON.stringify(obj),
        });
        dispatch(showRowAnimation(createData?.payload?.jobID))

        if (createData) {
            toast.success("Job has been created successfully!");
            const { data, error } = await supabase
                .from('jobs')
                .insert([
                    { crew: "", truck: newKeysObj?.assignAssets, extraCharges: newKeysObj?.extraCharges, shift: newKeysObj?.shift, compared_id: createData?.payload?.jobID, contactName: newKeysObj?.contactName },
                ])
                .select()

            if (data) {
                setOpen(false);
                dispatch(hideLoader());
                reloadDashboard();
            } else {
                setOpen(false);
                dispatch(hideLoader());
                reloadDashboard();
                toast.error(error.message)
            }

        } else {
            dispatch(hideLoader());
            setOpen(false);
            reloadDashboard();
            toast.error(error.message)
        }
    };
    return {
        postClientData,
    };
};


const useFetchRemainingJobColoumns = () => {
    const fetchRemainingJobColoumns = async (setRemainingJobColoums: any) => {
        let { data: jobs, error } = await supabase
            .from('jobs')
            .select('*')

        if (jobs) {
            setRemainingJobColoums(jobs)
        } else {
            toast.error(error?.message)
        }
    }
    return {
        fetchRemainingJobColoumns
    }
}

const useDeleteJobDetail = () => {
    const deleteJobDetails = async (
        id: string,
        setButtonLoader: (loading: boolean) => void,
        setOpen: (oen: boolean) => void,
        reloadDashboard: () => void
    ) => {
        setButtonLoader(true);
        const url = `api-jobs/${id}`;
        const { data, error } = await supabase.functions.invoke(url, {
            method: "DELETE", // Use DELETE method for deletion
        });
        if (error) {
            setButtonLoader(false);
        } else {
            const { error } = await supabase
                .from('jobs')
                .delete()
                .eq('compared_id', id)

            if (error) {
                setOpen(false);
                reloadDashboard();
                setButtonLoader(false);
                toast.error(error.message)
            } else {
                setOpen(false);
                reloadDashboard();
                setButtonLoader(false);
            }
        }
    };

    return {
        deleteJobDetails,
    };
};

const useUpdateJobDetails = () => {
    const dispatch = useDispatch();
    const updateJobDetails = async (
        obj: any,
        reloadDashboard: () => void,
        id: string,
        setOpen: any,
        newKeysObj: any
    ) => {
        dispatch(showLoader());
        const url = `api-jobs/${id}`;
        const { data: updatedData, error } = await supabase.functions.invoke(url, {
            method: "POST",
            body: JSON.stringify(obj),
        });
        if (updatedData) {
            const { data, error } = await supabase
                .from('jobs')
                .update({ crew: "", truck: newKeysObj?.assignAssets, extraCharges: newKeysObj?.extraCharges, shift: newKeysObj?.shift, compared_id: updatedData?.payload?.jobID, contactName: newKeysObj?.contactName })
                .eq('compared_id', id)
                .select()

            if (data) {
                toast.success("Updated Successfuly");
                if (data?.length == 0) {
                    const { data: insertData, error: insertError } = await supabase
                        .from('jobs')
                        .insert([{
                            crew: "",
                            truck: newKeysObj?.assignAssets,
                            extraCharges: newKeysObj?.extraCharges,
                            shift: newKeysObj?.shift,
                            compared_id: updatedData?.payload?.jobID,
                            contactName: newKeysObj?.contactName
                        }])
                        .select();
                }
                dispatch(hideLoader());
                setOpen(false)
                reloadDashboard();
            } else {
                dispatch(hideLoader());
                toast.error(error.message);
                setOpen(false);
                reloadDashboard();
            }

        } else {
            setOpen(false);
            reloadDashboard();
            dispatch(hideLoader());
        }
    };
    return {
        updateJobDetails,
    };
};


const useUpdateColumnQuickly = () => {
    const dispatch = useDispatch();
    const updateColumnQuickly = async (
        obj: any,
        reloadDashboard: () => void,
        id: string,
        newKeysObj: any,
        setIsContactNameEditable: any
    ) => {
        dispatch(showLoader());
        const url = `api-jobs/${id}`;
        const { data: updatedData, error } = await supabase.functions.invoke(url, {
            method: "POST",
            body: JSON.stringify(obj),
        });
        if (updatedData) {
            const { data, error } = await supabase
                .from('jobs')
                .update({ crew: "", truck: newKeysObj?.assignAssets, extraCharges: newKeysObj?.extraCharges, shift: newKeysObj?.shift, compared_id: updatedData?.payload?.jobID, contactName: newKeysObj?.contactName })
                .eq('compared_id', id)
                .select()

            if (data) {
                if (data?.length == 0) {
                    if (data?.length == 0) {
                        const { data: insertData, error: insertError } = await supabase
                            .from('jobs')
                            .insert([{
                                crew: "",
                                truck: newKeysObj?.assignAssets,
                                extraCharges: newKeysObj?.extraCharges,
                                shift: newKeysObj?.shift,
                                compared_id: updatedData?.payload?.jobID,
                                contactName: newKeysObj?.contactName
                            }])
                            .select();
                    }
                }
                toast.success("Updated Successfuly");
                dispatch(hideLoader());
                reloadDashboard();
                setIsContactNameEditable(null)
            } else {
                dispatch(hideLoader());
                toast.error(error.message);
                setIsContactNameEditable(null)
                reloadDashboard();
            }

        } else {
            reloadDashboard();
            dispatch(hideLoader());
        }
    };
    return {
        updateColumnQuickly,
    };
};

const useUpdateRemainingJobColumn = () => {
    const dispatch = useDispatch();
    const updateRemainingJobColumn = async (obj: any, onSyncClick: () => void, setIsTruckEditable: any) => {
        dispatch(showLoader());

        // Check if the ID exists in the jobs table
        const { data: existingData, error: checkError } = await supabase
            .from('jobs')
            .select('compared_id')
            .eq('compared_id', obj?.id)
            .single(); // Ensure you get a single object

        if (existingData) { // Check if existingData exists (as an object)
            const { data, error } = await supabase
                .from('jobs')
                .update({
                    crew: "",
                    truck: obj?.truck,
                    extraCharges: obj?.extraCharges,
                    shift: obj?.shift,
                    compared_id: obj?.id
                })
                .eq('compared_id', obj?.id)
                .select();

            if (data) {
                setIsTruckEditable(null);
                dispatch(hideLoader());
                onSyncClick();
                toast.success("Updated Successfully");
            } else {
                setIsTruckEditable(null);
                dispatch(hideLoader());
                toast.error(error.message);
            }
        } else {
            // No existing record, so insert a new one
            const { data: insertData, error: insertError } = await supabase
                .from('jobs')
                .insert([{
                    crew: "",
                    truck: obj?.truck,
                    extraCharges: obj?.extraCharges,
                    shift: obj?.shift,
                    compared_id: obj?.id
                }])
                .select();

            if (insertError) {
                toast.error(insertError.message);
                setIsTruckEditable(null);
            } else {
                onSyncClick();
                setIsTruckEditable(null);
                toast.success("Created Successfully");
            }
        }
    };

    return {
        updateRemainingJobColumn
    };
};


const useUpdateAssignedJobDetails = () => {
    const dispatch = useDispatch();
    const updateAsignedJobDetails = async (
        obj: any,
        reloadDashboard: () => void,
        id: string,
        setIsEditable: any,
        setOpen?: any
    ) => {
        dispatch(showLoader());
        const url = `api-jobs/${id}`;
        const { data, error } = await supabase.functions.invoke(url, {
            method: "POST",
            body: JSON.stringify(obj),
        });
        if (data) {
            setIsEditable(null)
            reloadDashboard();
            toast.success("Updated Successfuly");
        } else {
            setOpen && setOpen(false);
            setIsEditable(null)
            reloadDashboard();
        }
    };
    return {
        updateAsignedJobDetails,
    };
};

const useFilterApply = () => {
    const dispatch = useDispatch();
    const filterApply = async (queryString: string) => {
        dispatch(showLoader());
        // setButtonLoader(true)
        const url = `api-jobs?${queryString}`;
        const { data, error } = await supabase.functions.invoke(url, {
            method: "GET",
        });
        if (data) {
            dispatch(hideLoader());
            const arrayOfObjects = Object.values(data.payload);
            dispatch(storeJobs(arrayOfObjects));
            // setButtonLoader(false)
        } else {
            dispatch(hideLoader());
        }
    };
    return {
        filterApply,
    };
};

const useRequestJob = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const requestJob = async (
        obj: jobRequest,
        setRequestOpen: (open: boolean) => void,
    ) => {
        dispatch(showLoader());
        const { data, error } = await supabase
            .from("request_jobs")
            .insert([
                {
                    invoice: obj?.invoice,
                    dueDate: obj?.dueDate,
                    confirmed: obj?.confirmed,
                    title: obj?.title,
                    billingName: obj?.billingName,
                    contactName: obj?.contactName,
                    location: obj?.location,
                    assignedTo: obj?.assignedTo,
                    siteName: obj?.siteName,
                    zone: obj?.zone,
                    jobTitle: obj?.jobTitle,
                    jobType: obj?.jobType,
                    latestNote: obj?.description,
                    endDate: obj?.endDate,
                    assignAssets: obj?.assignAssets,
                    priority: obj?.priority,
                    jobValue: obj?.jobValue,
                    isDisplay: obj?.isDisplay,
                    salesPerson: obj?.salesPerson,
                    salesSource: obj?.salesSource,
                    form: obj?.form,
                    shift: obj?.shift,
                    extraCharges: obj?.extraCharges,
                    orderNumber: obj?.orderNumber
                },
            ])
            .select();
        if (data) {
            dispatch(hideLoader());
            dispatch(storeRequestJobs(true));
            setRequestOpen(false);
            navigate("/job-request")
            toast.success("Succesfully requested")
        } else {
            dispatch(hideLoader());
            setRequestOpen(false);
        }
    };
    return {
        requestJob,
    };
};

const usefetchRequestJob = () => {
    const fetchRequestJobs = async (setRequestJobListing: any,
        handleShowLoader: () => void,
        handleHideLoader: () => void) => {
        handleShowLoader();
        const { data: request_jobs, error } = await supabase
            .from("request_jobs")
            .select("*");
        if (request_jobs) {
            setRequestJobListing(request_jobs);
            handleHideLoader()
        } else {

            handleHideLoader()
        }
    };
    return {
        fetchRequestJobs,
    };
};

const useSubmitJobRequest = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const submitJobRequest = async (obj: any, id: string, handleFetchData: () => void, newKeysObj: any) => {
        const { data: createdData, error } = await supabase
            .from('queued_jobs')
            .insert([
                {
                    invoice: obj?.invoice,
                    dueDate: obj?.dueDate,
                    confirmed: obj?.confirmed,
                    title: obj?.title,
                    billingName: obj?.billingName,
                    contactName: obj?.contactName,
                    location: obj?.location,
                    assignedTo: obj?.assignedTo,
                    siteName: obj?.siteName,
                    zone: obj?.zone,
                    jobTitle: obj?.jobTitle,
                    jobType: obj?.jobType,
                    description: obj?.description,
                    endDate: obj?.endDate,
                    assignAssets: obj?.assignAssets,
                    priority: obj?.priority,
                    jobValue: obj?.jobValue,
                    isDisplay: obj?.isDisplay,
                    salesPerson: obj?.salesPerson,
                    salesSource: obj?.salesSource,
                    form: obj?.form,
                    shift: obj?.shift,
                    extraCharges: obj?.extraCharges,
                    status: obj?.status,
                    orderNumber: obj?.orderNumber,
                    jobNumber: obj?.jobNumber
                },
            ])
            .select()
        if (createdData) {
            toast.success("Job has been created successfully!");
            const { data, error } = await supabase
                .from('jobs')
                .insert([
                    { crew: "", truck: newKeysObj?.assignAssets, extraCharges: newKeysObj?.extraCharges, shift: newKeysObj?.shift, compared_id: createdData?.[0]?.id, contactName: newKeysObj?.contactName },
                ])
                .select()

            if (data) {
                // dispatch(hideLoader());
                const { error } = await supabase
                    .from('request_jobs')
                    .delete()
                    .eq('id', id)
                if (error) {
                    toast.error(error.message)
                } else {
                    navigate("/jobs")
                    handleFetchData();
                }

            } else {
                // dispatch(hideLoader());
                handleFetchData();
                toast.error(error.message)
            }

        } else {
            toast.error(error.message);
        }
    };
    return {
        submitJobRequest,
    };
};
const useDeleteRequestJob = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const deleteRequestJob = async (id: string, handleFetchData: () => void, setDeleteCheck: any) => {
        dispatch(showLoader());

        const { data, error } = await supabase
            .from("request_jobs")
            .delete()
            .eq("id", id);

        handleFetchData();
        dispatch(hideLoader());
        if (setDeleteCheck) {
            setDeleteCheck(true);
        }
        toast.success("Cancelled successfuly")
    };
    return {
        deleteRequestJob,
    };
};

const useFetchContactListing = () => {
    const fetchContactListing = async (
        clientId: string,
        updateListing: any,
        contactsArr: any,
        setContactListingLoader: (loading: boolean) => void
    ) => {
        setContactListingLoader(true)
        const url = `api-contact/${clientId}`;
        const { data, error } = await supabase.functions.invoke(url, {
            method: "GET",
        });
        if (error) {
            setContactListingLoader(false)
        } else {
            data?.payload?.length > 0 &&
                data?.payload?.map((ele: PayloadItem) => {
                    let obj = {
                        label: ele?.firstName,
                        value: ele?.firstName,
                    };
                    contactsArr.push(obj);
                });
            updateListing(contactsArr)
            setContactListingLoader(false)
        }
    };

    return {
        fetchContactListing,
    };
};

const useAddJobTitle = () => {
    const addJobTitle = async (values: any, handleCancelModal: () => void, setLoader: (loading: boolean) => void, setAddJobTitleData: any, formValues: any) => {
        setLoader(true)
        const { data, error } = await supabase
            .from('job_title')
            .insert([
                { job_title: values?.job_title, job_type: values?.job_type, forms: formValues },
            ])
            .select()
        if (data) {
            setLoader(false)
            setAddJobTitleData(data)
            handleCancelModal();
            toast.success("Job Title added successfuly")
        } else {
            setLoader(false)
            handleCancelModal()
        }
    }
    return {
        addJobTitle
    }
}

const usefetchJobTitleListing = () => {
    const fetchJobTitleListing = async (setJobTitleListing: any) => {
        let { data: job_title, error } = await supabase
            .from('job_title')
            .select('*')
        if (job_title) {
            if (job_title.length > 0) {
                const jobDataFormatted = job_title?.map((ele: any) => ({
                    label: ele.job_title,
                    value: ele.job_type, // Dynamically handle job_type array
                    formId: ele.forms.length > 0 ? ele.forms : null
                }));
                setJobTitleListing(jobDataFormatted);
            }
        } else {
            toast.error(error?.message);
        }
    }
    return {
        fetchJobTitleListing
    }
}

const useFetchAssignAssetsIds = () => {
    const fetchAssignAssetsIds = async (setAssignAssetsIds: any, setAssetsLoader: (loading: boolean) => void) => {
        setAssetsLoader(true)
        const url = `api-assets`;
        const { data, error } = await supabase.functions.invoke(url, {
            method: "GET",
        });
        if (error) {
        } else {
            const ids = data?.payload?.map((item: { id: number }) => item.id);
            setAssignAssetsIds(ids);
        }
    };

    return {
        fetchAssignAssetsIds,
    };
};
const useFetchAllAssignAssets = () => {
    const fetchAllAssignAssets = async (queryString: string) => {
        const url = `api-assets/?${queryString}`;
        const { data, error } = await supabase.functions.invoke(url, {
            method: "GET",
        });
        if (error) {
        } else {
            return data?.payload.results || [];
        }
    };

    return {
        fetchAllAssignAssets,
    };
};

const useAddAssignAssets = () => {
    const addAssignAssets = async (obj: any, id: any, reloadDashboard: () => void, handleCancelModal: any) => {
        const url = `/api-assets?schema_id=${id}`;
        const { data, error } = await supabase.functions.invoke(url, {
            method: "POST",
            body: JSON.stringify(obj),
        });
        if (error) {
            toast.error(error.message)
        } else {
            toast.success("Added successfully")
            reloadDashboard();
            handleCancelModal()
        }
    };

    return {
        addAssignAssets,
    };
};
const useDeleteAssignAssets = () => {
    const deleteAssignAssets = async (schemaId: any, assetId: any, reloadDashboard: () => void, handleCancelIsModal: any, setAssetsData: any) => {
        const url = `api-assets?schema_id=${schemaId}&asset_id=${assetId}`;
        const { data, error } = await supabase.functions.invoke(url, {
            method: "DELETE",
        });
        if (error) {
            const { error: fromSupabase } = await supabase
                .from('assets')
                .delete()
                .eq('schemaID', schemaId)
            if (fromSupabase) {
                toast.error(error.message)
            } else {
                toast.success("Deleted successfully");
                handleCancelIsModal();
                setAssetsData([])
                reloadDashboard();
            }
        } else {
            toast.success("Deleted successfully");
            handleCancelIsModal();
            setAssetsData([])
            reloadDashboard();
            // setAssetsData((prevData: any) => [...prevData, ...data?.payload.results]);
            // return data?.payload.results || [];
        }
    };

    return {
        deleteAssignAssets,
    };
};

const useFetchListForms = () => {
    const dispatch = useDispatch();
    const fetchListForms = async () => {
        const url = `api-forms`;
        const { data, error } = await supabase.functions.invoke(url, {
            method: "GET",
        });
        if (error) {
        } else {
            const arrayOfObjects = Object.values(data.payload);
            dispatch(storeRequestJobs(arrayOfObjects))
        }
    };

    return {
        fetchListForms,
    };
};

const useDeleteUsers = () => {
    const dispatch = useDispatch();
    const deleteUsers = async (id: string, handleCancelDeleteModal: any, synDataAfterDelete: any) => {
        dispatch(showLoader())
        const { error } = await supabase
            .from('users')
            .delete()
            .eq('id', id);
        handleCancelDeleteModal();
        synDataAfterDelete();
        dispatch(hideLoader())
        if (error) {
            toast.error(error.message)
        } else {
            toast.success("Deleted Successfuly");
        }
    }
    return {
        deleteUsers
    }
}

const useFetchAllAssetsSchema = () => {
    const fetchAllAssetsSchema = async (setAssetsSchema: any) => {
        const url = `api-assets`;
        const { data, error } = await supabase.functions.invoke(url, {
            method: "GET",
        });

        if (error) {
            console.error(error);
        } else {
            const ids = data?.payload?.map((asset: { id: number, title: string }) => ({ value: asset.id, label: asset.title }));
            setAssetsSchema(ids);
        }
    };

    return {
        fetchAllAssetsSchema,
    };
};

const useCreateJobColumn = () => {
    const createJobColumn = async (obj: any, id: string, setIsExist: any, onSyncClick: any, setIsTruckEditable: any) => {
        const idToCheck = id; // Change this to the actual id you're checking
        const { data: existingData, error: selectError } = await supabase
            .from('jobs')
            .select('id')
            .eq('id', idToCheck);

        if (selectError) {
            console.error('Error while checking if id exists:', selectError);
        } else {
            // Proceed with insert operation
            const { data: insertData, error: insertError } = await supabase
                .from('jobs')
                .insert([{ crew: "", truck: obj?.truck, extraCharges: obj?.extraCharges, shift: obj?.shift, compared_id: obj?.id }])
                .select();

            if (insertError) {
                toast.error(insertError.message)
                setIsTruckEditable(null)
            } else {
                onSyncClick()
                setIsTruckEditable(null)
                toast.success("Created Successfully")
            }
        }
    }
    return {
        createJobColumn
    }
}

const useCreateJobs = () => {
    const createJobs = async (obj: any, setOpen: (oen: boolean) => void, reloadDashboard: () => void, newKeysObj: any) => {
        const { data: createdData, error } = await supabase
            .from('queued_jobs')
            .insert([
                {
                    invoice: obj?.invoice,
                    dueDate: obj?.dueDate,
                    confirmed: obj?.confirmed,
                    title: obj?.title,
                    billingName: obj?.billingName,
                    contactName: obj?.contactName,
                    location: obj?.location,
                    assignedTo: obj?.assignedTo,
                    siteName: obj?.siteName,
                    zone: obj?.zone,
                    jobTitle: obj?.jobTitle,
                    jobType: obj?.jobType,
                    description: obj?.description,
                    endDate: obj?.endDate,
                    assignAssets: obj?.assignAssets,
                    priority: obj?.priority,
                    jobValue: obj?.jobValue,
                    isDisplay: obj?.isDisplay,
                    salesPerson: obj?.salesPerson,
                    salesSource: obj?.salesSource,
                    form: obj?.form,
                    shift: obj?.shift,
                    extraCharges: obj?.extraCharges,
                    status: obj?.status,
                    orderNumber: obj?.orderNumber,
                    jobNumber: obj?.jobNumber
                },
            ])
            .select()
        if (createdData) {
            toast.success("Job has been created successfully!");
            const { data, error } = await supabase
                .from('jobs')
                .insert([
                    { crew: "", truck: newKeysObj?.assignAssets, extraCharges: newKeysObj?.extraCharges, shift: newKeysObj?.shift, compared_id: createdData?.[0]?.id, contactName: newKeysObj?.contactName },
                ])
                .select()

            if (data) {
                setOpen(false);
                // dispatch(hideLoader());
                reloadDashboard();
            } else {
                setOpen(false);
                // dispatch(hideLoader());
                reloadDashboard();
                toast.error(error.message)
            }

        } else {
            toast.error(error.message);
            setOpen(false);
        }
    }
    return {
        createJobs
    }
}
// Fetching Queue Jobs from supabase
const useFetchCreatedJobs = () => {
    const fetchCreatedJobs = async (setCreatedJobsListing: any) => {
        let { data: queued_jobs, error } = await supabase
            .from('queued_jobs')
            .select('*')
            .order('created_at', { ascending: false });
        if (error) {
            toast.error(error.message)
        } else {

            const jobsWithSource = queued_jobs?.map(job => ({ ...job, source: 'supabase' }))
            setCreatedJobsListing(jobsWithSource)
        }
    }
    return {
        fetchCreatedJobs
    }
}

const useDeleteCreatedJobs = () => {
    const deleteCreatedJobs = async (id: string, setOpen: (oen: boolean) => void, reloadDashboard: () => void,) => {
        const { error } = await supabase
            .from('queued_jobs')
            .delete()
            .eq('id', id)
        if (error) {
            toast.error(error.message);
            setOpen(false)
        } else {
            toast.success("Deleted successfully")
            setOpen(false)
            reloadDashboard()
        }
    }
    return {
        deleteCreatedJobs
    }
}

const useDeleteCreatedJobsForDashboard = () => {
    const dispatch = useDispatch();
    const deleteCreatedJobsForDashboard = async (id: string, reloadDashboard: () => void,) => {
        dispatch(showLoader());
        const { error } = await supabase
            .from('queued_jobs')
            .delete()
            .eq('id', id)
        if (error) {
            toast.error(error.message);
            dispatch(hideLoader());
        } else {
            toast.success("Deleted successfully")
            reloadDashboard()
            dispatch(hideLoader());
        }
    }
    return {
        deleteCreatedJobsForDashboard
    }
}

const useUpdateCreatedJobs = () => {
    const updateCreatedJobs = async (obj: any, reloadDashboard: () => void, id: string, newKeysObj: any, setOpen: (open: boolean) => void) => {
        const { data: updatedData, error } = await supabase
            .from('queued_jobs')
            .update({
                invoice: obj?.invoice,
                dueDate: obj?.dueDate,
                confirmed: obj?.confirmed,
                title: obj?.title,
                billingName: obj?.billingName,
                contactName: obj?.contactName,
                location: obj?.location,
                assignedTo: obj?.assignedTo,
                siteName: obj?.siteName,
                zone: obj?.zone,
                jobTitle: obj?.jobTitle,
                jobType: obj?.jobType,
                description: obj?.description,
                endDate: obj?.endDate,
                assignAssets: obj?.assignAssets,
                priority: obj?.priority,
                jobValue: obj?.jobValue,
                isDisplay: obj?.isDisplay,
                salesPerson: obj?.salesPerson,
                salesSource: obj?.salesSource,
                form: obj?.form,
                shift: obj?.shift,
                extraCharges: obj?.extraCharges,
                orderNumber: obj?.orderNumber,
                jobNumber: obj?.jobNumber,
            })
            .eq('id', id)
            .select()
        if (updatedData) {
            const { data, error } = await supabase
                .from('jobs')
                .update({ crew: "", truck: newKeysObj?.assignAssets, extraCharges: newKeysObj?.extraCharges, shift: newKeysObj?.shift, compared_id: updatedData?.[0]?.id, contactName: newKeysObj?.contactName })
                .eq('compared_id', id)
                .select()

            if (data) {
                toast.success("Updated Successfuly");
                reloadDashboard();
                if (setOpen) {
                    setOpen(false)
                }
            } else {
                toast.error(error.message);
                reloadDashboard();
                if (setOpen) {
                    setOpen(false)
                }
            }

        } else {
            toast.error(error.message);
            if (setOpen) {
                setOpen(false)
            }
        }
    }
    return {
        updateCreatedJobs
    }
}


const useUpdateAssignToCreatedJobs = () => {
    const updateAssignToCreatedJobs = async (obj: any, reloadDashboard: () => void, id: string, newKeysObj: any, setIsEditable: any) => {
        const { data: updatedData, error } = await supabase
            .from('queued_jobs')
            .update({
                invoice: obj?.invoice,
                dueDate: obj?.dueDate,
                confirmed: obj?.confirmed,
                title: obj?.title,
                billingName: obj?.billingName,
                contactName: obj?.contactName,
                location: obj?.location,
                assignedTo: obj?.assignedTo,
                siteName: obj?.siteName,
                zone: obj?.zone,
                jobTitle: obj?.jobTitle,
                jobType: obj?.jobType,
                description: obj?.description,
                endDate: obj?.endDate,
                assignAssets: obj?.assignAssets,
                priority: obj?.priority,
                jobValue: obj?.jobValue,
                isDisplay: obj?.isDisplay,
                salesPerson: obj?.salesPerson,
                salesSource: obj?.salesSource,
                form: obj?.form,
                shift: obj?.shift,
                extraCharges: obj?.extraCharges,
            })
            .eq('id', id)
            .select()
        if (updatedData) {
            const { data, error } = await supabase
                .from('jobs')
                .update({ crew: "", truck: newKeysObj?.assignAssets, extraCharges: newKeysObj?.extraCharges, shift: newKeysObj?.shift, compared_id: updatedData?.[0]?.id, contactName: newKeysObj?.contactName })
                .eq('compared_id', id)
                .select()

            if (data) {
                toast.success("Updated Successfuly");
                if (setIsEditable) {
                    setIsEditable(null)
                }
                reloadDashboard();
            } else {
                toast.error(error.message);
                reloadDashboard();
            }

        } else {
            toast.error(error.message);
        }
    }
    return {
        updateAssignToCreatedJobs
    }
}

const useCreateAssets = () => {
    const createAssets = async (obj: any, reloadDashboard: () => void, handleCancelModal: any) => {
        const { data, error } = await supabase
            .from('assets')
            .insert([
                { schemaID: obj?.schemaID, name: obj?.name, schemaTitle: obj?.schemaTitle },
            ])
            .select()
        if (data) {
            toast.success("Created successfully")
            handleCancelModal()
            reloadDashboard()
        } else {
            toast.error(error.message)
            handleCancelModal()
        }
    }
    return {
        createAssets
    }
}

const useFetchAssets = () => {
    const fetchAssets = async (setAssetsListing: any) => {
        let { data: assets, error } = await supabase
            .from('assets')
            .select('*')
        if (error) {
            toast.error(error.message)
        } else {
            setAssetsListing(assets)
        }
    }
    return {
        fetchAssets
    }
}

const useDeleteAssets = () => {
    const deleteAssets = async () => {
        const { error } = await supabase
            .from('assets')
            .delete()
            .eq('some_column', 'someValue')
    }
    return {
        deleteAssets
    }
}

const useCreatNewCustomer = () => {
    const createNewCustomer = async (obj: any, handleCancelModal: any, reloadDashboard: any, handleFetchNewCustomers: any) => {
        const { data, error } = await supabase
            .from('clients')
            .insert([
                { billingName: obj?.billingName, contactName: obj?.contactName, email: obj?.email, phone: obj?.phone, latestNote: obj?.latestNote, address: obj?.address },
            ])
            .select()
        if (error) {
            toast.error(error.message)
            handleCancelModal()
        } else {
            toast.success("Created Successfully");
            handleCancelModal();
            reloadDashboard();
            handleFetchNewCustomers()
        }
    }
    return {
        createNewCustomer
    }
}

const useFetchNewCustomers = () => {
    const fetchNewCustomers = async (setNewCustomerListing: any) => {
        let { data: clients, error } = await supabase
            .from('clients')
            .select('*')
        if (clients) {
            setNewCustomerListing(clients)
        } else {
            toast.error(error?.message)
        }
    }
    return {
        fetchNewCustomers
    }
}

const useDeleteNewCustomer = () => {
    const deleteNewCustomer = async (id: any, handleFetchNewCustomers: any, reloadDashboard: any, handleCancelDelete: any) => {
        const { error } = await supabase
            .from('clients')
            .delete()
            .eq('id', id)
        if (error) {
            toast.error(error.message)
            handleCancelDelete()
        } else {
            toast.success("Deleted successfully");
            handleFetchNewCustomers();
            reloadDashboard();
            handleCancelDelete()
        }
    }
    return {
        deleteNewCustomer
    }
}

const useAddClient = () => {
    const userData = useAppSelector((state) => state.Authlogin?.data)
    const dispatch = useDispatch();
    const addClient = async (obj: any, handleCancelModal: any, reloadDashboard: any, handleFetchNewCustomers: any) => {
        const { data: createdData, error } = await supabase.functions.invoke("api-clients", {
            method: "POST",
            body: JSON.stringify(obj),
        });
        dispatch(showLoader())
        if (createdData) {
            const { data, error } = await supabase
                .from('clients')
                .insert([
                    { billingName: obj?.billingName, contactName: obj?.contactName, clientId: createdData?.payload?.id },
                ])
                .select()
            if (data) {
                toast.success("Created Successfully")
                dispatch(hideLoader())
                const { data, error } = await supabase
                    .from('users')
                    .update({ customer_formitize_id: createdData?.payload?.billingName })
                    .eq('id', userData?.id)
                    .select()
                handleCancelModal();
                reloadDashboard();
                handleFetchNewCustomers()
                if (data) {
                    dispatch(storeUserData({ ...userData, customer_formitize_id: createdData?.payload?.billingName }))
                }

            } else {
                toast.error(error.message)
                dispatch(hideLoader())
            }
        } else {
            toast.error(error?.message)
            dispatch(hideLoader())
        }
    }

    return {
        addClient
    }
}


const useUpdateRequestJob = () => {
    const updateRequestJob = async (id: any, handleFetchData: any, setIsExtraChargeEditable: any, updatedColumn: string, updatedValue: any) => {
        const { data, error } = await supabase
            .from('request_jobs')
            .update({ [updatedColumn]: updatedValue })
            .eq('id', id)
            .select()

        if (error) {
            toast.error(error.message)
        } else {
            toast.success("Updated Successfully");
            setIsExtraChargeEditable(null);
            handleFetchData()
        }
    }
    return {
        updateRequestJob
    }
}



export {
    useJobListing,
    useClientListing,
    usePostClientDetails,
    useFetchAllAssetsSchema,
    useDeleteAssignAssets,
    useDeleteJobDetail,
    useUpdateJobDetails,
    useFilterApply,
    useRequestJob,
    useUpdateAssignedJobDetails,
    usefetchRequestJob,
    useUpdateRemainingJobColumn,
    useSubmitJobRequest,
    useDeleteRequestJob,
    useFetchContactListing,
    useAddJobTitle,
    useFetchAssignAssetsIds,
    usefetchJobTitleListing,
    useFetchAllAssignAssets,
    useAddAssignAssets,
    useFetchListForms,
    useDeleteUsers,
    useFetchRemainingJobColoumns,
    useCreateJobColumn,
    useCreateJobs,
    useFetchCreatedJobs,
    useDeleteCreatedJobs,
    useUpdateCreatedJobs,
    useUpdateAssignToCreatedJobs,
    useSendClientDetails,
    useCreateAssets,
    useFetchAssets,
    useDeleteAssets,
    useDeleteCreatedJobsForDashboard,
    useCreatNewCustomer,
    useDeleteNewCustomer,
    useFetchNewCustomers,
    useUpdateColumnQuickly,
    useAddClient,
    useUpdateRequestJob
};
