import { Button } from "@/components";
import { DeleteJobsProps } from "@/models/DeleteModal";
import { Modal } from "antd";
import React from "react";

const DeleteModal: React.FC<DeleteJobsProps> = ({
  openModal,
  handleCancelModal,
  btnTextCncl,
  text,
  btnTextDlt,
  handleDelete,
}) => {
  return (
    <div>
      <Modal
        open={openModal}
        onOk={handleCancelModal}
        onCancel={handleCancelModal}
        footer={false}
        width={360}
        centered
      >
        <div>
          <p className="text-xl font-semibold text-center mt-12 mb-6">{text}</p>
          <div className="flex gap-3">
            <Button
              text={btnTextDlt}
              btnType="button"
              customClass="w-full font-bold shadow-sm rounded-lg py-3 bg-blue-500 text-white flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:bg-blue-700 focus:shadow-sm focus:shadow-outline"
              btnLoader={false}
              onClick={handleDelete}
            />
            <Button
              text={btnTextCncl}
              btnType="button"
              customClass="w-full font-bold shadow-sm rounded-lg py-3 bg-red-500 text-white flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:bg-red-700 focus:shadow-sm focus:shadow-outline"
              btnLoader={false}
              onClick={handleCancelModal}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export { DeleteModal };
