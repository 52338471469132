import React from "react";
import "./App.css";
import Allroutes from "./routes/Allroutes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div className="App">
      <ToastContainer position="top-right" />
      <Allroutes />
    </div>
  );
}

export default App;
