import supabase from '..';
import { showLoader, hideLoader } from '@/redux/slices/LoaderSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { SharedDocumentTableData } from '@/models';

export const useDocumentHandle = () => {
    const dispatch = useDispatch();

    const uploadDocument = async (
        file: File,
        uploadedBy: string,
        sharedWith: string[],
        setButtonLoader?: (loading: boolean) => void,
        onSuccess?: () => void
    ) => {
        dispatch(showLoader());
        setButtonLoader && setButtonLoader(true);

        try {
            // Determine the folder based on file type
            let folderName = 'documents'; // Default folder
            if (file.type.startsWith('image/')) {
                folderName = 'images';
            } else if (file.type.startsWith('video/')) {
                folderName = 'videos';
            } else if (file.type.startsWith('audio/')) {
                folderName = 'audio';
            }

            // Generate a unique filename to avoid conflicts
            const uniqueFilename = `${uploadedBy}-${file.name.replace(/[^a-zA-Z0-9.-]/g, '')}`;
            const filePath = `${folderName}/${uniqueFilename}`;

            // Check if the file already exists
            const { data: fileList, error: listError } = await supabase.storage
                .from('shared_documents')
                .list(folderName, { limit: 1, search: uniqueFilename });

            if (listError) {
                throw listError;
            }

            if (fileList.length > 0) {
                // File already exists, proceed to insert metadata
                await supabase
                    .from('shared_documents')
                    .insert([
                        {
                            doc_name: file.name,
                            uploaded_by: uploadedBy,
                            shared_with: sharedWith,
                            doc_path: filePath,
                        }
                    ]);

                dispatch(hideLoader());
                setButtonLoader && setButtonLoader(false);
                toast.success("File already exists. Metadata updated successfully!");
                onSuccess && onSuccess();
                return; // Exit function
            }

            // Upload file to the appropriate folder in Supabase storage bucket
            const { data, error } = await supabase.storage
                .from('shared_documents')
                .upload(filePath, file);

            if (error) {
                throw error;
            }

            // Save metadata to Supabase table
            await supabase
                .from('shared_documents')
                .insert([
                    {
                        doc_name: file.name,
                        uploaded_by: uploadedBy,
                        shared_with: sharedWith,
                        doc_path: filePath,
                    }
                ]);

            dispatch(hideLoader());
            setButtonLoader && setButtonLoader(false);
            toast.success("File uploaded and metadata saved successfully!");
            onSuccess && onSuccess();
        } catch (error) {
            dispatch(hideLoader());
            setButtonLoader && setButtonLoader(false);
            toast.error("Failed to upload file.");
            console.error("Error uploading file:", error);
        }
    };

    const getPublicUrl = async (path: string) => {
        try {
            const { data } = await supabase.storage.from('shared_documents').getPublicUrl(path);
            if (data) {
                return data.publicUrl;
            } else {
                throw Error();
            }
        } catch (error) {
            toast.error('Failed to get URL!');
            console.error(error);
        }
    };

    const deleteDocument = async (document: SharedDocumentTableData, onSuccess: () => void) => {
        try {
            dispatch(showLoader())
            const { data, error } = await supabase
                .storage
                .from('shared_documents')
                .remove([document.doc_path])

            if (data) {
                const { data: tableData, error: tableError } = await supabase.from('shared_documents').delete().eq('id', document.id)
                if (!tableError && !error) {
                    toast.success('Document Deleted!')
                    onSuccess()
                }
                else {
                    throw Error()
                }
            }

        } catch (err) {
            toast.error('Somthing went wrong! Failed to delete document')
        } finally {
            dispatch(hideLoader())
        }
    }

    return {
        uploadDocument,
        getPublicUrl,
        deleteDocument
    };
};
