import Login from "@/pages/common/auth/login";
import Signup from "@/pages/common/auth/signup/Signup";

const PublicPath = [
  {
    path: "/",
    component: <Signup />,
  },
  {
    path: "/login",
    component: <Login />,
  },
];

export { PublicPath };
