import { AssignRequestjobNumProps } from "@/models";
import { ROLE } from "@/utils/enums";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import React from "react";

const AssignRequestJobNumber: React.FC<AssignRequestjobNumProps> = ({
  isJobNumberEditable,
  record,
  jobNumberValue,
  setJobNumberValue,
  setIsJobNumberEditable,
  handleUpdateAssignTo,
  values,
  role,
}) => {
  React.useEffect(() => {
    setJobNumberValue(values);
  }, []);
  return (
    <div>
      <span className="block overflow-hidden text-ellipsis whitespace-nowrap w-full">
        {isJobNumberEditable !== record?.id ? (
          <span
            className={`flex ${values ? "justify-between" : "justify-end"} `}
          >
            {values}
            {role != ROLE.customer && role != ROLE.staff && (
              <EditOutlined
                className="cursor-pointer text-black"
                onClick={() => {
                  setIsJobNumberEditable(record?.id);
                }}
              />
            )}
          </span>
        ) : (
          <span className="text-black flex items-center gap-2 relative">
            <input
              name="jobNumber"
              type="text"
              value={jobNumberValue || undefined}
              onChange={(e) => setJobNumberValue(e.target.value)}
              placeholder="Enter job number"
              className="w-full border-1 rounded p-2 text-black"
            />
            <CloseOutlined
              onClick={() => {
                setIsJobNumberEditable(null);
                setJobNumberValue(null);
              }}
            />
            <CheckOutlined
              onClick={(e) => {
                handleUpdateAssignTo(record, "jobNumber");
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </span>
        )}
      </span>
    </div>
  );
};

export default AssignRequestJobNumber;
