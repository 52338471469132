import { JobFormListSlice } from "@/models";
import { createSlice } from "@reduxjs/toolkit";



const initialState: JobFormListSlice = {
  jobFormList: [],
};

const jobFormListSlice = createSlice({
  name: "jobFormListSlice",
  initialState,
  reducers: {
    storeRequestJobs: (state, actions) => {
      state.jobFormList = actions.payload;
    },
  },
});

export const { storeRequestJobs } = jobFormListSlice.actions;
export default jobFormListSlice.reducer;


