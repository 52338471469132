import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";
import { Button, Select } from "antd";
import { Client, Delete } from "@/utils/Images";
import {
  useDeleteNewCustomer,
  useFetchContactListing,
  useFetchNewCustomers,
} from "@/supabase/jobs";
import AddClientsModal from "./AddClientsModal";
import { useAppSelector } from "@/redux/store/Store";
import { ROLE } from "@/utils/enums";
import {
  ClientDetailsClient,
  ClientDetailsClientOption,
  ClientDetailsContactArr,
  ClientDetailsStepProps,
  ClientDetialSelectFieldProps,
  DeleteModalData,
} from "@/models";
import { DeleteModal } from "@/components";

const { Option } = Select;

const BillingSelectField: React.FC<ClientDetialSelectFieldProps> = ({
  field,
  form,
  options,
  loader,
  updateListing,
  setContactListingLoader,
  reloadDashboard,
  ...props
}) => {
  const contactsArr: ClientDetailsContactArr[] = [];
  const { deleteNewCustomer } = useDeleteNewCustomer();
  const { fetchContactListing } = useFetchContactListing();
  const { fetchNewCustomers } = useFetchNewCustomers();
  const [openModal, setOpentModal] = useState(false);
  const userRole = useAppSelector((state) => state.Authlogin.data?.role);
  const [newCustomerListing, setNewCustomerListing] = useState([]);
  const [clientsData, setClientsData] = useState<ClientDetailsClientOption[]>(
    []
  );
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalData, setDeleteModalData] =
    useState<DeleteModalData | null>(null);
  const [clientId, setClientId] = useState<string | null>(null);
  const userData = useAppSelector((state) => state.Authlogin?.data);
  const matchedObject =
    clientsData?.length > 0
      ? clientsData.find((obj) => obj.name === userData?.customer_formitize_id)
      : undefined;

  const newObject = matchedObject
    ? {
        label: matchedObject.name, // Assuming you want the label as is
        value: String(matchedObject.value), // Convert value to string if needed
      }
    : undefined;

  const handleChange = (value: string) => {
    setClientId(value);
    const selectedOption = clientsData.find((opt: any) => opt.value === value);
    if (selectedOption) {
      form.setFieldValue(field.name, selectedOption.name);
    }
  };

  const handleOpenDelete = () => {
    setDeleteModal(true);
  };
  const handleCancelDelete = () => {
    setDeleteModal(false);
  };

  const handleOpenModal = () => {
    setOpentModal(true);
  };
  const handleCancelModal = () => {
    setOpentModal(false);
  };

  const clientArray = () => {
    if (newCustomerListing?.length > 0) {
      const clientObjectOptions: ClientDetailsClientOption[] = [];

      newCustomerListing?.map((ele: ClientDetailsClient) => {
        let label = "";
        if (ele.address) {
          label += `\nAddress : ${ele.address}`;
        }
        if (ele.phone) {
          label += `\nContact : ${ele.phone}`;
        }
        if (ele.email) {
          label += `\nEmail : ${ele.email}`;
        }
        let obj: ClientDetailsClientOption = {
          label,
          name: ele.billingName || ele.contactName, // Use billingName or contactName
          value: ele.id, // Use 'id' as the value
        };
        clientObjectOptions.push(obj);
      });

      // Set the clients data with the updated list
      setClientsData(clientObjectOptions);
    }
  };
  const handleFetchNewCustomers = () => {
    fetchNewCustomers(setNewCustomerListing);
  };

  const handleDeleteClient = (value: any) => {
    setDeleteModalData(value);
  };

  React.useEffect(() => {
    if (clientId) {
      fetchContactListing(
        clientId,
        updateListing,
        contactsArr,
        setContactListingLoader
      );
    }
  }, [clientId]);

  const handleDeleteClients = () => {
    deleteNewCustomer(
      deleteModalData?.value,
      handleFetchNewCustomers,
      reloadDashboard,
      handleCancelDelete
    );
  };

  React.useEffect(() => {
    handleFetchNewCustomers();
  }, []);

  React.useEffect(() => {
    if (userRole != ROLE.admin) {
      form.setFieldValue(field.name, newObject?.label);
    }
  }, [matchedObject]);

  React.useEffect(() => {
    if (newCustomerListing?.length > 0) {
      clientArray();
    }
  }, [newCustomerListing]);

  return (
    <div>
      {openModal && (
        <AddClientsModal
          openModal={openModal}
          handleCancelModal={handleCancelModal}
          reloadDashboard={reloadDashboard}
          handleFetchNewCustomers={handleFetchNewCustomers}
        />
      )}
      {deleteModal && (
        <DeleteModal
          openModal={deleteModal}
          handleCancelModal={handleCancelDelete}
          btnTextCncl="Cancel"
          text={`Are you sure you want to delete ${deleteModalData?.name} from
          application?`}
          btnTextDlt="Delete"
          handleDelete={handleDeleteClients}
        />
      )}
      <Select
        {...field}
        {...props}
        value={field.value || undefined}
        onChange={handleChange}
        loading={loader}
        showSearch
        placeholder="Select an option"
        disabled={matchedObject ? true : false}
        optionFilterProp="label"
        filterOption={(input, option) => {
          const label = String(option?.name).toLowerCase();
          const searchTerm = input?.toLowerCase() ?? "";
          return label.includes(searchTerm);
        }}
        notFoundContent={false}
        dropdownRender={(menu) => (
          <>
            {menu}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 8,
              }}
            >
              <Button
                type="dashed"
                onClick={() => handleOpenModal()}
                style={{ marginBottom: 8 }}
              >
                {userRole == ROLE.admin ? "Add More" : "Add Your Client"}
              </Button>
            </div>
          </>
        )}
        className="custom-select mt-0.5 w-full rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
      >
        {userRole == ROLE.admin
          ? clientsData?.map((option) => (
              <Option
                key={option.value}
                value={option?.value}
                name={option?.name}
              >
                <div style={{ whiteSpace: "pre-wrap" }}>
                  <div className="flex items-center justify-between">
                    <div>
                      <strong>{option?.name}</strong>
                      {option.label}
                    </div>
                    <div>
                      <img
                        src={Delete}
                        alt=""
                        className="h-5 w-5"
                        onClick={() => {
                          handleOpenDelete();
                          handleDeleteClient(option);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Option>
            ))
          : matchedObject && (
              <Option
                key={matchedObject.value}
                value={matchedObject?.value}
                name={matchedObject?.name}
              >
                <div style={{ whiteSpace: "pre-wrap" }}>
                  <div className="flex items-center justify-between">
                    <div>
                      <strong>{matchedObject?.name}</strong>
                    </div>
                  </div>
                </div>
              </Option>
            )}
      </Select>
    </div>
  );
};

const ClientDetails: React.FC<ClientDetailsStepProps> = ({
  clientsData,
  loader,
  updateListing,
  setContactListingLoader,
  reloadDashboard,
}) => {
  return (
    <div className="min-h-screen-minus-150">
      <h1 className="text-base font-bold mt-3 flex items-center gap-2">
        <img src={Client} alt="" className="w-6 h-6" />
        Client Details
      </h1>
      <div className="mt-5">
        <label className="font-medium">Billing Name</label>
        <Field
          name="billingName"
          options={clientsData}
          loader={loader}
          updateListing={updateListing}
          component={BillingSelectField}
          setContactListingLoader={setContactListingLoader}
          reloadDashboard={reloadDashboard}
        />
        <ErrorMessage
          name="billingName"
          component="div"
          className="text-red-500 text-sm"
        />
      </div>

      <div className="mt-5">
        <label className="font-medium">Contact Name</label>
        <Field
          name="contactName"
          type="text"
          placeholder="Enter contact name"
          className="w-full mt-0.5 px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
        />
        <ErrorMessage
          name="contactName"
          component="div"
          className="text-red-500 text-sm"
        />
      </div>

      <div className="mt-5">
        <label className="font-medium">Location</label>
        <Field
          name="location"
          as="textarea"
          placeholder="Enter location"
          className="w-full mt-0.5 px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
        />
        <ErrorMessage
          name="location"
          component="div"
          className="text-red-500 text-sm"
        />
      </div>
    </div>
  );
};

export { ClientDetails };
