import { AssignRequestExtraChargesProps } from "@/models";
import { ROLE } from "@/utils/enums";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import React from "react";

const AssignRequestExtraCharges: React.FC<AssignRequestExtraChargesProps> = ({
  isExtraChargeEditable,
  record,
  extraChargesValue,
  setExtraChargesValue,
  setIsExtraChargeEditable,
  handleUpdateAssignTo,
  values,
  role,
}) => {
  React.useEffect(() => {
    setExtraChargesValue(values);
  }, []);
  return (
    <div>
      <span className="block overflow-hidden text-ellipsis whitespace-nowrap w-full">
        {isExtraChargeEditable !== record?.id ? (
          <span
            className={`flex ${values ? "justify-between" : "justify-end"} `}
          >
            {values}
            {role != ROLE.customer && role != ROLE.staff && (
              <EditOutlined
                className="cursor-pointer text-black"
                onClick={() => {
                  setIsExtraChargeEditable(record?.id);
                }}
              />
            )}
          </span>
        ) : (
          <span className="text-black flex items-center gap-2 relative">
            <input
              name="extraCharges"
              type="text"
              value={extraChargesValue || undefined}
              onChange={(e) => setExtraChargesValue(e.target.value)}
              placeholder="Enter charges"
              className="w-full border-1 rounded p-2 pl-8 text-black"
            />
            <span className="flex items-center gap-2">
              <CloseOutlined
                onClick={() => {
                  setIsExtraChargeEditable(null);
                  setExtraChargesValue(null);
                }}
              />
              <CheckOutlined
                onClick={(e) => {
                  handleUpdateAssignTo(record, "extraCharges");
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            </span>
          </span>
        )}
      </span>
    </div>
  );
};

export default AssignRequestExtraCharges;
