import { UserDataState } from "@/models";
import { createSlice } from "@reduxjs/toolkit";



const initialState: UserDataState = {
    userList: [],
    searchText: null,
    formitizeUsers: [],
    profileImage: null,
};

const usersSlice = createSlice({
    name: "usersSlice",
    initialState,
    reducers: {
        storeUserData: (state, action) => {
            state.userList = action.payload;
        },
        setSearchText: (state, action) => {
            state.searchText = action.payload
        },
        storeFormitizeUsers: (state, action) => {
            state.formitizeUsers = action.payload
        },
        setProfileImage: (state, action) => {
            state.profileImage = action.payload
        }
    },
});

export const { storeUserData, setSearchText, storeFormitizeUsers, setProfileImage } = usersSlice.actions;
export default usersSlice.reducer;
