import React from "react";
import { SharedDocumentTable } from "@/pages";

const SharedDocuments = () => {
  return (
    <div>
      <SharedDocumentTable />
    </div>
  );
};

export { SharedDocuments };
