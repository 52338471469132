import { useAppSelector } from '@/redux/store/Store';

const useAuth = () => {
  const profileData = useAppSelector(
    (state) =>
      state.Authlogin.data?.session?.user?.user_metadata ||
      state.Authlogin.data?.user?.user_metadata
  );
  return {
    profileData
  }
}

export { useAuth }