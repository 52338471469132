import { StoreAssetsState } from "@/models";
import { createSlice } from "@reduxjs/toolkit";

const initialState: StoreAssetsState = {
    assets: [],
};

const storeAssetsSlice = createSlice({
    name: "storeAssetsSlice",
    initialState,
    reducers: {
        storeAssets: (state, actions) => {
            state.assets = actions.payload;
        },
    },
});

export const { storeAssets } = storeAssetsSlice.actions;
export default storeAssetsSlice.reducer;
