import { useAuth } from "@/supabase/auth";
import { DefaultProfile, HeaderSearch } from "@/utils/Images";
import { DownArrowIcon, LogoutIcon, ProfileIcon } from "@/utils/SvgIcons";
import { Dropdown } from "antd";
import React, { useState } from "react";
import type { MenuProps } from "antd";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "@/redux/store/Store";
import { useSelector } from "react-redux";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { setSearchText } from "@/redux/slices/UsersSlice";

const Header = () => {
  const dispatch = useAppDispatch();
  const pageUrl = window.location.pathname;
  const { fetchUserProfileByEmail } = useAuth();
  const profileEmail = useAppSelector(
    (state) => state.Authlogin?.data?.user?.email
  );
  const profileImage = useAppSelector(
    (state) => state.usersSlice?.profileImage
  );
  const [showLogoute, setShowLogout] = useState(false);
  const [loading, setLoading] = useState(false);
  const { logoutUser } = useAuth();
  const navigate = useNavigate();
  const profileData = useSelector(
    (state: RootState) =>
      state.Authlogin.data?.session?.user?.user_metadata ||
      state.Authlogin.data?.user?.user_metadata
  );
  const role = useAppSelector((state) => state.Authlogin?.data?.role);

  const searchText = useAppSelector((state) => state.usersSlice.searchText);

  const items: MenuProps["items"] = [
    {
      label: (
        <p
          className="text-black h-10 flex items-center gap-2 cursor-pointer"
          onClick={() => navigate("profile")}
        >
          <ProfileIcon /> My Profile
        </p>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: (
        <p
          className="text-black h-10 flex items-center gap-2 cursor-pointer"
          onClick={() => logoutUser()}
        >
          <LogoutIcon />
          Logout
        </p>
      ),
      key: "1",
    },
  ];

  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setSearchText(event.target.value));
  };

  React.useEffect(() => {
    if (profileEmail) fetchUserProfileByEmail(profileEmail);
  }, []);

  React.useEffect(() => {
    if (profileData?.avatar_url || profileImage) {
      setLoading(true);
    }
  }, [profileImage, profileData?.avatar_url]);

  return (
    <div className="h-20 bg-white shadow-md	flex items-center justify-between px-6 ">
      <div className="mr-4">
        <MenuUnfoldOutlined className="cursor-pointer text-2xl" />
      </div>
      <div className="w-1/2 flex items-center gap-2">
        <img src={HeaderSearch} alt="" className="w-6" />
        <input
          type="search"
          placeholder={
            pageUrl == "/jobs" ? "Search Job ID" : "Type to search..."
          }
          className="h-20 outline-none w-full pr-2 bg-white"
          onChange={handleSearchTextChange}
          value={searchText ?? ""}
        />
      </div>
      <div className="sm:hidden w-16 flex items-center justify-end"></div>
      <div className="hidden sm:flex w-full justify-end items-center">
        <Dropdown menu={{ items }} trigger={["click"]} placement="topRight">
          <div
            className="flex items-center bg-white gap-4 cursor-pointer"
            onClick={() => setShowLogout(!showLogoute)}
          >
            <div>
              <p className="font-medium text-sm">
                {profileData?.full_name ||
                  profileData?.preferred_username ||
                  profileData?.name}
              </p>
              <p className="text-xs text-right text-slate-400">{role}</p>
            </div>
            {loading && (
              <div className="w-5 h-5 border border-black border-l-0 animate-spin rounded-full"></div>
            )}

            <img
              src={profileData?.avatar_url || profileImage || DefaultProfile}
              alt=""
              style={{ display: loading ? "none" : "block" }}
              className="w-12 h-12 rounded-full"
              onLoad={() => setLoading(false)}
            />

            <DownArrowIcon />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export { Header };
