import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  InternalAxiosRequestConfig,
} from "axios";

// Define the Axios instance configuration
const config: AxiosRequestConfig = {
  baseURL: "https://ipbmgxltjqvmlivdejuv.supabase.co", // Your Supabase base URL
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${process.env.REACT_APP_SUPABASE_KEY}`, // Your Supabase API key
  },
};

// Create an Axios instance
const Api = axios.create(config);

// Request interceptor
Api.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    // Add any request modifications here
    return config;
  },
  (error: AxiosError) => {
    // Handle request errors here
    console.error("Request error:", error.message);
    return Promise.reject(error);
  }
);

// Response interceptor
Api.interceptors.response.use(
  (response: AxiosResponse) => {
    // Add any response modifications here
    return response;
  },
  (error: AxiosError) => {
    // Handle response errors here
    console.error("Response error:", error.message);
    return Promise.reject(error);
  }
);

export { Api };
