import { AssignRequestDriverProps } from "@/models";
import { ROLE } from "@/utils/enums";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Select } from "antd";
import React from "react";

const AssignRequestDriver: React.FC<AssignRequestDriverProps> = ({
  values,
  record,
  isEditable,
  setIsEditable,
  assignedData,
  crewName,
  setCrewName,
  handleUpdateAssignTo,
  role,
  jobsObj,
}) => {
  const jobs = jobsObj?.filter((val: any) =>
    record?.assignedTo?.includes(val?.formitize_id)
  );

  // If jobs are found, map to get their names, otherwise return an empty string
  const jobNames = jobs?.length
    ? jobs.map((job: any) => job.name).join(", ")
    : "";

  const options = assignedData?.filter((val: any) =>
    record?.assignedTo?.includes(val?.value)
  );
  React.useEffect(() => {
    if (values) {
      setCrewName(values);
    }
  }, []);
  return (
    <div>
      <span className="whitespace-nowrap">
        {isEditable == record?.id && isEditable ? (
          <div className="flex items-center gap-2">
            <Select
              allowClear
              mode="multiple"
              maxTagCount={1}
              value={crewName}
              style={{
                width: "100%",
                height: "48px",
              }}
              onChange={(_: any, data: any) => {
                setCrewName(data);
              }}
              placeholder="Please select"
              filterOption={(input, option: any) =>
                option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={assignedData}
            />
            <CloseOutlined
              onClick={(e) => {
                if (values?.length > 0) {
                  setIsEditable(null);
                  e.preventDefault();
                  e.stopPropagation();
                } else {
                  setIsEditable(null);
                  setCrewName([]);
                }
              }}
            />
            <CheckOutlined
              onClick={(e) => {
                handleUpdateAssignTo(record, "assignedTo");
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </div>
        ) : (
          <span
            className={`flex items-center gap-2 ${
              jobNames ? "justify-between" : "justify-end"
            }`}
          >
            {jobNames}
            {role != ROLE.customer && role != ROLE.staff && (
              <EditOutlined
                className="cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsEditable(record?.id);
                }}
              />
            )}
          </span>
        )}
      </span>
    </div>
  );
};

export default AssignRequestDriver;
