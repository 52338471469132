// auth/SignupSlice.ts
import { AuthState } from "@/models";
import { createSlice } from "@reduxjs/toolkit";

const initialState: AuthState = {
  data: null,
  sideNav: false,
  isLoginWithGoogle: false,
  isLoginWithMicrosoft: false,
};

const SignupSlice = createSlice({
  name: "Authlogin",
  initialState,
  reducers: {
    storeUserData: (state, action) => {
      state.data = action.payload;
    },
    OpenSideNav: (state, action) => {
      state.sideNav = action.payload;
    },
    LoginWithGoogle: (state, action) => {
      state.isLoginWithGoogle = action.payload;
    },
    LoginWithMicrosoft: (state, action) => {
      state.isLoginWithMicrosoft = action.payload;
    },
  },
});

export const {
  storeUserData,
  OpenSideNav,
  LoginWithGoogle,
  LoginWithMicrosoft,
} = SignupSlice.actions;
export default SignupSlice.reducer;

export interface RootState {
  Authlogin: AuthState;
}
