import { Button } from "@/components";
import { useClientListing, useRequestJob } from "@/supabase/jobs";
import { CloseOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import { Formik, Form, FormikHelpers } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { JobDetails, ScheduleVisit, ClientDetails } from "@/pages";
import dayjs from "dayjs";
import { useAppSelector } from "@/redux/store/Store";
import {
  ApiClienData,
  RequestJobAssigned,
  RequestJobClient,
  RequestJobFormValues,
  RequestJobProps,
} from "@/models";

const RequestJob: React.FC<RequestJobProps> = ({
  onClose,
  setRequestOpen,
  requestOpen,
  reloadDashboard = () => {},
}) => {
  const arr: RequestJobAssigned[] = [];
  const [contactListing, setContactListing] = useState([]);
  const [allAssignedName, setAllAssignedName] = useState<RequestJobAssigned[]>(
    []
  );
  const jobsObj = useAppSelector((state) => state?.usersSlice?.formitizeUsers);
  const [step, setStep] = useState(0);
  const { requestJob } = useRequestJob();
  const [clientsData, setClientsData] = useState<RequestJobClient[]>([]);
  const userRole = useAppSelector((state) => state.Authlogin.data?.role);
  const [loader, setLoader] = React.useState(false);
  const { fetchClientsData } = useClientListing();
  const clients = useAppSelector((state) => state.clientSlice.clientList);
  const [contactListingLoader, setContactListingLoader] = useState(false);
  const [formProgressData, setFormProgressData] = useState([]);

  const initialValues: RequestJobFormValues = {
    id: "",
    billingName: "",
    contactName: "",
    title: "",
    location: "",
    dueDate: null,
    assignedTo: [],
    status: "",
    confirmed: "",
    siteName: "",
    zone: "",
    jobType: "",
    jobTitle: "",
    orderNumber: "",
    crew: "",
    description: "",
    endDate: null,
    shift: "",
    assignAssets: [],
    extraCharges: "",
    priority: "",
    dueTime: "",
    endTime: "",
    invoice: "",
    jobValue: "",
    salesPerson: "",
    form: "",
    salesSource: "",
    isDisplay: false,
    // invoiceNumber: "",
  };

  const getUnixTimestampInSeconds = (
    dateString: string,
    timeString?: string
  ): number => {
    const dateTimeString = timeString
      ? `${dateString} ${timeString}`
      : dateString;

    const date = dayjs(
      dateTimeString,
      timeString ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD"
    );
    return Math.floor(date.valueOf() / 1000);
  };

  const validationSchemas = [
    Yup.object().shape({
      billingName: Yup.string().required("Billing name is required"),
      contactName: Yup.string().required("Contact name is required"),
      location: Yup.string().required("Location is required"),
    }),
    Yup.object().shape({
      dueDate: Yup.string().required("Due Date is required"),
      dueTime: Yup.string().required("Due Time is required"),
      endTime: Yup.string().required("End Time is required"),
      endDate: Yup.date()
        .required("End Date is required")
        .test(
          "is-after-dueDate",
          "End date must be after or equal to due date",
          function (value) {
            const { dueDate } = this.parent;
            if (!value || !dueDate) return true; // Skip validation if dueDate or endDate is not provided

            // Normalize dates to midnight
            const normalizeDate = (dateStr: any) => {
              const date = new Date(dateStr);
              date.setHours(0, 0, 0, 0);
              return date;
            };

            const normalizedDueDate = normalizeDate(dueDate);
            const normalizedEndDate = normalizeDate(value);

            return normalizedEndDate >= normalizedDueDate;
          }
        ),
    }),
    Yup.object().shape({
      jobType: Yup.object().required("Job type is required"),
    }),
    Yup.object().shape({}),
  ];

  const handleSubmit = async (
    values: RequestJobFormValues,
    { setSubmitting }: FormikHelpers<RequestJobFormValues>
  ) => {
    if (step === 3) {
      const extractIds = (data: any[]): string[] => {
        let ids: string[] = [];

        const traverse = (obj: any) => {
          if (obj.formID) ids.push(obj.formID);

          if (obj.children) {
            Object.values(obj.children).forEach((child) => traverse(child));
          }
        };

        data.forEach((item) => traverse(item));
        return ids;
      };

      // Extract IDs from formProgressData
      const allIds = extractIds(formProgressData);
      let obj = {
        billingName: values?.billingName || "",
        contactName: values?.contactName || "",
        title: values?.jobTitle || "",
        location: values?.location || "",
        dueDate: String(
          getUnixTimestampInSeconds(
            values?.dueDate ? String(values?.dueDate) : "", // Ensure string is passed
            values?.dueTime || ""
          )
        ),
        assignedTo: values?.assignedTo || [],
        status: values?.status || "",
        confirmed: values?.confirmed || "",
        extraCharges: values?.extraCharges,
        crew: values?.crew,
        shift: values?.shift,
        siteName: values?.siteName || "",
        zone: values?.zone || "",
        jobType: values?.jobType || "",
        jobTitle: values?.jobTitle || "",
        description: values?.description || "",
        orderNumber: values?.orderNumber,
        form: allIds,
        endDate: String(
          getUnixTimestampInSeconds(
            values?.endDate ? String(values?.endDate) : "", // Ensure string is passed
            values?.endTime || ""
          )
        ),
        assignAssets: values?.assignAssets || [],
        priority: values?.priority || "",
        invoice: values?.invoice || "",
        jobValue: values?.jobValue || "",
        salesPerson: values?.salesPerson || "",
        salesSource: values?.salesSource || "",
        isDisplay: values?.isDisplay || false,
      };
      requestJob(obj, setRequestOpen);
    } else {
      setStep((prev) => prev + 1);
    }
    setSubmitting(false);
  };

  const handleBack = () => {
    setStep((prev) => prev - 1);
  };

  const handleNext = (validateForm: any, setTouched: any) => {
    validateForm().then((errors: any) => {
      if (Object.keys(errors).length === 0) {
        setStep((prev) => prev + 1);
      } else {
        setTouched(errors);
      }
    });
  };

  const clientArray = () => {
    if (clients) {
      const clientObjectOptions: RequestJobClient[] = [];
      Object.values(clients).map((ele: ApiClienData) => {
        let label = "";
        if (ele?.primaryAddress) {
          label += `\nAddress : ${ele?.primaryAddress}`;
        }
        if (ele?.cachedata?.[0]?.type == "Mobile") {
          label += `\nContact : ${ele?.cachedata?.[0]?.value}`;
        }
        if (ele?.cachedata?.[0]?.type == "Email") {
          label += `\nEmail : ${ele?.cachedata?.[0]?.value}`;
        }

        let obj = {
          label,
          name: ele?.billingName,
          value: ele?.clientID,
        };
        clientObjectOptions.push(obj);
      });
      setClientsData(clientObjectOptions);
    }
  };

  const updateListing = (val: any) => {
    setContactListing(val);
  };

  React.useEffect(() => {
    fetchClientsData(setLoader);
    clientArray();
  }, []);

  React.useEffect(() => {
    jobsObj?.length > 0 &&
      jobsObj?.map((ele) => {
        let obj = {
          label: ele?.name,
          value: ele?.formitize_id,
        };
        arr.push(obj);
      });
    setAllAssignedName(arr);
  }, []);

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={onClose}
      open={requestOpen}
      key="bottom"
      className="overflow-hidden"
    >
      <div>
        <div className="flex items-center justify-between">
          <h1 className="font-medium text-lg flex gap-2">
            <CloseOutlined
              className="cursor-pointer text-sm"
              onClick={onClose}
            />
            Request Job
          </h1>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchemas[step]}
        >
          {({
            isSubmitting,
            validateForm,
            errors,
            touched,
            values,
            setTouched,
          }) => (
            <Form>
              <div className="min-h-screen-minus-150">
                {step === 0 && (
                  <ClientDetails
                    errors={errors}
                    touched={touched}
                    clientsData={clientsData}
                    loader={loader}
                    initialValues={values}
                    contactListing={contactListing}
                    updateListing={updateListing}
                    setContactListingLoader={setContactListingLoader}
                    contactListingLoader={contactListingLoader}
                    reloadDashboard={reloadDashboard}
                  />
                )}
                {step === 1 && (
                  <ScheduleVisit
                    errors={errors}
                    touched={touched}
                    allAssignedName={allAssignedName}
                  />
                )}
                {(step === 2 || step === 3) && (
                  <JobDetails
                    errors={errors}
                    touched={touched}
                    setFormProgressData={setFormProgressData}
                  />
                )}
              </div>
              <div className="flex items-center justify-between gap-5 mt-4">
                {step > 0 && (
                  <Button
                    text="Back"
                    btnType="button"
                    btnLoader={false}
                    customClass="w-full py-4 font-bold shadow-sm rounded-lg bg-gray-500 text-white"
                    onClick={handleBack}
                  />
                )}
                <Button
                  text={step === 2 ? "Request" : "Next"}
                  btnType={step === 3 ? "submit" : "button"}
                  onClick={() => handleNext(validateForm, setTouched)}
                  btnLoader={isSubmitting}
                  customClass="w-full py-4 font-bold shadow-sm rounded-lg bg-blue-500 text-white"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Drawer>
  );
};

export { RequestJob };
