import { AddClientModalProps } from "@/models";
import { useAppSelector } from "@/redux/store/Store";
import { useAddClient, useCreatNewCustomer } from "@/supabase/jobs";
import { ROLE } from "@/utils/enums";
import { Modal } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";

const AddClientsModal: React.FC<AddClientModalProps> = ({
  openModal,
  handleCancelModal,
  reloadDashboard,
  handleFetchNewCustomers,
}) => {
  const { createNewCustomer } = useCreatNewCustomer();
  const { addClient } = useAddClient();
  const userRole = useAppSelector((state) => state.Authlogin.data?.role);

  const initialValues = {
    billingName: "",
    contactName: "",
    phone: "",
    email: "",
    address: "",
    latestNote: "",
  };
  const validationSchema = Yup.object().shape({
    billingName: Yup.string()
      .required("Billing name is required")
      .min(3, "Billing name must be at least 3 characters long"),
    contactName: Yup.string()
      .required("Contact name is required")
      .min(3, "Contact name must be at least 3 characters long"),
  });
  const handleSubmit = (values: any) => {
    const obj = {
      address: values?.address || "",
      contactName: values?.contactName || "",
      billingName: values?.billingName || "",
      email: values?.email || "",
      phone: values?.phone || "",
      latestNote: values?.latestNote || "",
    };
    const formitizeObj = {
      contactName: values?.contactName || "",
      billingName: values?.billingName || "",
    };
    {
      userRole == ROLE.admin
        ? createNewCustomer(
            obj,
            handleCancelModal,
            reloadDashboard,
            handleFetchNewCustomers
          )
        : addClient(
            formitizeObj,
            handleCancelModal,
            reloadDashboard,
            handleFetchNewCustomers
          );
    }
  };

  return (
    <div>
      <Modal
        title="Add Customer"
        open={openModal}
        onOk={handleCancelModal}
        centered
        onCancel={handleCancelModal}
        footer={false}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema} // Add the validation schema here
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="mt-3">
              <label className="block font-medium">Billing Name</label>
              <Field
                type="text"
                className="border-1 h-10 w-full rounded-md mt-1 px-2 focus:outline-none"
                name="billingName"
                placeholder="Enter billing name"
              />
              <ErrorMessage
                name="billingName"
                component="div"
                className="text-red-500"
              />
            </div>
            <div className="mt-3">
              <label className="block font-medium">Contact Name</label>
              <Field
                type="text"
                className="border-1 h-10 w-full rounded-md mt-1 px-2 focus:outline-none"
                name="contactName"
                placeholder="Enter contact name"
              />
              <ErrorMessage
                name="contactName"
                component="div"
                className="text-red-500"
              />
            </div>
            {userRole != ROLE.customer && (
              <div className="mt-3">
                <div>
                  <label className="block font-medium">Phone Number</label>
                  <Field
                    type="text"
                    className="border-1 h-10 w-full rounded-md mt-1 px-2 focus:outline-none"
                    name="phone"
                    placeholder="Enter phone number"
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="mt-3">
                  <label className="block font-medium">Email</label>
                  <Field
                    type="text"
                    className="border-1 h-10 w-full rounded-md mt-1 px-2 focus:outline-none"
                    name="email"
                    placeholder="Enter your email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="mt-3">
                  <label className="block font-medium">Address</label>
                  <Field
                    type="text"
                    className="border-1 h-10 w-full rounded-md mt-1 px-2 focus:outline-none"
                    name="address"
                    placeholder="Enter your address"
                  />
                  <ErrorMessage
                    name="address"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="mt-3">
                  <label className="block font-medium">Latest Note</label>
                  <Field
                    type="text"
                    className="border-1 h-10 w-full rounded-md mt-1 px-2 focus:outline-none"
                    name="latestNote"
                    placeholder="Enter latest note"
                  />
                </div>
              </div>
            )}
            <div className="flex items-center gap-2 mt-3">
              <button
                className="bg-blue-500 p-2 px-4 w-full rounded-lg text-white font-semibold hover:bg-blue-700"
                type="submit"
              >
                Add
              </button>
              <button
                className="bg-red-500 p-2 px-4 w-full rounded-lg text-white font-semibold hover:bg-red-700"
                onClick={() => handleCancelModal()}
              >
                Cancel
              </button>
            </div>
          </Form>
        </Formik>
      </Modal>
    </div>
  );
};

export default AddClientsModal;
