import { useAppSelector } from "@/redux/store/Store";
import { Modal, Select } from "antd";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useCreateAssets, useFetchAllAssetsSchema } from "@/supabase/jobs";
import {
  AddStepAssetsClient,
  AddStepAssetsProps,
  AddStepAssetsSchemaOption,
  ApiClienData,
} from "@/models";

const AddAssets: React.FC<AddStepAssetsProps> = ({
  visible,
  handleCancelModal,
  reloadDashboard,
}) => {
  const { Option } = Select;
  const { createAssets } = useCreateAssets();
  const { fetchAllAssetsSchema } = useFetchAllAssetsSchema();
  const [assetsSchema, setAssetsSchema] = React.useState([]);
  const clients = useAppSelector((state) => state.clientSlice.clientList);
  const [clientsData, setClientsData] = React.useState<AddStepAssetsClient[]>(
    []
  );
  const [schemaId, setSchemaId] =
    React.useState<AddStepAssetsSchemaOption | null>(null);

  const initialValues = {
    name: "",
    clientID: "",
    longitude: "",
    latitude: "",
    altitude: "",
  };

  const handleSubmit = (values: any) => {
    let obj = {
      name: values?.name,
      schemaID: schemaId?.value,
      schemaTitle: schemaId?.children,
    };
    createAssets(obj, reloadDashboard, handleCancelModal);
  };

  const clientArray = () => {
    if (clients) {
      const clientObjectOptions: AddStepAssetsClient[] = [];
      Object.values(clients)?.map((ele: ApiClienData) => {
        let label = "";
        if (ele?.primaryAddress) {
          label += `\nAddress : ${ele?.primaryAddress}`;
        }
        if (ele?.cachedata?.[0]?.type == "Mobile") {
          label += `\nContact : ${ele?.cachedata?.[0]?.value}`;
        }
        if (ele?.cachedata?.[0]?.type == "Email") {
          label += `\nEmail : ${ele?.cachedata?.[0]?.value}`;
        }

        let obj = {
          label,
          name: ele?.billingName,
          value: ele?.clientID,
        };
        clientObjectOptions.push(obj);
      });
      setClientsData(clientObjectOptions);
    }
  };

  const handleScheme = (values: any, data: any) => {
    setSchemaId(data);
  };

  React.useEffect(() => {
    clientArray();
    fetchAllAssetsSchema(setAssetsSchema);
  }, []);

  return (
    <div>
      <Modal
        title="Add Assets"
        open={visible}
        centered
        onOk={handleCancelModal}
        onCancel={handleCancelModal}
        footer={false}
      >
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          <Form>
            <div className="mt-3">
              <label className="block font-medium">Name</label>
              <Field
                type="text"
                className="border-1 h-10 w-full rounded-md mt-1 px-2 focus:outline-none"
                name="name"
              />
            </div>
            <div className="mt-3">
              <label className="block font-medium">Schema</label>
              <Select
                onChange={handleScheme}
                // loading={loader}
                showSearch
                placeholder="Select an option"
                optionFilterProp="label"
                filterOption={(input, option) =>
                  option?.props.children
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                className="h-10 w-full"
              >
                {assetsSchema?.length > 0 &&
                  assetsSchema?.map((option: any) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
              </Select>
            </div>
            <div className="flex items-center gap-2 mt-3">
              <button
                className="bg-blue-500 p-2 px-4 w-full rounded-lg text-white font-semibold hover:bg-blue-700"
                type="submit"
              >
                Add
              </button>
              <button
                className="bg-red-500 p-2 px-4 w-full rounded-lg text-white font-semibold hover:bg-red-700"
                onClick={() => handleCancelModal()}
              >
                Cancel
              </button>
            </div>
          </Form>
        </Formik>
      </Modal>
    </div>
  );
};

export { AddAssets };
