import { AssignAssetsProps, AssignAssetTruck } from "@/models";
import { useAppSelector } from "@/redux/store/Store";
import { ROLE } from "@/utils/enums";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Select, Tooltip } from "antd";
import React from "react";
import { useDebounceFunctions } from "../debounce";

const AssignAssets: React.FC<AssignAssetsProps> = ({
  isTruckEditable,
  setIsTruckEditable,
  record,
  values,
  onSyncClick,
  setUpdatedAssets,
  isPath,
}) => {
  const [storedTrucks, setStoredTrucks] = React.useState<
    AssignAssetTruck[] | null
  >(null);
  const role = useAppSelector((state) => state.Authlogin?.data?.role);
  const storedAssets = useAppSelector((state) => state?.storedAssets?.assets);
  const { debouncedUpdateColumnQuickly, debouncedUpdateCreatedJobs } =
    useDebounceFunctions();
  const transformedArray = storedAssets?.map((item) => ({
    label: item?.name,
    value: item?.name,
  }));
  const handleChangeTruckValues = (values: any, data: any) => {
    setStoredTrucks(values);
    if (setUpdatedAssets) {
      setUpdatedAssets(values); // Only call if setUpdatedAssets is provided
    }
  };

  const handleUpdateAssignTo = (record: any) => {
    const dueDateTimestamp = parseInt(record?.dueDate, 10);
    const durationInSeconds = parseInt(record?.duration, 10);

    // Calculate the final due date with duration
    const finalTimestamp = dueDateTimestamp + durationInSeconds;
    const formIds = Object.values(record?.forms || {})?.map(
      (form: any) => form.id
    );
    const driversIndex = record?.description?.indexOf("Drivers:") || -1;
    const drivers =
      driversIndex !== -1
        ? record?.description
            ?.slice(driversIndex + 8)
            .split("\n")[0]
            .trim() // Extract and clean the Drivers line
        : "";
    const notesIndex = record?.description?.indexOf("Notes:") || -1;
    const notes =
      notesIndex !== -1
        ? record?.description?.slice(notesIndex + 6).trim() // Extract text after "Notes:"
        : "";
    const selectedValues = storedTrucks?.map((val: any) => val.value);
    const dueDate = new Date(record?.dueDate * 1000);
    // Calculate the end date by adding the duration (in seconds)
    const endDate = new Date(
      dueDate.getTime() + parseInt(record?.duration) * 1000
    );
    const endDateTimestamp = Math.floor(endDate.getTime() / 1000);

    const combinedNotes = `Client Name: ${
      (record?.contactName && record?.contactName) || ""
    }\n${
      (selectedValues && `Vehichle Registration: ${selectedValues}`) || ""
    }\nDrivers: ${drivers || ""}${notes ? `\nNotes: ${notes}` : ""}`;

    let obj = {
      client: {
        billingName: record?.billingName || "",
      },
      title: record?.title || "",
      location: record?.location || "",
      dueDate: dueDateTimestamp || "",
      agent: record?.assignedTo || [],
      orderNumber: record?.orderNumber || "",
      status: record?.status || "",
      confirmed: record?.confirmed || "",
      siteName: record?.siteName || "",
      zone: record?.zone || "",
      jobType: record?.title || "",
      jobTitle: record?.title || "",
      notes: combinedNotes || "",
      form: formIds || "",
      endDate: endDateTimestamp,
      priority: record?.priority || "",
      invoice: record?.invoice || "",
      jobValue: record?.jobValue || "",
      salesPerson: record?.salesPerson || "",
      salesSource: record?.salesSource || "",
      sendNotification: record?.isDisplay || "",
      invoiceNumber: record?.invoice || "",
    };
    let newKeysObj = {
      extraCharges: record?.extraCharges || "",
      shift: record?.shift || "",
      contactName: record?.contactName || "",
      assignAssets: selectedValues || [],
    };
    let supabaseObj = {
      billingName: record?.billingName || "",
      title: record?.title || "",
      location: record?.location || "",
      dueDate: record?.dueDate || "",
      assignedTo: record?.assignedTo || [],
      orderNumber: record?.orderNumber || "",
      status: record?.status || "",
      confirmed: record?.confirmed || "",
      siteName: record?.siteName || "",
      zone: record?.zone || "",
      jobType: record?.title || "",
      jobTitle: record?.title || "",
      description: combinedNotes || "",
      form: record?.form || "",
      endDate: record?.endDate || "",
      priority: record?.priority || "",
      invoice: record?.invoice || "",
      jobValue: record?.jobValue || "",
      salesPerson: record?.salesPerson || "",
      salesSource: record?.salesSource || "",
      sendNotification: record?.isDisplay || "",
      invoiceNumber: record?.invoice || "",
    };
    {
      !record?.source
        ? debouncedUpdateColumnQuickly(
            obj,
            onSyncClick,
            record?.id,
            newKeysObj,
            setIsTruckEditable
          )
        : debouncedUpdateCreatedJobs(
            supabaseObj,
            onSyncClick,
            record?.id,
            newKeysObj,
            setIsTruckEditable
          );
    }
  };

  React.useEffect(() => {
    if (transformedArray && record?.assignAssets) {
      const filteredTrucks = transformedArray.filter(
        (val) =>
          Array.isArray(record?.assignAssets) &&
          record.assignAssets.includes(val?.value)
      );
      setStoredTrucks(filteredTrucks);
    }
  }, [record?.assignAssets]);
  return (
    <span>
      {isTruckEditable == record?.id && isTruckEditable ? (
        <div className="flex items-center gap-2">
          <Select
            allowClear
            mode="multiple"
            maxTagCount={1}
            value={storedTrucks}
            style={{
              width: "100%",
              height: "48px",
            }}
            onChange={(_: any, data: any) => {
              handleChangeTruckValues(data, record);
            }}
            placeholder="Please select"
            options={transformedArray}
          />
          <div className="flex items-center gap-2">
            <CloseOutlined
              onClick={(e) => {
                setIsTruckEditable(null);
                e.preventDefault();
                e.stopPropagation();
              }}
            />
            {isPath != "/job-request" && (
              <CheckOutlined
                onClick={(e) => {
                  handleUpdateAssignTo(record);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            )}
          </div>
        </div>
      ) : (
        <span
          className={`flex items-center whitespace-nowrap gap-2 ${
            values ? "justify-between" : "justify-end"
          }`}
        >
          <Tooltip
            title={
              Array.isArray(values)
                ? values.filter(Boolean).join(" - ")
                : JSON.parse(values || "[]")
                    .filter(Boolean)
                    .join(" - ")
            }
          >
            <span className="overflow-hidden text-ellipsis whitespace-nowrap max-w-60">
              {Array.isArray(values)
                ? values.filter(Boolean).join(", ")
                : JSON.parse(values || "[]")
                    .filter(Boolean)
                    .join(", ")}
            </span>
          </Tooltip>
          {role != ROLE.customer && role != ROLE.staff && (
            <EditOutlined
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsTruckEditable(record?.id);
              }}
            />
          )}
        </span>
      )}
    </span>
  );
};

export default AssignAssets;
