import { ButtonProps } from "@/models";
import React from "react";
import { Loader } from "../Loader";

const Button: React.FC<ButtonProps> = ({
  text,
  customClass,
  btnType,
  btnLoader,
  children,
  onClick,
  disabled,
}) => {
  return (
    <button
      type={btnType}
      className={customClass}
      disabled={btnLoader || disabled}
      onClick={onClick}
    >
      {btnLoader ? <Loader /> : children}
      {!btnLoader && text}
    </button>
  );
};

export { Button };
