import { AssignContactNameProps } from "@/models";
import { useAppSelector } from "@/redux/store/Store";
import { ROLE } from "@/utils/enums";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useDebounceFunctions } from "../debounce";

const AssignContactName: React.FC<AssignContactNameProps> = ({
  values,
  record,
  isContactNameEditable,
  setIsContactNameEditable,
  onSyncClick,
}) => {
  const role = useAppSelector((state) => state.Authlogin?.data?.role);
  const [updateContactName, setUpdateContactName] = useState<string | null>(
    null
  );
  const { debouncedUpdateColumnQuickly, debouncedUpdateCreatedJobs } =
    useDebounceFunctions();

  const handleUpdateAssignTo = (record: any) => {
    const dueDateTimestamp = parseInt(record?.dueDate, 10);
    const durationInSeconds = parseInt(record?.duration, 10);

    // Calculate the final due date with duration
    const finalTimestamp = dueDateTimestamp + durationInSeconds;
    const formIds = Object.values(record?.forms || {})?.map(
      (form: any) => form.id
    );
    const driversIndex = record?.description?.indexOf("Drivers:") || -1;
    const drivers =
      driversIndex !== -1
        ? record?.description
            ?.slice(driversIndex + 8)
            .split("\n")[0]
            .trim() // Extract and clean the Drivers line
        : "";
    const notesIndex = record?.description?.indexOf("Notes:") || -1;
    const notes =
      notesIndex !== -1
        ? record?.description?.slice(notesIndex + 6).trim() // Extract text after "Notes:"
        : "";
    const combinedNotes = `Client Name: ${
      updateContactName ? updateContactName : record?.contactName || ""
    }\n${
      (record?.assignAssets &&
        `Vehichle Registration: ${record?.assignAssets}`) ||
      `Vehichle Registration: ${record?.assignAssets}` ||
      ""
    }\nDrivers: ${drivers || ""}${notes ? `\nNotes: ${notes}` : ""}`;

    const dueDate = new Date(record?.dueDate * 1000);
    // Calculate the end date by adding the duration (in seconds)
    const endDate = new Date(
      dueDate.getTime() + parseInt(record?.duration) * 1000
    );
    const endDateTimestamp = Math.floor(endDate.getTime() / 1000);

    let obj = {
      client: {
        billingName: record?.billingName || "",
      },
      title: record?.title || "",
      location: record?.location || "",
      dueDate: dueDateTimestamp || "",
      agent: record?.assignedTo || [],
      orderNumber: record?.orderNumber || "",
      status: record?.status || "",
      confirmed: record?.confirmed || "",
      siteName: record?.siteName || "",
      zone: record?.zone || "",
      jobType: record?.title || "",
      jobTitle: record?.title || "",
      notes: combinedNotes || "",
      form: formIds || "",
      endDate: endDateTimestamp,
      priority: record?.priority || "",
      invoice: record?.invoice || "",
      jobValue: record?.jobValue || "",
      salesPerson: record?.salesPerson || "",
      salesSource: record?.salesSource || "",
      sendNotification: record?.isDisplay || "",
      invoiceNumber: record?.invoice || "",
    };
    let newKeysObj = {
      extraCharges: record?.extraCharges || "",
      shift: record?.shift || "",
      contactName: updateContactName || record?.contactName || "",
      assignAssets: record?.assignAssets || [],
    };
    let supabaseObj = {
      billingName: record?.billingName || "",
      title: record?.title || "",
      location: record?.location || "",
      dueDate: record?.dueDate || "",
      assignedTo: record?.assignedTo || [],
      orderNumber: record?.orderNumber || "",
      status: record?.status || "",
      confirmed: record?.confirmed || "",
      siteName: record?.siteName || "",
      zone: record?.zone || "",
      jobType: record?.title || "",
      jobTitle: record?.title || "",
      description: combinedNotes || "",
      form: record?.form || "",
      endDate: record?.endDate || "",
      priority: record?.priority || "",
      invoice: record?.invoice || "",
      jobValue: record?.jobValue || "",
      salesPerson: record?.salesPerson || "",
      salesSource: record?.salesSource || "",
      sendNotification: record?.isDisplay || "",
      invoiceNumber: record?.invoice || "",
    };
    {
      !record?.source
        ? debouncedUpdateColumnQuickly(
            obj,
            onSyncClick,
            record?.id,
            newKeysObj,
            setIsContactNameEditable
          )
        : debouncedUpdateCreatedJobs(
            supabaseObj,
            onSyncClick,
            record?.id,
            newKeysObj,
            setIsContactNameEditable
          );
    }
  };

  return (
    <div>
      {isContactNameEditable == record?.id && isContactNameEditable ? (
        <div className="flex items-center gap-2">
          <input
            type="text"
            defaultValue={values}
            placeholder="Enter"
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setUpdateContactName(e?.target?.value);
            }}
            className="w-full h-10 px-2 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
          />
          <div className="flex items-center gap-2">
            <CloseOutlined
              onClick={(e) => {
                setIsContactNameEditable(null);
                e.preventDefault();
                e.stopPropagation();
              }}
            />
            <CheckOutlined
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleUpdateAssignTo(record);
              }}
            />
          </div>
        </div>
      ) : (
        <div
          className={`flex items-center whitespace-nowrap gap-2 ${
            values ? "justify-between" : "justify-end"
          }`}
        >
          <span>{values}</span>
          {role != ROLE.customer && role != ROLE.staff && (
            <EditOutlined
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsContactNameEditable(record?.id);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AssignContactName;
