import { useAppSelector } from "@/redux/store/Store";
import { ROLE } from "@/utils/enums";
import { AdminDetailsTable } from "@/pages";
import { FormitizeUserTable } from "@/pages";
import { StaffDetailsTable } from "@/pages/admin/staff/StaffDetailsTable";

export const StaffPageDetails = () => {
  const profileData = useAppSelector(
    (state) =>
      state.Authlogin.data?.session?.user?.user_metadata ||
      state.Authlogin.data?.user?.user_metadata
  );
  const users = useAppSelector((state) => state.usersSlice.userList);
  const staffUsers = users?.filter(
    (user) => user.role === ROLE.staff && user.email !== profileData?.email
  );

  const searchText = useAppSelector((state) => state.usersSlice.searchText);

  const staffList = useAppSelector((state) => state.usersSlice.userList).filter(
    (user) => user.role === ROLE.admin && user.email !== profileData?.email
  );

  const filteredStaffList = searchText
    ? staffList.filter(
        (staff) =>
          staff.full_name.toLowerCase().includes(searchText.toLowerCase()) ||
          staff.email.toLowerCase().includes(searchText.toLowerCase())
      )
    : staffList;

  const filteredDriverData = searchText
    ? staffUsers?.filter(
        (user) =>
          user.full_name.toLowerCase().includes(searchText.toLowerCase()) ||
          user.email.toLowerCase().includes(searchText.toLowerCase())
      )
    : staffUsers;

  return (
    <div className="pt-3 pb-3">
      <AdminDetailsTable tableName="Admin" adminList={filteredStaffList} />
      <StaffDetailsTable tableName="Staff" staffList={filteredDriverData} />
      <FormitizeUserTable />
    </div>
  );
};
