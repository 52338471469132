import supabase from "..";
import { hideLoader, showLoader } from "@/redux/slices/LoaderSlice";
import { storeFormitizeUsers, storeUserData } from "@/redux/slices/UsersSlice";
import { toast } from "react-toastify";
import { useAppDispatch } from "@/redux/store/Store";
import { useAuth } from "@/hooks";
import { SupabaseFormitizeUser, UserDetail } from "@/models";

const useUsersListing = () => {
    const dispatch = useAppDispatch();
    const { profileData } = useAuth()

    const getUsersList = async () => {
        dispatch(showLoader());
        let { data: users, error } = await supabase
            .from('users')
            .select('*')
        if (users) {
            dispatch(hideLoader());
            dispatch(storeUserData(users))
        } else {
            dispatch(hideLoader());
        }
    };

    const saveUserDetail = async (userDetails: UserDetail) => {
        dispatch(showLoader());
        const { data, error } = await supabase
            .from('users')
            .update(userDetails)
            .eq('id', userDetails.id)
            .select()
        // const roleName = userDetails.role === ROLE.admin ? 'Staff' : 'Customer'
        if (data) {
            toast.success(`${userDetails.full_name} updated succesfully`)
            getUsersList()
        } else {
            dispatch(hideLoader());
            toast.error('Failed to update details. Please try again!')
        }
    }

    const uploadFormitizeUsers = async (userList: SupabaseFormitizeUser[], handleRefresh: any) => {
        try {

            const { error } = await supabase
                .from('formitize_users')
                .delete()
                .neq('id', 0);

            if (error) {
                toast.error(error.message)
            } else {
                const { data, error } = await supabase
                    .from('formitize_users')
                    .insert(
                        userList
                    )
                    .select()
                if (data) {
                    toast.success(`Formitize User's uploaded successfully!`)
                    handleRefresh()
                }
                if (error) {
                    throw Error()
                }
            }
        } catch (err) {
            toast.error(`Failed to upload user list`);
        }
    }

    const getFormitizeUsers = async () => {
        try {
            const { data, error } = await supabase.from('formitize_users').select('*')
            if (data) {
                dispatch(storeFormitizeUsers(data))
            }
            if (error) {
                throw Error
            }

        } catch (err) {
            toast.error('Failed to fetch formitize users')
        }
    }


    const getSharedDocuments = async () => {
        try {
            const userEmail = profileData?.email;

            if (!userEmail) {
                throw new Error('No user email found');
            }


            const { data, error } = await supabase
                .from('shared_documents')
                .select('*')

            if (error) {
                throw error;
            }

            //Converting shared_with in string to JSON
            const mapDoc = data.map(doc => ({
                ...doc, shared_with: doc.shared_with.map((obj: any) => {
                    const parseObject = JSON.parse(obj)
                    return parseObject
                })
            }))

            // Filter documents where userEmail is either in `uploaded_by` or in `shared_with`
            const filteredDocuments = mapDoc.filter((doc: any) =>
                doc.uploaded_by === userEmail ||
                doc.shared_with.some((user: { email: string }) => user.email === userEmail)
            );

            return filteredDocuments || [];
        } catch (err) {
            toast.error('Failed to fetch shared documents');
            return [];
        }
    };

    return {
        getUsersList, saveUserDetail, uploadFormitizeUsers, getFormitizeUsers, getSharedDocuments
    };
};


export { useUsersListing };
