import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Upload,
  Space,
  Modal,
  Select,
  Avatar,
  Tooltip,
} from "antd";
import {
  UploadOutlined,
  DeleteOutlined,
  DownloadOutlined,
  ShareAltOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import type { UploadProps } from "antd/es/upload";
import supabase from "@/supabase";
import { useDocumentHandle } from "@/supabase/storage";
import { useAppSelector } from "@/redux/store/Store";
import { useUsersListing } from "@/supabase/users";
import { useAuth } from "@/hooks";
import { getRandomColor } from "@/utils/getRandomColor";
import moment from "moment";
import { ROLE } from "@/utils/enums";
import { CopyToClipboard } from "@/pages";
import { toast } from "react-toastify";
import { SharedDocumentTableData } from "@/models";

const SharedDocumentTable: React.FC = () => {
  const [documents, setDocuments] = useState<SharedDocumentTableData[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState<string | null>(null);
  const [openCopyModal, setOpenCopyModal] = useState<boolean>(false);
  const [currentDocument, setCurrentDocument] =
    useState<SharedDocumentTableData | null>(null);
  const [sharedUsers, setSharedUsers] = useState<
    { email: string; fullName: string }[]
  >([]);
  const { uploadDocument, getPublicUrl, deleteDocument } = useDocumentHandle();
  const { getUsersList, getSharedDocuments } = useUsersListing();
  const { profileData } = useAuth();

  const role = useAppSelector((state) => state.Authlogin.data?.role);

  const userList = useAppSelector((state) => state.usersSlice.userList);

  const handleOpenCopyModal = () => {
    setOpenCopyModal(true);
  };
  const handleCancelCopyModal = () => {
    setOpenCopyModal(false);
  };

  useEffect(() => {
    getUsersList();
    fetchSharedDocuments();
  }, []);

  const fetchSharedDocuments = async () => {
    const docList = await getSharedDocuments();
    setDocuments(docList);
  };

  const handleModalData = async (values: any) => {
    const url = await getPublicUrl(values?.doc_path);
    setModalData(url || null);
    if (url) {
      handleOpenCopyModal();
    }
  };

  const columns: ColumnsType<SharedDocumentTableData> = [
    {
      title: "Document Name",
      dataIndex: "doc_name",
      key: "doc_name",
      render: (text: string) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: 200,
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Uploaded By",
      dataIndex: "uploaded_by",
      key: "uploaded_by",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (createAt: string) => {
        return <div>{moment(createAt).format("ll")}</div>;
      },
    },
    {
      title: "Shared With",
      dataIndex: "shared_with",
      key: "shared_with",
      render: (sharedWith: string[]) => {
        return (
          <Avatar.Group
            max={{
              count: 2,
              style: { color: "#f56a00", backgroundColor: "#fde3cf" },
            }}
          >
            {sharedWith.map((user: any) => {
              return (
                <>
                  <Tooltip title={user.fullName} placement="top">
                    <Avatar style={{ backgroundColor: getRandomColor() }}>
                      {user.fullName.substring(0, 2)}
                    </Avatar>
                  </Tooltip>
                </>
              );
            })}
          </Avatar.Group>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button
            type="link"
            icon={<DownloadOutlined />}
            onClick={() => handleDownload(record)}
          />

          {/* User can only delete document if it has been uploaded by them */}
          {record.uploaded_by === profileData?.email ? (
            <Button
              type="link"
              danger
              icon={<DeleteOutlined />}
              onClick={() => handleDelete(record)}
            />
          ) : (
            <></>
          )}
          {/* User can only share if they are owner of document/ they are admin */}
          {record.uploaded_by === profileData?.email || role === ROLE.admin ? (
            <Button
              type="link"
              icon={<ShareAltOutlined />}
              onClick={() => showShareModal(record)}
            />
          ) : null}
          <CopyOutlined
            className="cursor-pointer"
            onClick={() => {
              handleModalData(record);
            }}
          />
        </Space>
      ),
    },
  ];

  const handleDelete = async (record: SharedDocumentTableData) => {
    deleteDocument(record, () => {
      setDocuments(documents.filter((doc) => doc.id !== record.id));
    });
  };

  const handleDownload = async (record: SharedDocumentTableData) => {
    const url = await getPublicUrl(record.doc_path); // Adjust the path if needed
    if (url) {
      const response = await fetch(url);
      const blob = await response.blob();
      const objectUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = objectUrl;
      link.download = record.doc_name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the object URL
      URL.revokeObjectURL(objectUrl);
    }
  };

  const handleUpload: UploadProps["beforeUpload"] = async (file) => {
    const maxFileSizeMB = 50; // Set maximum file size in MB
    const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024; // Convert MB to bytes

    if (file.size > maxFileSizeBytes) {
      toast.error(`File size should not exceed ${maxFileSizeMB}MB`);
      return false; // Prevent upload
    }
    const uploadedBy = profileData?.email; // Replace with actual user data
    uploadedBy &&
      (await uploadDocument(file, uploadedBy, [], () => {
        fetchSharedDocuments();
      }));
    return false; // Prevent default upload behavior
  };

  const showShareModal = (document: SharedDocumentTableData) => {
    setCurrentDocument(document);
    setSharedUsers(document.shared_with);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    if (currentDocument) {
      // Update the document in the state and Supabase
      setDocuments(
        documents.map((doc) =>
          doc.id === currentDocument.id
            ? { ...doc, shared_with: sharedUsers }
            : doc
        )
      );

      // Update Supabase with new shared users
      await supabase
        .from("shared_documents")
        .update({ shared_with: sharedUsers })
        .eq("doc_name", currentDocument.doc_name); // Use appropriate identifier

      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleUserChange = (selectedEmails: string[]) => {
    const selectedUsers = userList
      .filter((user) => selectedEmails.includes(user.email))
      .map((user) => ({
        email: user.email,
        fullName: user.full_name,
      }));

    setSharedUsers(selectedUsers);
  };

  const userOptions = userList
    .filter((user) => user.email !== profileData?.email)
    .map((user) => ({
      value: user.email,
      label: user.full_name,
    }));

  return (
    <div className="p-5">
      {openCopyModal && (
        <CopyToClipboard
          openCopyModal={openCopyModal}
          handleCancelCopyModal={handleCancelCopyModal}
          modalData={modalData}
        />
      )}
      <div className="flex w-full justify-between items-center">
        <h4 className="text-xl font-semibold text-black">Shared Documents</h4>
        <Upload beforeUpload={handleUpload} showUploadList={false}>
          <Button icon={<UploadOutlined />}>Upload Document</Button>
        </Upload>
      </div>
      <Table
        columns={columns}
        dataSource={documents}
        style={{ marginTop: "20px" }}
        className="custom-table"
        scroll={{ x: "auto" }}
      />
      <Modal
        title="Share Document"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Select
          mode="multiple"
          value={sharedUsers.map((user) => user.email)}
          onChange={handleUserChange}
          options={userOptions}
          style={{ width: "100%" }}
        />
      </Modal>
    </div>
  );
};

export { SharedDocumentTable };
