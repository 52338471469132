// import { SupabaseClient, createClient } from '@supabase/supabase-js';

// const supabaseUrl = 'https://ipbmgxltjqvmlivdejuv.supabase.co';
// const supabaseKey = process.env.SUPABASE_KEY;

// let supabase: SupabaseClient | null = null;

// if (supabaseKey) {
//     supabase = createClient(supabaseUrl, supabaseKey);
// } else {
//     console.error('Supabase key is missing. Please provide SUPABASE_KEY environment variable.');
// }

// export { supabase };


import { createClient } from "@supabase/supabase-js";

// const supabaseUrl = process.env.SUPABASE_BASE_URL as string;
// const supabaseAnonKey = process.env.SUPABASE_ANON_KEY as string

const supabaseUrl = "https://namewkmfqbarwuowydjs.supabase.co";
const supabaseAnonKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5hbWV3a21mcWJhcnd1b3d5ZGpzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjMxMTg0MDYsImV4cCI6MjAzODY5NDQwNn0._LgnUvs2WrgrWM18FUMx2aMUhGbd28IGxuB-ZHGG9Fc"

const supabase = createClient(supabaseUrl, supabaseAnonKey);

export default supabase;
