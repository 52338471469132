import { useAppSelector } from "@/redux/store/Store";
import { ROLE } from "@/utils/enums";
import { CustomerDetails } from "./CustomerDetailsTable";

const Customers = () => {
  const searchText = useAppSelector((state) => state.usersSlice.searchText);

  const profileData = useAppSelector(
    (state) =>
      state.Authlogin.data?.session?.user?.user_metadata ||
      state.Authlogin.data?.user?.user_metadata
  );

  // Filter users by role: customer and staff
  const users = useAppSelector((state) => state.usersSlice.userList);
  const customerUsers = users.filter(
    (user) => user.role === ROLE.customer && user.email !== profileData?.email
  );

  // Filtered lists based on searchText
  const filteredCustomerList = searchText
    ? customerUsers.filter(
        (user) =>
          user.full_name.toLowerCase().includes(searchText.toLowerCase()) ||
          user.email.toLowerCase().includes(searchText.toLowerCase())
      )
    : customerUsers;

  return (
    <div className="pt-3 pb-3">
      <CustomerDetails tableName="Customers" users={filteredCustomerList} />
    </div>
  );
};

export { Customers };
