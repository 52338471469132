import { AssignExtraChargesProps } from "@/models";
import { useAppSelector } from "@/redux/store/Store";
import { useUpdateRemainingJobColumn } from "@/supabase/jobs";
import { ROLE } from "@/utils/enums";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import React, { useState } from "react";

const AssignExtraCharges: React.FC<AssignExtraChargesProps> = ({
  values,
  record,
  isExtraChargesEditable,
  setIsExtraChargesEditable,
  onSyncClick,
}) => {
  const role = useAppSelector((state) => state.Authlogin?.data?.role);
  const [updateCharges, setUpdatesCharges] = useState("");
  const { updateRemainingJobColumn } = useUpdateRemainingJobColumn();

  const debouncedUpdateColumnQuickly = debounce(
    (obj: any, onSyncClick: any, setIsExtraChargesEditable: any) => {
      updateRemainingJobColumn(obj, onSyncClick, setIsExtraChargesEditable);
    },
    500 // Adjust debounce delay as needed
  );

  const handleUpdateAssignTo = (record: any) => {
    let obj = {
      crew: "",
      truck: record?.assignAssets,
      extraCharges: updateCharges,
      shift: record?.shift,
      id: record?.id,
      contactName: record?.contactName,
    };
    debouncedUpdateColumnQuickly(obj, onSyncClick, setIsExtraChargesEditable);
  };

  return (
    <div>
      {isExtraChargesEditable == record?.id && isExtraChargesEditable ? (
        <div className="flex items-center gap-2">
          <input
            type="text"
            defaultValue={values}
            placeholder="Enter charge"
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setUpdatesCharges(e?.target?.value);
            }}
            className="w-full h-10 px-2 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
          />
          <div className="flex items-center gap-2">
            <CloseOutlined
              onClick={(e) => {
                setIsExtraChargesEditable(null);
                e.preventDefault();
                e.stopPropagation();
              }}
            />
            <CheckOutlined
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleUpdateAssignTo(record);
              }}
            />
          </div>
        </div>
      ) : (
        <div
          className={`flex items-center whitespace-nowrap gap-2 ${
            values ? "justify-between" : "justify-end"
          }`}
        >
          <span>{values}</span>
          {role != ROLE.customer && role != ROLE.staff && (
            <EditOutlined
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsExtraChargesEditable(record?.id);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AssignExtraCharges;
