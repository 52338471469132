import React, { useState } from "react";
import { AppLogo, OfficePhoto } from "@/utils/Images";
import { Button, Input, registerUser } from "@/components";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useAuth } from "@/supabase/auth";
import { useNavigate } from "react-router-dom";
import TermsAndConditionsModal from "@/components/Modal/TermsAndConditionsModal";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { SignUpFormValues } from "@/models";

const Signup: React.FC = () => {
  const navigate = useNavigate();
  const { signUpUser } = useAuth();
  const [loader, setLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const initialValues: SignUpFormValues = {
    fullName: "",
    australianBusinessName: "",
    australianNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
    checked: false,
  };

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full name is required"),
    australianBusinessName: Yup.string().required(
      "Australian business name is required"
    ),
    australianNumber: Yup.number()
      .typeError("Australian number must be a number")
      .required("Australian number is required")
      .positive("Australian number must be a positive number")
      .integer("Australian number must be an integer"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[0-9]/, "Password must contain at least one number")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain at least one special character"
      )
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), undefined], "Passwords must match")
      .required("Confirm password is required"),
    checked: Yup.boolean().oneOf(
      [true],
      "You must accept the terms and conditions"
    ),
  });

  const handleSubmit = async (
    values: SignUpFormValues,
    { setSubmitting }: FormikHelpers<SignUpFormValues>
  ) => {
    setSubmitting(false);
    await signUpUser(values, setLoader);
  };

  return (
    <div className="min-h-screen bg-gray-300 text-gray-900 flex justify-center">
      <div className="max-w-screen-xl m-0 sm:m-10 bg-white shadow sm:rounded-lg flex justify-center flex-1">
        <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-12">
          <div>
            <img src={AppLogo} className="w-32 mx-auto" alt="App Logo" />
          </div>
          <div className="mt-12 flex flex-col items-center">
            <h1 className="text-2xl font-semibold text-center">
              Smart Solutions Australia Gateway
            </h1>
            <div className="w-full flex-1 mt-8">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="mx-auto max-w-xs">
                      {registerUser?.map((val) => (
                        <div key={val.name} className="mt-5">
                          {val.name === "password" ||
                          val.name === "confirmPassword" ? (
                            <div className="relative">
                              <Input
                                inputType={
                                  val.name === "password"
                                    ? passwordVisible
                                      ? "text"
                                      : "password"
                                    : confirmPasswordVisible
                                    ? "text"
                                    : "password"
                                }
                                inputPlaceholder={val.placeholder}
                                inputName={val.name}
                                inputClass="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                              />
                              <span
                                className="absolute right-3 top-4 cursor-pointer"
                                onClick={() =>
                                  val.name === "password"
                                    ? setPasswordVisible(!passwordVisible)
                                    : setConfirmPasswordVisible(
                                        !confirmPasswordVisible
                                      )
                                }
                              >
                                {val.name === "password" &&
                                  (passwordVisible ? (
                                    <EyeInvisibleOutlined />
                                  ) : (
                                    <EyeOutlined />
                                  ))}
                                {val.name === "confirmPassword" &&
                                  (confirmPasswordVisible ? (
                                    <EyeInvisibleOutlined />
                                  ) : (
                                    <EyeOutlined />
                                  ))}
                              </span>
                              <ErrorMessage
                                name={val.name}
                                component="div"
                                className="text-red-600 text-sm mt-1"
                              />
                            </div>
                          ) : (
                            <div>
                              <Input
                                inputType={val.type}
                                inputPlaceholder={val.placeholder}
                                inputName={val.name}
                                inputClass="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                              />
                              <ErrorMessage
                                name={val.name}
                                component="div"
                                className="text-red-600 text-sm mt-1"
                              />
                            </div>
                          )}
                        </div>
                      ))}
                      <div className="flex items-center mt-4">
                        <Field
                          type="checkbox"
                          name="checked"
                          id="terms"
                          className="h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                        />
                        <p className="ml-2 text-xs text-gray-600">
                          By signing up I agree to abide by SSA's{" "}
                          <button
                            type="button"
                            className="border-b border-gray-500 border-dotted text-blue-500"
                            onClick={openModal}
                          >
                            Terms and Conditions.
                          </button>
                        </p>
                      </div>
                      <ErrorMessage
                        name="checked"
                        component="div"
                        className="text-red-500 text-xs mt-1"
                      />
                      <Button
                        btnLoader={isSubmitting || loader}
                        btnType="submit"
                        customClass="mt-5 tracking-wide font-semibold bg-blue-500 text-white w-full py-4 rounded-lg hover:bg-blue-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                        text="Sign Up"
                      >
                        <svg
                          className="w-6 h-6 mr-1"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          viewBox="0 0 24 24"
                        >
                          <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                          <circle cx="8.5" cy="7" r="4" />
                          <path d="M20 8v6M23 11h-6" />
                        </svg>
                      </Button>
                      <div className="flex items-center justify-between mt-4">
                        <p className="text-base text-slate-400">
                          Already have an account?
                        </p>
                        <p
                          className="cursor-pointer text-blue-500 font-medium text-base"
                          onClick={() => navigate("/login")}
                        >
                          Sign In
                        </p>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="flex-1 bg-[rgb(1,0,120)] text-center hidden lg:flex rounded-r-md">
          <img className="rounded-r-md" src={OfficePhoto} alt="img" />
        </div>
        <TermsAndConditionsModal isOpen={isModalOpen} onClose={closeModal} />
      </div>
    </div>
  );
};

export default Signup;
